import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Label, FormGroup, Button,
    ModalBody,ModalHeader,Table,Modal } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import Notification from "../../../components/Notification";
import utils from "../../../utils";
import DatePicker from "react-datepicker";
const apiHistory = require("./../api/apiHistory");
const {listStore} = require("./../api/apiStore");
const productOption = require("../../../assets/json/productOption.json");
const product = require("../../../assets/json/product.json");
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listStore: [],
            fromdate: null,
            todate: null,
            openModalExpan: false,
            totalMoney: 0
        };
    }

    inputOnchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    getApiHistory(fromDate, toDate, params) {
        let state = Object.assign({}, this.state);
        if(utils.isEmpty(this.state.listStore)){
            listStore((err, result) => {
                if (err) {
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    result.map(value => {
                        state.listStore.push({ id: value.id, label: value.storeName });
                    });
                }
            });
        }
    
        apiHistory.getHistory(utils.convertDate(fromDate), utils.convertDate(toDate), params, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                state.data = [];
                state.totalMoney = 0;
                state.countTicket = 0;
                result.map((value,index) => {
                    value.staffStatic.storeName = state.listStore.find(store => store.id == value.staff.storeId).label;
                    var price = 0;
                    value.ticketArray.map((t,index)=>{
                        if(t.statusId!==4){
                            // neu ticket bi huy thi khong list ra
                            value.ticketArray[index] = null;
                        } else{
                            let term = t.termArray.length;
                            let priceOnTicketRow = 0;
                            state.countTicket+=1;
                            // gia ve = tong cac rowTicket * so ky
                            for (const r of t.value) {
                                priceOnTicketRow+= term*r.price;
                                price+= term*r.price;
                            }
                            t.price = priceOnTicketRow;
                        }
                    })
                    // gia orrder = tong cac gia ve
                    value.price = price;
                    state.totalMoney += price;
                });
                // lat nguoc mang + loai bo order bi huy tat ca ticket
                for (let index = result.length-1; index >=0 ; index--) {
                    if(result[index].price!== 0) state.data.push(result[index])
                }
                this.setState(state);
            }
        });
    }

    componentDidMount() {
        this.getApiHistory(null, null, "?action[]=26");
    }

    render() {
        const expandRow = {
            renderer: (row) =>(
                <div className='pl-5'>
                    <Modal className="modal-big" isOpen={this.state.open}>
                        <ModalHeader toggle={()=>this.setState({open:false})}/>
                        <ModalBody>
                            <Row>
                                {
                                    utils.isEmpty(row.affectedObjectStatic)
                                    ? null
                                    : utils.isEmpty(row.affectedObjectStatic.shipment)
                                        ?null
                                        : row.affectedObjectStatic.shipment.type!==2
                                            ? <Table className="mb-3">
                                                <thead>
                                                    <tr>
                                                        <th>Loại chuyển phát</th>
                                                        <th>Số điện thoại</th>
                                                        <th>Số CMND/CCCD</th>
                                                        <th>Địa chỉ</th>
                                                    </tr>
                                                    
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{row.affectedObjectStatic.shipment.type===0 ? "Chuyển nhanh" : "Chuyển chậm"}</td>
                                                        <td>{row.affectedObjectStatic.shipment.phoneNumber}</td>
                                                        <td>{row.affectedObjectStatic.shipment.idCardNumber}</td>
                                                        <td>{row.affectedObjectStatic.shipment.address}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            : null
                                }
                                <div className="d-block">
                                    <img style={{maxWidth: "50%",maxHeight: "550px"}} src={utils.getURLImage(this.state.back)} />
                                    <img style={{maxWidth: "50%",maxHeight: "550px"}} src={utils.getURLImage(this.state.front)} />
                                </div>
                            </Row>
                        </ModalBody>
                    </Modal>
                    <Table>
                            <React.Fragment>
                                <thead>
                                    <tr>
                                        <th width="10%">ID</th>
                                        <th width="16%">Loại vé</th>
                                        <th width="16%">Kiểu chơi</th>
                                        <th>Kỳ</th>
                                        <th width="15%">Giá vé</th>
                                    </tr>
                                </thead>
                                {row.ticketArray.map((ticket, key) => {
                                    if(ticket!==null)return(
                                        <tr onClick={()=>{if(ticket.statusId!==3)this.setState({open:true, back: ticket.imageBack, front: ticket.imageFront});}}>
                                            <td>
                                                {`#${ticket.id}`}
                                            </td>
                                            <td>
                                                {product[ticket.productId]}
                                            </td>
                                            <td>
                                                {productOption[ticket.productOptionId]}    
                                            </td>
                                            <td>
                                                {
                                                    ticket.termArray.length > 7
                                                    ? (`${ticket.termArray.length} kỳ. từ kỳ: ${ticket.termArray[0]} - đến kỳ: ${ticket.termArray[ticket.termArray.length-1]}`)
                                                    :ticket.termArray.toString()
                                                }
                                            </td>
                                            <td>
                                                {utils.convertMoneyFomat(ticket.price)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </React.Fragment>
                    </Table>
                </div>
            )
        };
        return(
            <React.Fragment>
                <Row className='justify-content-center m-0'>
                    <FormGroup className='mb-3'>
                        <Label className="d-block">Ngày bắt đầu</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.fromdate===null ? new Date(): this.state.fromdate}
                            onChange={date =>this.setState({fromdate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <FormGroup className='mb-3 ml-3'>
                        <Label className="d-block">Ngày kết thúc</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.todate===null ? new Date(): this.state.todate}
                            onChange={date => this.setState({todate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <div className='mt-4 p-2'>
                    <Button color='primary' onClick={this.getApiHistory.bind(this, this.state.fromdate, this.state.todate, "?action[]=26")}>
                            Tìm kiếm
                        </Button>
                    </div>
                </Row>
                <div className='mx-4 p-3 w-120 borderRadius text-center'>
                    <Row>
                        <Col md='4'>
                            <div className='w-100 font-weight-bold'>Tổng số order</div>
                            <div>{this.state.data.length}</div>
                        </Col>
                        <Col md='4'>
                            <div className='w-100 font-weight-bold'>Tổng số vé</div>
                            <div>{this.state.countTicket}</div>
                        </Col>
                        <Col md='4'>
                            <div className='w-100 font-weight-bold'>Tổng tiền vé</div>
                            <div>{utils.convertMoneyFomat(this.state.totalMoney)}</div>
                        </Col>
                    </Row>
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 borderRadius text-center'>
                    <FontAwesomeIcon color='#2c7be5' style={{ fontSize: "30px" }} icon={faTicketAlt} /> <span className='content-title-bold'>Thống kê bán vé</span>
                    <Row className='pt-2 p-1 overflow-x-auto table-overflow-x'>
                        <BootstrapTable
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.data}
                            expandRow={expandRow}
                            columns={[
                                {
                                    dataField: "id",
                                    text: "ID order",
                                    filter: textFilter({
                                        placeholder: "Tìm kiếm theo ID",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        return "#"+cell;
                                    },
                                    
                                },
                                {
                                    dataField: "staffStatic.username",
                                    text: "Nhân viên bán vé",
                                    filter: textFilter({
                                        placeholder: "Tìm kiếm theo nhân viên",
                                    }),
                                    
                                    formatter: function Formatter(cell, row) {
                                        return cell;
                                    },
                                },
                                {
                                    dataField: "staffStatic.storeName",
                                    text: "Cửa hàng tiếp nhận",
                                    filter: textFilter({
                                        placeholder: "Tìm kiếm theo cửa hàng",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        return cell;
                                    },
                                },
                                {
                                    dataField: "affectedObjectStatic.customer.phoneNumber",
                                    text: "SĐT khách hàng",
                                    filter: textFilter({
                                        placeholder: "Tìm kiếm theo SĐT khách hàng",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                                {
                                    dataField: "price",
                                    text: "Số tiền order",
                                    sort: true,
                                    filter: textFilter({
                                        placeholder: "Tìm kiếm theo số tiền",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return utils.convertMoneyFomat(cell);
                                        }
                                    },
                                    
                                },
                                {
                                    dataField: "createdDate",
                                    text: "Thời gian",
                                    sort: true,
                                    filter: textFilter({
                                        placeholder: "Tìm kiếm theo thời gian mua vé",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                            ]}
                            filter={filterFactory()}
                            pagination={paginationFactory({
                                sizePerPage: 100,
                                sizePerPageList: [50, 100, 200, 250],
                            })}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
