import React from "react";
import { Row, Label, FormGroup, Button,Table,Col, InputGroup,CustomInput,ModalHeader,Modal,ModalBody, Card, CardTitle, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2} from '../api/apiStatistic'
import {getStaffMonitor,getTicketArray} from '../api/apiAdmin'
import {getHistory} from '../api/apiHistory'
import {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
import Select from "react-select";
const {getProductById,getProductOptionById} = require('../../../assets/js/getProduct');
const moment = require('moment')


class ChangeTermTicket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),           
            phone:null,
            pageNum:1,
            statisticData : [],
            statisticDataShow:[],
            size:50,
            ticketArray:[],
            showModalTicket:false,
            productSelect:null,
            revenueSelect:null,
            actionFilter:64
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label:"STT"},
            {label: "Nhân viên"},    
            {label: "Id vé"},                                                       
            {label: "Thời gian mua"},  
            {label: "Thời gian đổi/ Hoàn thành vé"}, 
            {label: "Kỳ vé hiện tại"},                        
        ];
        this.filter={
            size: 1000,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.action=[
                {value:64,label:'Dời kì keno'}                 ,
                {value:65,label:'Hoàn thành sai kì'}                 
        ];
        this.actionSelect=64
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    
    getStatistic(page){
       
      let query={       
        action:[this.actionSelect],
        size:this.filter.size
      }      
      getHistory(
        this.convertDate(this.state.date),this.convertDate(this.state.date),query,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
           
                this.setState({statisticData: result,statisticDataShow:result.slice(0,this.filter.size), pageNum : Math.ceil(result.length/this.filter.size)})
            }
        });
    }
    convertDate=(date)=>{

        if(date){
            let month=date.getMonth()+1;
            month=month>=10?month:'0'+month
        return (date.getDate()>=10?date.getDate():'0'+date.getDate())+'-'+(month)+'-'+date.getFullYear();
    }
    }
   
    
    componentDidMount(){
        this.getStatistic(null);
    }
    
    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }
    handleToggleSelectPos(optionSelected){
       
            this.actionSelect =optionSelected.value
       
    }
    renderRow(){
        if( this.state.statisticDataShow.length>0){
            return  this.state.statisticDataShow.map((row,index)=>{
                return(
                    <tr >
                        <td>{index+1}</td>
                        <td>{row.staff.username}</td>
                        <td>{row.affectedObjectStatic.id}</td>   
                        <td>{row.affectedObjectStatic.createdDate}</td>
                        <td>{row.createdDate}</td>                                                 
                        <td style={{textAlign:'left'}}>{row.affectedObjectStatic.termArray.join(',')}</td>                                                                                                                                                                              
                    </tr>
                )
            })
        }
    }
    render() {
        return(
            <React.Fragment>
                
                <div className='mx-4 p-3 w-120 text-center'>
                    
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Chọn ngày cần xem</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.date}
                                onChange={date =>this.setState({date: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>   
                        <FormGroup className='mb-3 ml-3'>
                        <Label className="d-block">Loại tìm kiếm</Label>
                        <Select
                            className="header-select-statistic"
                            name='value'
                            placeholder="Chọn lịch sử"
                            classNamePrefix='react-select'
                            options={this.action}
                            onChange={(value)=> this.handleToggleSelectPos(value)}
                        />
                    </FormGroup>                     
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={this.getStatistic.bind(this,null)}>
                                Tìm kiếm
                            </Button>                            
                        </div>

                    </Row>
                   
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.renderRow()
                                   
                                }
                            </tbody>
                        </Table>
                    </Row>
                   
                </div>
            </React.Fragment>
        );
    }
}

export default ChangeTermTicket;