import React from "react";
import {
    Col, Row
} from "reactstrap";
import "react-tabs/style/react-tabs.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";

class Block extends React.Component {
    handleOnClick() {
        this.props.handleOnClick(this.props.id);
    }

    render() {
        return (
            <React.Fragment>
                <div className="block" onClick={this.handleOnClick.bind(this)}
                >
                    <Row className="p-0 m-0">
                        <Col className="p-0 m-0 content-title-bold">
                            {this.props.phoneNumber ? this.props.phoneNumber.toUpperFisrtChacracter() : "Phone Number"}
                        </Col>
                        <Col className="p-0 m-0 text-right">
                            {this.props.price ? (this.props.price * 1000).getMoneyFormat() + " đ" : "Price"}
                        </Col>
                    </Row>
                    <Row className="p-0 m-0 mt-2">
                        <Col className="p-0 m-0">
                            {this.props.lotteryType  ? this.props.lotteryType : null}
                        </Col>
                    </Row>
                    <Row className="p-0 m-0 mt-2">
                        <Col className="p-0 m-0">
                            {this.props.time ? this.props.time : "Time"}
                        </Col>
                        <Col className="p-0 m-0 text-right">
                            <FontAwesomeIcon icon={faCircle}
                                            color={this.props.id ? (this.props.id.split("-")[0] === "waiting_for_handling" ? "red" : "orange") : "green"}
                                            style={{width: "10px"}}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default Block;