import React from "react";
import {
    Col, Row
} from "reactstrap";
import "react-tabs/style/react-tabs.css";
import "./css/clerk.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";

class Block extends React.Component {
    handleOnClick() {
        try {
            this.props.handleOnClick(this.props.id);
        } catch (error) {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div 
                    className="block" 
                    onClick={this.handleOnClick.bind(this) }
                    
                >
                    <Row className="p-0 m-0">
                        <Col className="p-0 m-0 content-title-bold">
                            {this.props.phoneNumber ? this.props.phoneNumber.toUpperFisrtChacracter() : "Phone Number"}
                        </Col>
                        {this.props.isDt? <Col className="p-0 m-0 content-title-bold">
                           <img src={require("../../assets/img/photos/dien-toan.png")} width="50"/>
                        </Col>:null}
                       
                        <Col className="p-0 m-0 text-right">
                            {this.props.price ? (this.props.price * 1000).getMoneyFormat() + " đ" : "Price"}
                        </Col>
                    </Row>
                    <Row className="p-0 m-0 mt-2">
                        <Col className="p-0 m-0">
                            {this.props.lotteryType  ? this.props.lotteryType : null}
                        </Col>
                    </Row>
                    {
                        this.props.kenoterm
                        ? (
                            this.props.kenoterm.length === 1
                            ?(
                                `Kỳ ${this.props.kenoterm[0]}`
                            ):(
                                `${this.props.kenoterm.length} Kỳ [${this.props.kenoterm[0]}-${this.props.kenoterm[this.props.kenoterm.length-1]}]`
                            )
                        ):(
                            null
                        )
                    }
                    <Row></Row>
                    <Row className="p-0 m-0 mt-2">
                        <Col md="10" className="p-0 m-0">
                            {this.props.time ? this.props.time : "Time"}
                        </Col>
                        <Col md="2" className="p-0 m-0 text-right">
                            <FontAwesomeIcon icon={faCircle}
                                            color={this.props.id 
                                                    ? (this.props.id.split("-")[0] === "waiting_for_handling_keno" || this.props.id.split("-")[0] === "waiting_for_handling_normal" 
                                                        ? "red" 
                                                        : this.props.id.split("-")[0] === "handling" 
                                                            ? "orange" 
                                                            : this.props.id.split("-")[0] === "completed" 
                                                                ?"green"
                                                                :"gray"
                                                    ): "green"}
                                            style={{width: "10px"}}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default Block;