const arrayCity = [ 
    { label: 'An Giang', value: '89' },
    { label: 'Bà Rịa - Vũng Tàu', value: '77' },
    { label: 'Bình Dương', value: '74' },
    { label: 'Bình Phước', value: '70' },
    { label: 'Bình Thuận', value: '60' },
    { label: 'Bình Định', value: '52' },
    { label: 'Bạc Liêu', value: '95' },
    { label: 'Bắc Giang', value: '24' },
    { label: 'Bắc Kạn', value: '06' },
    { label: 'Bắc Ninh', value: '27' },
    { label: 'Bến Tre', value: '83' },
    { label: 'Cao Bằng', value: '04' },
    { label: 'Cà Mau', value: '96' },
    { label: 'Cần Thơ', value: '92' },
    { label: 'Đà Nẵng', value: '48' },
    { label: 'Đắk Lắk', value: '66' },
    { label: 'Đắk Nông', value: '67' },
    { label: 'Điện Biên', value: '11' },
    { label: 'Đồng Nai', value: '75' },
    { label: 'Đồng Tháp', value: '87' },
    { label: 'Gia Lai', value: '64' },
    { label: 'Hoà Bình', value: '17' },
    { label: 'Hà Giang', value: '02' },
    { label: 'Hà Nam', value: '35' },
    { label: 'Hà Nội', value: '01' },
    { label: 'Hà Tĩnh', value: '42' },
    { label: 'Hưng Yên', value: '33' },
    { label: 'Hải Dương', value: '30' },
    { label: 'Hải Phòng', value: '31' },
    { label: 'Hậu Giang', value: '93' },
    { label: 'Hồ Chí Minh', value: '79' },
    { label: 'Khánh Hòa', value: '56' },
    { label: 'Kiên Giang', value: '91' },
    { label: 'Kon Tum', value: '62' },
    { label: 'Lai Châu', value: '12' },
    { label: 'Long An', value: '80' },
    { label: 'Lào Cai', value: '10' },
    { label: 'Lâm Đồng', value: '68' },
    { label: 'Lạng Sơn', value: '20' },
    { label: 'Nam Định', value: '36' },
    { label: 'Nghệ An', value: '40' },
    { label: 'Ninh Bình', value: '37' },
    { label: 'Ninh Thuận', value: '58' },
    { label: 'Phú Thọ', value: '25' },
    { label: 'Phú Yên', value: '54' },
    { label: 'Quảng Bình', value: '44' },
    { label: 'Quảng Nam', value: '49' },
    { label: 'Quảng Ngãi', value: '51' },
    { label: 'Quảng Ninh', value: '22' },
    { label: 'Quảng Trị', value: '45' },
    { label: 'Sóc Trăng', value: '94' },
    { label: 'Sơn La', value: '14' },
    { label: 'Thanh Hóa', value: '38' },
    { label: 'Thái Bình', value: '34' },
    { label: 'Thái Nguyên', value: '19' },
    { label: 'Thừa Thiên Huế', value: '46' },
    { label: 'Tiền Giang', value: '82' },
    { label: 'Trà Vinh', value: '84' },
    { label: 'Tuyên Quang', value: '08' },
    { label: 'Tây Ninh', value: '72' },
    { label: 'Vĩnh Long', value: '86' },
    { label: 'Vĩnh Phúc', value: '26' },
    { label: 'Yên Bái', value: '15' },
]



module.exports={
    arrayCity: arrayCity
}