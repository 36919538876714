import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { Col, Row,Label, FormGroup, Button,Table } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter,selectFilter } from "react-bootstrap-table2-filter";
import Notification from "../../../components/Notification";
import utils from "../../../utils";
import DatePicker from "react-datepicker";
import {exportedExcel} from '../../../components/api/apiExportServer';
import LoadingSprinner from '../../../components/LoadingSprinner';

const apiStatistic = require("../api/apiStatistic");
const apiGroup = require('../api/ApiGroupBuy.js');
let productName = ['','Mega',"Power"]
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winPrize: null,
            fromDate: new Date(),
            toDate: new Date(),
            pageNum: 0,
            winGroup:[]
        };
        this.rowPage = [50,100,200,250];
        this.filter={
            size: 100,
            page:1
        };
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    fnGetDepositWinPrize(page) {
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        if(this.state.fromDate===null) Object.assign(this.filter,{fromDate: "01-01-2019"},{toDate:utils.convertDate(this.state.toDate)})
        else Object.assign(this.filter,{fromDate: utils.convertDate(this.state.fromDate)},{toDate:utils.convertDate(this.state.toDate)})

        apiStatistic.winPrize(this.filter, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({ winPrize: result, pageNum : Math.ceil(result.count/this.filter.size)});
            }
        });
        apiGroup.getAllGroup({isWining:1,dateF: utils.convertDate(this.state.fromDate),dateTo:utils.convertDate(this.state.toDate), limit:1000,offset:0},(err,result)=>{
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({winGroup:result})
            }
            
        })
    }

    handExport(isVietlott=false){
        this.setState({loading:true});
        let filter = {};
        if( this.state.fromDate!==null ) 
            Object.assign(filter,{fromDate: utils.convertDate(this.state.fromDate)})
        if( this.state.toDate!==null )
            Object.assign(filter,{toDate:utils.convertDate(this.state.toDate)})
        if(isVietlott)
            Object.assign(filter,{type:'vietlott'})
        exportedExcel('prize/export',filter,(err,res)=>{
            if(err){
                Notification("error","Vui lòng giới hạn lại thời gian");
            } 
            this.setState({loading:false});
        })
    }

    getNo(cell,row,index){
        return this.state.size*(this.state.page-1)+index;
    }

    componentDidMount() {
        this.fnGetDepositWinPrize(1);
    }

    render() {
        return utils.isEmpty(this.state.winPrize) ? null : (
            <React.Fragment>
                <Row className='justify-content-center m-0'>
                    <FormGroup className='mb-3'>
                        <Label className="d-block">Ngày bắt đầu</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.fromDate}
                            onChange={date =>this.setState({fromDate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <FormGroup className='mb-3 ml-3'>
                        <Label className="d-block">Ngày kết thúc</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.toDate===null ? new Date(): this.state.toDate}
                            onChange={date => this.setState({toDate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <div className='mt-4 p-2 d-flex'>
                        <Button className=" mb-3" color='primary' onClick={this.fnGetDepositWinPrize.bind(this,1)}>
                            Tìm kiếm
                        </Button>
                        {
                            !this.state.loading
                            ? <Button  className="mx-2 mb-3" color='primary' onClick={()=>this.handExport()}>
                                    Xuất file
                                </Button>
                            : <LoadingSprinner/>
                        }
                        {
                            !this.state.loading
                            ? <Button  className="mx-2 mb-3" color='primary' onClick={()=>this.handExport(true)}>
                                    Xuất file vietlott
                                </Button>
                            : <LoadingSprinner/>
                        }
                    </div>
                </Row>
                <div className='p-3 w-120 borderRadius text-center'>
                    <Row>
                        <Col md='6'>
                            <div className='w-100 font-weight-bold'>Tổng số vé trúng</div>
                            <div>{this.state.winPrize.count}</div>
                        </Col>
                        <Col md='6'>
                            <div className='w-100 font-weight-bold'>Tổng số tiền trúng thưởng trong trang</div>
                            <div>{utils.convertMoneyFomat(this.state.winPrize.amount)}</div>
                        </Col>
                    </Row>
                </div>
                <div className='py-3 mt-2 w-120 '>
                <div className='text-center'>
                        <FontAwesomeIcon color='#2c7be5' style={{ fontSize: "30px" }} icon={faAward} /> <span className='content-title-bold'>Thống kê nhóm trúng thưởng</span>
                </div>
                <Row className='pt-2 p-1 scroll-table  '>
                <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Loại vé</th>
                            <th>Số</th>
                            <th>Kì mua</th>
                            <th>Tiền trúng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.winGroup.map(e=>{
                                return (<tr>
                                    <td>{e.id}</td>
                                    <td>{productName[e.productId]}</td>
                                    <td>{e.numberCurrent}</td>
                                    <td>#{e.term.termValue} {e.term.date}</td>
                                    <td>{(e.winingNumber[0].profit*1000).getMoneyFormat()}</td>
                                </tr>)
                            })
                        }
                        
                    </tbody>
                    </Table>
                </Row>
                    <div className='text-center'>
                        <FontAwesomeIcon color='#2c7be5' style={{ fontSize: "30px" }} icon={faAward} /> <span className='content-title-bold'>Thống kê trúng thưởng</span>
                    </div>
                    
                    <Row className='pt-2 p-1 m-0 overflow-x-auto table-overflow-x'>
                        <BootstrapTable
                            id = "thong-ke-trung-thuong"
                            className="table-staffmanagement"
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.winPrize.rows}
                            columns={[
                                {
                                    dataField: `orderId`,
                                    text: "ID Order",
                                    filter: textFilter({
                                        placeholder: "Nhập ID",
                                    })
                                },                            
                                {
                                    dataField: `ticketId`,
                                    text: "ID vé",
                                    filter: textFilter({
                                        placeholder: "Nhập ID",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                                {
                                    dataField: `customer.fullName`,
                                    text: "Tên khách hàng  ",
                                    filter: textFilter({
                                        placeholder: "Nhập tên khách hàng",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                                {
                                    dataField: `customer.phoneNumber`,
                                    text: "Số điện thoại  ",
                                    filter: textFilter({
                                        placeholder: "Nhập số điện hàng",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                                {
                                    dataField: `ticket.product.name`,
                                    text: "Loại vé  ",
                                    filter: selectFilter({
                                        placeholder: "Chọn loại vé",
                                        options: {
                                            "Keno":"Keno",
                                            "MAX 3D PRO":"MAX 3D PRO",
                                            "MAX 3D":"MAX 3D",
                                            "MAX 3D+":"MAX 3D+",
                                            "POWER 6/55":"POWER 6/55",
                                            "Mega 6/45":"Mega 6/45",
                                        }
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                                {
                                    dataField: `ticket.value`,
                                    text: "Số ghi trên vé  ",
                                    filter: textFilter({
                                        placeholder: "Nhập số vé",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        return(
                                            cell.map(({number},index)=>{
                                                return (
                                                    <div className="w-100 my-1">
                                                    {
                                                        (row.ticket.productId!==13&&row.ticket.productId!==14)?
                                                        number.map((n,key)=>{
                                                            if(utils.isEmpty(n)){
                                                                console.log(1);
                                                                return ("");
                                                            }
                                                            if(row.ticket.productId === 6 && ["1","2","3","4","5","6","7","8"].includes(n)){
                                                                this.kenoEvenOddBigSmall={
                                                                    "1": "Lẻ",
                                                                    "2": "Chẵn",
                                                                    "3": "Nhỏ",
                                                                    "4": "Lớn",
                                                                    "5": "Hòa Chẵn-Lẻ",
                                                                    "6": "Hòa Lớn-Nhỏ",
                                                                    "7": "Chẵn 11-12",
                                                                    "8": "Lẻ 11-12",
                                                                    
                                                                };
                                                                return (<span className="mx-1" key={index*10 + key}>{this.kenoEvenOddBigSmall[n]}</span>)
                                                            }
                                                            return( <span className="mx-1" key={index*10 + key}>{n}</span> )
                                                        })  :
                                                        <span className="mx-1" >{number}</span>      
                                                    }
                                                    </div>
                                                )
                                            })
                                        )
                                    },
                                    headerStyle: () => {
                                        return { width: "250px", textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: "term",
                                    text: "Kì quay  ",
                                    filter: textFilter({
                                        placeholder: "Kì quay",
                                    }),
                                    formatter: function Formatter(cell) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                    headerStyle: () => {
                                        return { width: "100px", textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: "totalPrizeProfit",
                                    text: "Số tiền             ",
                                    filter: textFilter({
                                        placeholder: "Nhập số tiền",
                                    }),
                                    sort: true,
                                    formatter: function Formatter(cell) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell.getMoneyFormat();
                                        }
                                    },
                                },
                                {
                                    dataField: "createdDate",
                                    text: "Thời gian  ",
                                    sort: true,
                                    filter: textFilter({
                                        placeholder: "Nhập ngày tháng",
                                    }),
                                    formatter: function Formatter(cell) {
                                        if (cell === null) {
                                            return "---";
                                        } else {
                                            return cell;
                                        }
                                    },
                                },
                            ]}
                            filter={filterFactory()}
                        />
                    </Row>
                    <Row className="mt-2 ml-3">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                utils.isEmpty(this.state.winPrize) ? null
                                :(
                                    <div className="float-right px-2">
                                        <Button disabled={this.filter.page===1} onClick={this.fnGetDepositWinPrize.bind(this,"-")}>{`<`}</Button>
                                        {
                                            utils.range(1,10).map((index)=>{
                                                return(
                                                    <Button 
                                                        key = {index}
                                                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                                                        hidden={index >  this.state.pageNum}
                                                        onClick={this.fnGetDepositWinPrize.bind(this,index)}
                                                    >
                                                        {index}
                                                    </Button>
                                                )
                                            })
                                        }
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        <Button hidden={this.state.pageNum<=10} className={`${this.filter.page === this.state.pageNum ? "btn-danger" : null}`} onClick={this.fnGetDepositWinPrize.bind(this,this.state.pageNum)}>{`...${this.state.pageNum}`}</Button>
                                        <Button disabled={this.filter.page >=  this.state.pageNum} onClick={this.fnGetDepositWinPrize.bind(this,"+")}>{`>`}</Button>
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;