const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');


function getAllGroup(data,callback){
    axios({
        url: config_api.kienthietGetAll+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function createGroup(data,callback){
    axios({
        url:config_api.kienthietCreate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data    

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getAllKTMN(data,callback){
    axios({
        url: config_api.ktmnGetAll+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function updateKTMN(data,callback){
    axios({
        url: config_api.ktmnUpdate,
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function deleteKTMN(data,callback){
    axios({
        url: config_api.ktmnDelete,
        method: 'delete',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:{id:data.kID}   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}



module.exports={
    createGroup,
    getAllGroup,
    getAllKTMN,
    updateKTMN,
    deleteKTMN,    
}