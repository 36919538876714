
const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');
const createKTMN = async(data) =>{
    try{
        let res= await axios({
            url: `${config_api.createKTMN}`,
            method: 'POST',
            headers: {
                "Content-type": 'application/json',
                "Authorization": 'Bearer ' + utils.getAuthToken()
            },
            data
        });
        return [null,res.data]
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }
   

}

const getKTMNDraw = async(date) =>{
    try{
        let res= await axios({
            url: `${config_api.getKTMNDraw}?date=${date}`,
            method: 'GET',
            headers: {
                "Content-type": 'application/json',
            },
        });
        return [null,res.data]
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }
}

function apiAttachKTMN(file,callback){
    if(file===null){
        return callback({data:{_error_message:"Chưa chụp được ảnh"}});
    }else if(file.size>5242880){
        return callback({data:{_error_message:"Ảnh quá lớn. Chọn lại ảnh"}});
    }
    var bodyFormData = new FormData();
    console.log(file);
    var photo = {
        file: file,
        type: 'image/JPEG',
        name: 'photo',
    };
    bodyFormData.append('attached_file', file);
    bodyFormData.append('contentType', "ktmn");
    axios({
        url: `${config_api.attachment}`,
        method: 'POST',
        headers: {
            "Content-type": "multipart/form-data",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: bodyFormData
    })
    .then(result => {
        return callback(false, result.data);
    })
    .catch(error => {
        console.log(error);

        if(error.request && !error.response){
            console.log(error.request)
            return callback("Đường truyền bị gián đoạn"+error.request);
        }
         if(error.response){
         if (error.response.status === 413){
            error.response.data._error_message="Ảnh quá lớn. Chọn lại ảnh";
            return callback(error.response);
        }
        else if (error.response.status === 422){
            error.response.data._error_message="Kiểm tra kết nối camera";
            return callback(error.response);
        }
        else if (error.response) {
            return callback(error.response);
        }
        else {
            return callback(error.message);
        }
        }
    });
}
export{
    createKTMN,
    getKTMNDraw,
    apiAttachKTMN
}