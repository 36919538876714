const config_api = require("../../config/config").config_api;
const utils = require("../../utils");
const axios = require('axios');
/*
  -H ""Content-Type: multipart/form-data""
  -H ""Authorization: Bearer ${AUTH_TOKEN}""
  -F attached_file=@test.png
  -F contentType=ticket ( hoặc avatar, wallet)"
*/
function apiAttachment(file,contentType,callback) {
    if(file===null){
        return callback({data:{_error_message:"Chưa chụp được ảnh"}});
    }else if(file.size>5242880){
        return callback({data:{_error_message:"Ảnh quá lớn. Chọn lại ảnh"}});
    }
    var bodyFormData = new FormData();
    bodyFormData.append('attached_file', file);
    bodyFormData.append('contentType', contentType);
    axios({
        url: config_api.attachment,
        method: 'POST',
        headers: {
            "Content-type": "multipart/form-data",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: bodyFormData
    })
    .then(result => {
        return callback(false, result.data);
    })
    .catch(error => {
        if(error.request){
            return callback("Đường truyền bị gián đoạn");
        }
        else if (error.response.status === 413){
            error.response.data._error_message="Ảnh quá lớn. Chọn lại ảnh";
            return callback(error.response);
        }
        else if (error.response.status === 422){
            error.response.data._error_message="Kiểm tra kết nối camera";
            return callback(error.response);
        }
        else if (error.response) {
            return callback(error.response);
        }
        else {
            return callback(error.message);
        }
    });
}

function getLinkAttachment(file,contentType){
    apiAttachment(file,contentType,(err,result)=>  {
        if(err){
            return null;
        } else {
            return result.pathUrl;
        }
    });
}
module.exports = {
    apiAttachment: apiAttachment,
    getLinkAttachment:getLinkAttachment
};