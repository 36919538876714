import React from "react";
import { Row, Label, FormGroup, Button,Table, Jumbotron } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {revenue,revenueThirdParty} from '../api/apiStatistic';
import {getKTMNRevenue} from '../api/thirdPartyApi'
import {listPos} from '../api/apiPos';
import {convertDate,checkObjectUndefined} from '../../../utils/'
import Notification from './../../../components/Notification';
const moment = require('moment');
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromdate: null,
            todate: null,
            listPos : [],
            revKTMN:[],
            revThirdParty:[],
        };
        this.rowPage = [1000,2000,2500];
        this.tableheaderThirdParty=[
            {label:"Mã POS"},
            {label:"Ngày"},
            {label:"3D"},
            {label:"3D+"},
            {label:"Max 3d Pro"},
            {label:"Mega 6/45"},
            {label:"Power 6/55"},
            {label:"keno"},
            {label:"Keno Bao"},
            {label:"Kiến thiết"},
            {label:"Điện toán 235"},
            {label:"Điện toán 234"},
            {label:"Điện toán 6x36"},
            {label:"Thần tài"},
            {label:"Điện toán 123"},
            {label:"Phí dịch vụ"},
            {label:"Doanh thu - dịch vụ"},
            {label:"Tổng tiền bán vé"},
        ]
        this.headerKTMN=[
            {label:'Ngày'},
            {label:'Tiền vé'},
            {label:'Phí dịch vụ'},
            {label:'Tổng'},
        ]
        this.tableheader1=[
            {label:"Mã POS"},
            {label:"Ngày"},
            {label:"Số dư đầu"},
            {label:"3D"},
            {label:"3D+"},
            {label:"Max 3d Pro"},
            {label:"4D"},
            {label:"Mega 6/45"},
            {label:"Power 6/55"},
            {label:"keno"},
            {label:"Keno Bao"},
            {label:"Kiến thiết"},
            {label:"Điện toán 235"},
            {label:"Điện toán 234"},
            {label:"Điện toán 6x36"},
            {label:"Thần tài"},
            {label:"Điện toán 123"},
            {label:"Phí dịch vụ"},
            {label:"Doanh thu - dịch vụ"},
            {label:"Tổng tiền bán vé"},
            {label:"Tiền trả thưởng"},
            {label:"Hủy vé"},
            {label:"Hoa hồng"},
            {label:"Lệ phí"},
            {label:"Lợi nhuận"},
            {label:"Tăng hạn mức"},
            {label:"Số dư cuối"},
        ];
        this.filter={
            size: 1000,
            page:1,
        };
        this.allOptionPos=[];
        this.activeOptionPos=[];
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    async getRevenue(page){
        if(this.state.isLoading)
            return;
        else 
            this.setState({isLoading:true})
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        Object.assign(this.filter,{fromdate: convertDate(this.state.fromdate)},{todate:convertDate(this.state.todate)});
        if(this.filter.code==="Tất cả") delete this.filter.code;
       // this.filter.code='154000010038'
        revenue(
            this.filter,
            (err, result) => {
            if (err) {
                switch (err.data["_error_message"]) {
                    case "POS not found!":
                        return ( Notification("error","Mã POS không tồn tại"))
                    default:
                        return(Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên"))
                }
            } else {
                this.setState({statisticData: result, pageNum : Math.ceil(result.count/this.filter.size)})
            }
            this.setState({isLoading:false})
        });
        revenueThirdParty(
            this.filter,
            (err, result) => {
            if (err) {
                console.log(err);
                return(Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên"))
            } else {
                this.setState({revThirdParty: result})
            }
            this.setState({isLoading:false})
        });
        let [err,revKTMN]=await getKTMNRevenue(this.filter);
        if(err){
            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên"+err.data['_error_message']);
            return
        }
        else{
            revKTMN = revKTMN.map(e=>{
                return {...e,createdDate:moment(e.createdDate,'DD-MM-YYYY HH:mm:ss').format("DD-MM-YYYY")}
            })
            const group = revKTMN.reduce((acc, obj) => {
                const key = obj.thirdPartyName;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
              }, {});

            this.setState({revKTMN: group});
        }
        
    }

    handleToggleSelectPos(optionSelected){
        if(optionSelected.value === "toggle display pos inactive"){
            let optionShowPosInactive = !this.state.optionShowPosInactive;
            this.filter.code = "Tất cả";
            this.setState({
                listPos: optionShowPosInactive ? this.allOptionPos:this.activeOptionPos,
                optionShowPosInactive: optionShowPosInactive
            })
        } else{
            this.filter.code = optionSelected.label;
        }
    }

    componentDidMount(){
        listPos({},(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.allOptionPos = [{label: "Tất cả", value: ""},{label: "Ẩn/hiện POS bị khoá", value: "toggle display pos inactive"}];
                this.activeOptionPos = [];
                result.forEach(pos => {
                    let posItem = {label: pos.code, value: pos.id, isActive: pos.isActive}
                    
                    this.allOptionPos.push(posItem);
                    if(pos.isActive){
                        this.activeOptionPos.push(posItem);
                    }
                });
                this.activeOptionPos.sort((a,b)=>{
                    return parseInt(a.label.slice(-5)) - parseInt(b.label.slice(-5))
                })
                this.activeOptionPos=[{label: "Tất cả", value: ""},{label: "Ẩn/hiện POS bị khoá", value: "toggle display pos inactive"}].concat(this.activeOptionPos)
                this.setState({
                    listPos : this.activeOptionPos,
                    optionShowPosInactive : false
                });
            }
        });
    }
    renderKTMN(){
        let dataReturn=[]
        for (let key in this.state.revKTMN) {
            if (this.state.revKTMN.hasOwnProperty(key)) {
            let row = this.state.revKTMN[key];
            dataReturn.push(<div style={{margin:10}}><Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
            <thead>
                <tr ><td colSpan={4}>{key}</td></tr>
                <tr>
                    {
                        this.headerKTMN.map((header)=>{
                            return (
                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                <tr style={{fontWeight:'bold'}}>
                    <td>Tổng</td>
                    <td>{(row.reduce((sum,b)=> sum+b.revenueTicket,0)*1000).getMoneyFormat()}</td>
                    <td>{(row.reduce((sum,b)=> sum+b.revenueService,0)*1000).getMoneyFormat()}</td>
                    <td>{((row.reduce((sum,b)=> sum+b.revenueTicket*1000,0))+(row.reduce((sum,b)=> sum+b.revenueService*1000,0))).getMoneyFormat()}</td>
                </tr>
                {
                    row.map(e=>(<tr><td>{e.createdDate}</td><td>{(e.revenueTicket*1000).getMoneyFormat()}</td><td>{(e.revenueService*1000).getMoneyFormat()}</td><td>{((e.revenueTicket+e.revenueService)*1000).getMoneyFormat()}</td></tr>))
                }
            </tbody>
        </Table></div>) ;
            }
          }
          return dataReturn
    }
    renderThirdPartyRevenue(){
        let allStore = [];
        let dataReturn=[]
        for (let data of this.state.revThirdParty) {
            if(allStore.indexOf(data.thirdPartyName) == -1){
                allStore.push(data.thirdPartyName)
            }
        }
        for(let store of allStore){
            let data = this.state.revThirdParty.filter(e=>e.thirdPartyName==store);
            dataReturn.push(<Row className='pt-2 p-1 scroll-table  '><Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
            <thead>
                <tr ><td colSpan={18}>{store}</td></tr>
                <tr>
                    {
                        this.tableheaderThirdParty.map((header)=>{
                            return (
                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                <tr style={{fontWeight:'bold'}}>
                    <td>Tổng</td>
                    <td></td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[4]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[5]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[13]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[1]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[2]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[6]-(b.source.kenoBaoMoney??0)),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.kenoBaoMoney??0),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[14]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[7]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[8]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[9]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[10]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[11]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[12]),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat((b.source.moneyArray[0]-b.source.moneyArray[12])),0)*1000).getMoneyFormat()}</td>
                    <td>{(data.reduce((sum,b)=> sum+parseFloat(b.source.moneyArray[0]),0)*1000).getMoneyFormat()}</td>
                </tr>
                {
                    data.map(row=>(<tr>
                        <td></td>
                        <td>{row.createdDate.substr(0, 10)}</td>                                          
                        <td>{(row.source.moneyArray[4]).getMoneyFormat()}</td>
                        <td>{(row.source.moneyArray[5]).getMoneyFormat()}</td>
                        <td>{(row.source.moneyArray[13]??0).getMoneyFormat()}</td>
                        <td>{row.source.moneyArray[1].getMoneyFormat()}</td>
                        <td>{row.source.moneyArray[2].getMoneyFormat()}</td>
                        <td>{(row.source.moneyArray[6]-(row.source.kenoBaoMoney??0)).getMoneyFormat()}</td>
                        <td>{(row.source.kenoBaoMoney??0).getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[14].getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[7].getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[8].getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[9].getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[10].getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[11].getMoneyFormat()}</td>
                        <td>{ row.source.moneyArray[12].getMoneyFormat()}</td>
                        <td>{(row.source.moneyArray[0]-row.source.moneyArray[12]).getMoneyFormat()}</td>
                        <td>{row.source.moneyArray[0].getMoneyFormat()}</td>
                    </tr>))
                }
            </tbody>
        </Table></Row>)
        }
        return dataReturn
    }
    render() {
        return(
            <React.Fragment>
                <Row className='justify-content-center m-0'>
                    <FormGroup className='mb-3 revenueStatistic_main_formGroup'>
                        <Label className="d-block">Ngày bắt đầu</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.fromdate===null ? new Date(): this.state.fromdate}
                            onChange={date =>this.setState({fromdate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <FormGroup className='mb-3 ml-3 revenueStatistic_main_formGroup'>
                        <Label className="d-block">Ngày kết thúc</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.todate===null ? new Date(): this.state.todate}
                            onChange={date => this.setState({todate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                  
                    <FormGroup className='mb-3 ml-3'>
                        <Label className="d-block">Nhập mã POS</Label>
                        <Select
                            className="header-select-statistic"
                            name='code'
                            placeholder="Chọn mã pos"
                            classNamePrefix='react-select'
                            options={this.state.listPos}
                            onChange={(value)=> this.handleToggleSelectPos(value)}
                        />
                    </FormGroup> 
                    <div className='mt-4 p-2'>
                        <Button color='primary' onClick={this.getRevenue.bind(this,null)}>
                            Tìm kiếm
                        </Button>
                    </div>
                </Row>
                <div className='mx-4 p-3 w-120 borderRadius text-center'>
                    <div className="d-inline">
                    <span className='content-title-bold'>THỐNG KÊ DOANH THU</span>
                    </div>
                    <ReactHTMLTableToExcel
                        id="table-xls-button"
                        className="download-table-xls-button float-right d-inline"
                        table="thong-ke-khach-hang"
                        filename={`THỐNG KÊ DOANH THU TỪ ${this.filter.fromdate} ĐẾN ${this.filter.todate}  POS ${this.filter.code}`}
                        sheet={`sheet1`}
                        buttonText="Tải xuống"
                    />
                </div>
                <div className=' mx-4 p-3 mt-2 w-120  text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                                {
                                    checkObjectUndefined(this.state,["statisticData","total","source"])
                                    ?(
                                        <tr>
                                            <th></th>
                                            <th>Tổng</th>
                                            <th>{this.state.statisticData.total.source.wallet.balance.getMoneyFormat()}</th>
                                            
                                            <th>{(this.state.statisticData.total.source.moneyArray[4]).getMoneyFormat()}</th>
                                            <th>{(this.state.statisticData.total.source.moneyArray[5]).getMoneyFormat()}</th>
                                            <th>{(this.state.statisticData.total.source.moneyArray[13]??0).getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyArray[3].getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyArray[1].getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyArray[2].getMoneyFormat()}</th>
                                            <th>{(this.state.statisticData.total.source.moneyArray[6]-(this.state.statisticData.total.source.kenoBaoMoney??0)).getMoneyFormat()}</th>
                                            <th>{(this.state.statisticData.total.source.kenoBaoMoney??0).getMoneyFormat()}</th>
                                            {
                                                this.state.statisticData.total.source.moneyArray.length>14?
                                               
                                               ( <>
                                                <th>{ this.state.statisticData.total.source.moneyArray[14].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[7].getMoneyFormat()}</th>
                                                <th>{this.state.statisticData.total.source.moneyArray[8].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[9].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[10].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[11].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[12].getMoneyFormat()}</th>

                                                </>)
                                                :(
                                                <>
                                                <th>{0}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[7].getMoneyFormat()}</th>
                                                <th>{this.state.statisticData.total.source.moneyArray[8].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[9].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[10].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[11].getMoneyFormat()}</th>
                                                <th>{ this.state.statisticData.total.source.moneyArray[12].getMoneyFormat()}</th>
                                                </>
                                                )
                                            }
                                            <th>{(this.state.statisticData.total.source.moneyArray[0]-this.state.statisticData.total.source.moneyArray[12]).getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyArray[0].getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyWonLotteryReal.getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyTicketRefundReal.getMoneyFormat()}</th>
                                            <th>{(this.state.statisticData.total.source.moneyTicketSalesExtra - this.state.statisticData.total.source.moneyTicketRefundExtra).getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyWonLotteryExtra.getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.totalExtra.getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.moneyAddition.getMoneyFormat()}</th>
                                            <th>{this.state.statisticData.total.source.wallet.availableBalance.getMoneyFormat()}</th>
                                        </tr>
                                    ): null
                                }
                            </thead>
                            <tbody>
                                {
                                    this.state.statisticData
                                    ?this.state.statisticData.rows.map((row)=>{
                                        return(
                                            <tr>
                                                <td>{this.filter.code==="Tất cả" ? "" :this.filter.code}</td>
                                                <td>{row.createdDate.substr(0, 10)}</td>
                                                <td>{row.source.wallet.balance.getMoneyFormat()}</td>                                                
                                                <td>{(row.source.moneyArray[4]).getMoneyFormat()}</td>
                                                <td>{(row.source.moneyArray[5]).getMoneyFormat()}</td>
                                                <td>{(row.source.moneyArray[13]??0).getMoneyFormat()}</td>
                                                <td>{row.source.moneyArray[3].getMoneyFormat()}</td>
                                                <td>{row.source.moneyArray[1].getMoneyFormat()}</td>
                                                <td>{row.source.moneyArray[2].getMoneyFormat()}</td>
                                                <td>{(row.source.moneyArray[6]-(row.source.kenoBaoMoney??0)).getMoneyFormat()}</td>
                                                <td>{(row.source.kenoBaoMoney??0).getMoneyFormat()}</td>
                                                {
                                                    row.source.moneyArray.length>14?
                                               
                                               ( <>
                                                <td>{ row.source.moneyArray[14].getMoneyFormat()}</td>
                                                <td>{ row.source.moneyArray[7].getMoneyFormat()}</td>
                                            <td>{ row.source.moneyArray[8].getMoneyFormat()}</td>
                                            <td>{ row.source.moneyArray[9].getMoneyFormat()}</td>
                                            <td>{ row.source.moneyArray[10].getMoneyFormat()}</td>
                                            <td>{ row.source.moneyArray[11].getMoneyFormat()}</td>
                                            <td>{ row.source.moneyArray[12].getMoneyFormat()}</td>
                                                </>)
                                                :(
                                                <>
                                                <td>{0}</td>
                                                <td>{ row.source.moneyArray[7].getMoneyFormat()}</td>
                                                <td>{ row.source.moneyArray[8].getMoneyFormat()}</td>
                                                <td>{ row.source.moneyArray[9].getMoneyFormat()}</td>
                                                <td>{ row.source.moneyArray[10].getMoneyFormat()}</td>
                                                <td>{ row.source.moneyArray[11].getMoneyFormat()}</td>
                                                <td>{ row.source.moneyArray[12].getMoneyFormat()}</td>
                                                </>
                                                )
                                            }
                                            <td>{(row.source.moneyArray[0]-row.source.moneyArray[12]).getMoneyFormat()}</td>
                                                <td>{row.source.moneyArray[0].getMoneyFormat()}</td>
                                                <td>{row.source.moneyWonLotteryReal.getMoneyFormat()}</td>
                                                <td>{row.source.moneyTicketRefundReal.getMoneyFormat()}</td>
                                                <td>{(row.source.moneyTicketSalesExtra - row.source.moneyTicketRefundExtra).getMoneyFormat()}</td>
                                                <td>{row.source.moneyWonLotteryExtra.getMoneyFormat()}</td>
                                                <td>{row.source.totalExtra.getMoneyFormat()}</td>
                                                <td>{row.source.moneyAddition.getMoneyFormat()}</td>
                                                <td>{row.source.wallet.availableBalance.getMoneyFormat()}</td>
                                            </tr>
                                        )
                                    })
                                    : null
                                }
                            </tbody>
                        </Table>
                    </Row>
                    
                    <Row className="mt-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            <Button disabled={this.filter.page===1} onClick={this.getRevenue.bind(this,"-")}>{`<`}</Button>
                            <Button hidden={this.filter.page===1} onClick={this.getRevenue.bind(this,"-")}>{this.filter.page-1}</Button>
                            <Button className="btn-danger">{this.filter.page}</Button>
                            <Button hidden={this.state.statisticData?this.state.statisticData.rows.length < this.filter.size: true } onClick={this.getRevenue.bind(this,"+")}>{this.filter.page+1}</Button>
                            <Button disabled={this.state.statisticData?this.state.statisticData.rows.length < this.filter.size: true } onClick={this.getRevenue.bind(this,"+")}>{`>`}</Button>
                        </div>
                        {console.log(this.state.statisticData?this.state.statisticData.rows.length:0,this.state.statisticData?this.state.statisticData.rows.length < this.filter.size: true)}
                    </Row>
                    <div>
                        <div className='mx-4 p-3 w-120 borderRadius text-center'>
                        <div className="d-inline">
                        <span className='content-title-bold'>DOANH THU KTMN</span>
                        </div>
                        </div>
                        <Row style={{justifyContent:'center'}}>
                        {
                            this.renderKTMN()
                        }
                        </Row>
                    </div>
                    <div>
                        <div className='mx-4 p-3 w-120 borderRadius text-center'>
                        <div className="d-inline">
                        <span className='content-title-bold'>DOANH THU IN VÉ</span>
                        </div>
                        </div>
                        <Row style={{justifyContent:'center'}}>
                        {
                            this.renderThirdPartyRevenue()
                        }
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
