const {config_api} = require("../../../config/config");
const utils = require("../../../utils");
const axios = require('axios');

function rejectTicket(productId,value,reason,reprint,file,callback) {
    if(file===null){
        return callback({data:{_error_message:"Chưa chụp được ảnh"}});
    }else if(file.size>5242880){
        return callback({data:{_error_message:"Ảnh quá lớn. Chọn lại ảnh"}});
    }
    var bodyFormData = new FormData();
    bodyFormData.append('contentType', "ticket");
    bodyFormData.append('attached_file', file);
    bodyFormData.append('productId', productId);
    bodyFormData.append('value', value);
    bodyFormData.append('reason', reason);
    bodyFormData.append('reprint', reprint);
    axios({
        url: `${config_api.pos}/cancer-ticket`,
        method: 'POST',
        headers: {
            "Content-type": "multipart/form-data",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: bodyFormData
    })
    .then(result => {
        return callback(false, result.data);
    })
    .catch(error => {
        // if(error.request){
        //     return callback("Đường truyền bị gián đoạn");
        // }
        // else if (error.response.status === 413){
        //     error.response.data._error_message="Ảnh quá lớn. Chọn lại ảnh";
        //     return callback(error.response);
        // }
        // else if (error.response.status === 422){
        //     error.response.data._error_message="Kiểm tra kết nối camera";
        //     return callback(error.response);
        // }
        // else if (error.response) {
        //     return callback(error.response);
        // }
        // else {
            return callback(error.message);
        // }
    });
}


module.exports = {
    rejectTicket: rejectTicket
};