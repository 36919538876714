import React from 'react';
import { ButtonGroup, Button, Input, Col, Row } from 'reactstrap';
import "./css/pushLotteryResults.css";
import DatePicker from "react-datepicker";
import Notification from "../../components/Notification"
import {confirm,isEmpty} from "../../utils/index"
import moment from "moment"
const {getCurrentTerm} = require('./api/apiTicket');
const {postInputResult} = require('./api/apiLotteryResultInput');

const transition={
    giainhat: "giải nhất",
    giainhi: "giải nhì",
    giaiba: "giải ba",
    giaikhuyenkhich: "giải khuyến khích",
}

class InputNumberLottey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value : ""
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.reRender !== this.props.reRender){
            this.props.onChange(this.props.name, "")
            this.setState({value : ""})
        }
    }

    shouldComponentUpdate(np,ns){
        if(np.reRender===this.props.reRender && ns.value===this.state.value) return false;
        return true
    }


    numberOnChange(event){
        event.target.value = event.target.value.replace(/\D/g,'');
        this.props.onChange(event.target.name, event.target.value)
        this.setState({value: event.target.value});
    }

    numberOnBlur(event){
        let value = event.target.value;
        if(isEmpty(this.state.value)) return;
        if(value.length < this.props.maxLength){
            for (let i = 0; i < this.props.maxLength-this.state.value.length; i++) {
                value="0"+value;
            }
        }
        if(this.props.specialCharacters) value=this.props.specialCharacters+value;
        this.props.onChange(event.target.name, value)
        this.setState({value: value});
    }

    render() {
        return (
            <Input
                className={`my-1 text-center border-red ${this.props.className}`}
                maxLength={this.props.maxLength}
                name={this.props.name}
                value={this.state.value}
                autoComplete='false'
                onChange={this.numberOnChange.bind(this)}
                onBlur={this.numberOnBlur.bind(this)}
            />
        );
    }
}

class InputJackpot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value : 0
        }
    }

    numberOnChange(event){
        event.target.value = event.target.value.replace(/\D/g,'');
        this.props.onChange(event.target.name, event.target.value)
        this.setState({value: event.target.value});
    }

    render() {
        return (
            <Input
                className={`my-1 text-center border-red ${this.props.className}`}
                maxLength={this.props.maxLength}
                name={this.props.name}
                autoComplete='false'
                value={parseInt(this.state.value).getMoneyFormat()}
                onChange={this.numberOnChange.bind(this)}
            />
        );
    }
}

class Lottey3D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            reRender: 1
        }
        this.lottey={
            giainhat:[null, null],
            giainhi : [null, null, null, null],
            giaiba : [null, null, null, null, null, null],
            giaikhuyenkhich : [null, null, null, null, null, null, null, null]
        }
    }

    inputOnchange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    numberOnChange(inputName,value){
        let name = inputName.split("-");
        this.lottey[name[0]][name[1]] = value;
    }

    checkInput(){
        // eslint-disable-next-line
        for (const key in this.lottey) {
            for (let i = 0; i < this.lottey[key].length; i++) {
                if(isEmpty(this.lottey[key][i])) 
                    return Notification('error',"Lỗi",`Bộ số thứ ${i+1} ${transition[key]} không được bỏ trống`);
            }
        }
        return true;
    }

    handApi(){
        let dataApi={
            "productId": "4",
            "data": {
                "term": this.state.term || this.props.currentTerm,
                "result": [
                    {"type":"giainhat","result":this.lottey.giainhat},
                    {"type":"giaiba","result":this.lottey.giaiba},
                    {"type":"giainhi","result":this.lottey.giainhi},
                    {"type":"giaikhuyenkhich","result":this.lottey.giaikhuyenkhich},
                ],
                "date": moment(this.state.date).format("DD-MM-YYYY")
            }
        }
        postInputResult(dataApi,(err)=>{
            if(err){
                if( err.data && err.data._error_message==="Term is exist"){
                    return Notification("error", `Hệ thống đã có kết quả kỳ ${this.state.term||this.props.currentTerm}`);
                }
                if( err.data && err.data._error_message==="Time input invalid"){
                    return Notification("error", "Không cho phép cập nhật ở thời điểm hiện tại");
                }
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else{
                Notification("success","Thêm kết quả thành công");
                this.setState({reRender: this.state.reRender+1})
            }
        })
    }

    handSubmitedForm(){
        if(this.checkInput() !== true) return false
        confirm("Chú ý, Kiểm tra lại dãy số trúng thưởng. Cập nhật kết quả có thể ảnh hưởng đến hệ thống nếu sai sót.", (check) => {
            if (check) {
                this.handApi();
            }
        });
    }
    shouldComponentUpdate(np,ns){
        return np.tabLotteryResults === "3D" || ns.reRender!==this.state.reRender
    }

    render() {
        return (
            <React.Fragment>
                <div className="row-tittle d-flex justify-content-center">
                    <h3>Nhập kết quả 3D thủ công</h3>
                </div>
                <div className="row-term d-flex">
                    <div className="d-inline">
                        <p className="m-0 ">Kỳ số: </p>
                        <Input
                            className="term-input"
                            name="term"
                            type="number"
                            value={this.state.term || this.props.currentTerm}
                            onChange={this.inputOnchange.bind(this)}
                        />
                    </div>
                    <div className="d-inline">
                        <p className="m-0 ">Ngày</p>
                        <DatePicker
                            showPopperArrow={false}
                            selected={this.state.date}
                            onChange={date =>this.setState({date: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </div>
                <div className="row-result pt-3 ">
                    <div className="row-number ">
                        <h4 className="text-center mt-2 mb-0">Giải nhất</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giainhat.map((number=null,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="3"
                                                name={`giainhat-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <h4 className="text-center mt-2 mb-0">Giải nhì</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giainhi.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="3"
                                                name={`giainhi-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <h4 className="text-center mt-2 mb-0">Giải ba</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giaiba.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="3"
                                                name={`giaiba-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <h4 className="text-center mt-2 mb-0">Giải khuyến khích</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giaikhuyenkhich.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="3"
                                                name={`giaikhuyenkhich-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button className="btn-success" size="lg" onClick={this.handSubmitedForm.bind(this)}>Hoàn thành</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class Lottey4D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            reRender: 1
        }
        this.lottey={
            giainhat:[null],
            giainhi : [null, null],
            giaiba : [null, null, null],
            giaikhuyenkhich : [null, null]
        }
    }

    inputOnchange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    numberOnChange(inputName,value){
        let name = inputName.split("-");
        this.lottey[name[0]][name[1]] = value;
    }

    checkInput(){
        // eslint-disable-next-line
        for (const key in this.lottey) {
            for (let i = 0; i < this.lottey[key].length; i++) {
                if(isEmpty(this.lottey[key][i])) 
                    return Notification('error',"Lỗi",`Bộ số thứ ${i+1} ${transition[key]} không được bỏ trống`);
            }
        }
        return true;
    }

    handApi(){
        let dataApi={
            "productId": "3",
            "data": {
                "term": this.state.term || this.props.currentTerm,
                "result": [
                    {"type":"giainhat","result":this.lottey.giainhat},
                    {"type":"giaiba","result":this.lottey.giaiba},
                    {"type":"giainhi","result":this.lottey.giainhi},
                    {"type":"giaikhuyenkhich","result":this.lottey.giaikhuyenkhich},
                ],
                "date": moment(this.state.date).format("DD-MM-YYYY")
            }
        }
        postInputResult(dataApi,(err)=>{
            if(err){
                if( err.data && err.data._error_message==="Term is exist"){
                    return Notification("error", `Hệ thống đã có kết quả kỳ ${this.state.term||this.props.currentTerm}`);
                }
                if( err.data && err.data._error_message==="Time input invalid"){
                    return Notification("error", "Không cho phép cập nhật ở thời điểm hiện tại");
                }
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else{
                Notification("success","Thêm kết quả thành công");
                this.setState({reRender: this.state.reRender+1})
            }
        })
    }

    handSubmitedForm(){
        if(this.checkInput() !== true) return false
        confirm("Chú ý, Kiểm tra lại dãy số trúng thưởng. Cập nhật kết quả có thể ảnh hưởng đến hệ thống nếu sai sót.", (check) => {
            if (check) {
                this.handApi();
            }
        });
    }

    shouldComponentUpdate(np){
        return np.tabLotteryResults === "4D"
    }

    render() {
        return (
            <React.Fragment>
                <div className="row-tittle d-flex justify-content-center">
                    <h3>Nhập kết quả 4D thủ công</h3>
                </div>
                <div className="row-term d-flex">
                    <div className="d-inline">
                        <p className="m-0 ">Kỳ số: </p>
                        <Input
                            className="term-input"
                            name="term"
                            type="number"
                            value={this.state.term || this.props.currentTerm}
                            onChange={this.inputOnchange.bind(this)}
                        />
                    </div>
                    <div className="d-inline">
                        <p className="m-0 ">Ngày</p>
                        <DatePicker
                            showPopperArrow={false}
                            selected={this.state.date}
                            onChange={date =>this.setState({date: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </div>
                <div className="row-result pt-3 ">
                    <div className="row-number ">
                        <h4 className="text-center mt-2 mb-0">Giải nhất</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giainhat.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="4"
                                                name={`giainhat-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <h4 className="text-center mt-2 mb-0">Giải nhì</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giainhi.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="4"
                                                name={`giainhi-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <h4 className="text-center mt-2 mb-0">Giải ba</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giaiba.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength="4"
                                                name={`giaiba-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <h4 className="text-center mt-2 mb-0">Giải khuyến khích</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.giaikhuyenkhich.map((number,index)=>{
                                    return(
                                        <Col xs="3" sm="2" xl="1" key={index}>
                                            <InputNumberLottey
                                                className="number-3"
                                                maxLength={3-index}
                                                name={`giaikhuyenkhich-${index}`}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                                specialCharacters= {index===0?"x":"xx"}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button className="btn-success" size="lg" onClick={this.handSubmitedForm.bind(this)}>Hoàn thành</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class LotteyMega extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            reRender: 1
        }
        this.lottey=[null,null,null,null,null,null];
        this.jackport=["0"]
    }

    inputOnchange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    numberOnChange(inputName,value){
        this.lottey[inputName] = value;
    }

    jackpotOnChange(inputName,value){
        this.jackport[inputName] = value;
    }

    checkInput(){
        for (let i = 0; i < this.lottey.length; i++) {
            if(isEmpty(this.lottey[i])) 
                return Notification('error',"Lỗi",`Bộ số thứ ${i+1} không được bỏ trống`);
        }
        return true;
    }

    handApi(){
        let dataApi={
            "productId": "1",
            "data": {
                "term": this.state.term || this.props.currentTerm,
                "date": moment(this.state.date,).format("DD-MM-YYYY"),
                "result": this.lottey,
                "statistical": [{"giaithuong":"Jackpot","ketqua":"O O O O O O","soluonggiai":"0","giatrigiai":parseInt(this.jackport[0]).getMoneyFormat()}]
            }
        }
        postInputResult(dataApi,(err)=>{
            if(err){
                if( err.data && err.data._error_message==="Term is exist"){
                    return Notification("error", `Hệ thống đã có kết quả kỳ ${this.state.term||this.props.currentTerm}`);
                }
                if( err.data && err.data._error_message==="Time input invalid"){
                    return Notification("error", "Không cho phép cập nhật ở thời điểm hiện tại");
                }

                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else{
                Notification("success","Thêm kết quả thành công");
                // this.setState({reRender: this.state.reRender+1})
            }
        })
    }

    handSubmitedForm(){
        if(this.checkInput() !== true) return false
        confirm("Chú ý, Kiểm tra lại dãy số trúng thưởng. Cập nhật kết quả có thể ảnh hưởng đến hệ thống nếu sai sót.", (check) => {
            if (check) {
                this.handApi();
            }
        });
    }

    shouldComponentUpdate(np){
        return np.tabLotteryResults === "MEGA"
    }

    render() {
        return (
            <React.Fragment>
                <div className="row-tittle d-flex justify-content-center">
                    <h3>Nhập kết quả MEGA 6/45 thủ công</h3>
                </div>
                <div className="row-term d-flex">
                    <div className="d-inline">
                        <p className="m-0 ">Kỳ số: </p>
                        <Input
                            className="term-input"
                            name="term"
                            type="number"
                            value={this.state.term || this.props.currentTerm}
                            onChange={this.inputOnchange.bind(this)}
                        />
                    </div>
                    <div className="d-inline">
                        <p className="m-0 ">Ngày</p>
                        <DatePicker
                            showPopperArrow={false}
                            selected={this.state.date}
                            onChange={date =>this.setState({date: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </div>
                <div className="row-result pt-3 ">
                    <div className="row-number ">
                        <h4 className="text-center mt-2 ">Bộ số trúng thưởng</h4>
                        <div className="justify-content-center d-flex">
                            {
                                this.lottey.map((number,index)=>{
                                    return(
                                        <div key={index}>
                                            <InputNumberLottey
                                                className="number-2"
                                                maxLength="2"
                                                name={index}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* GIA TRI JACKPOT */}
                        <h4 className="text-center mt-2 ">Giá trị JACKPOT</h4>
                        <div className="justify-content-center d-flex">
                            <InputJackpot
                                className="input-jackpot"
                                maxLength="20"
                                name="0"
                                onChange={this.jackpotOnChange.bind(this)}
                            />
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <Button className="btn-success" size="lg" onClick={this.handSubmitedForm.bind(this)}>Hoàn thành</Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class LotteyPower extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            reRender: 1
        }
        this.lottey=[null,null,null,null,null,null,null];
        this.jackport=["0","0"]
    }

    inputOnchange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    numberOnChange(inputName,value){
        this.lottey[inputName] = value;
    }

    jackpotOnChange(inputName,value){
        this.jackport[inputName] = value;
    }

    checkInput(){
        for (let i = 0; i < this.lottey.length; i++) {
            if(isEmpty(this.lottey[i])) 
                return Notification('error',"Lỗi",`Bộ số thứ ${i+1} không được bỏ trống`);
        }
        return true;
    }

    handApi(){
        let dataApi={
            "productId": "2",
            "data": {
                "term": this.state.term || this.props.currentTerm,
                "date": moment(this.state.date,).format("DD-MM-YYYY"),
                "result": this.lottey,
                "statistical": [
                    {"giaithuong":"Jackpot 1","ketqua":"O O O O O O","soluonggiai":"0","giatrigiai":parseInt(this.jackport[0]).getMoneyFormat()},
                    {"giaithuong":"Jackpot 2","ketqua":"O O O O O | O","soluonggiai":"1","giatrigiai":parseInt(this.jackport[1]).getMoneyFormat()}
                ]
            }
        }
        postInputResult(dataApi,(err)=>{
            if(err){
                if( err.data && err.data._error_message==="Term is exist"){
                    return Notification("error", `Hệ thống đã có kết quả kỳ ${this.state.term||this.props.currentTerm}`);
                }
                if( err.data && err.data._error_message==="Time input invalid"){
                    return Notification("error", "Không cho phép cập nhật ở thời điểm hiện tại");
                }
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else{
                Notification("success","Thêm kết quả thành công");
                this.setState({reRender: this.state.reRender+1})
            }
        })
    }

    handSubmitedForm(){
        if(this.checkInput() !== true) return false
        confirm("Chú ý, Kiểm tra lại dãy số trúng thưởng. Cập nhật kết quả có thể ảnh hưởng đến hệ thống nếu sai sót.", (check) => {
            if (check) {
                this.handApi();
            }
        });
    }

    shouldComponentUpdate(np){
        return np.tabLotteryResults === "POWER"
    }

    render() {
        return (
            <React.Fragment>
                <div className="row-tittle d-flex justify-content-center">
                    <h3>Nhập kết quả POWER 6/55 thủ công</h3>
                </div>
                <div className="row-term d-flex">
                    <div className="d-inline">
                        <p className="m-0 ">Kỳ số: </p>
                        <Input
                            className="term-input"
                            name="term"
                            type="number"
                            value={this.state.term || this.props.currentTerm}
                            onChange={this.inputOnchange.bind(this)}
                        />
                    </div>
                    <div className="d-inline">
                        <p className="m-0 ">Ngày</p>
                        <DatePicker
                            showPopperArrow={false}
                            selected={this.state.date}
                            onChange={date =>this.setState({date: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </div>
                <div className="row-result pt-3 ">
                    <div className="row-number ">
                        <h4 className="text-center mt-2 ">Bộ số trúng thưởng</h4>
                        <div className="justify-content-center d-flex">
                            {
                                this.lottey.map((number,index)=>{
                                    return(
                                        <div key={index}>
                                            <InputNumberLottey
                                                className={`number-2 ${index===6?"border-orange":""}`}
                                                maxLength="2"
                                                name={index}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* GIA TRI JACKPOT */}
                        {
                            this.jackport.map((v,index)=>{
                                return(
                                    <React.Fragment>
                                        <h4 className="text-center mt-2 ">Giá trị JACKPOT {index+1}</h4>
                                        <div className="justify-content-center d-flex">
                                            <InputJackpot
                                                className="input-jackpot"
                                                maxLength="20"
                                                name={index}
                                                onChange={this.jackpotOnChange.bind(this)}
                                            />
                                        </div>
                                    </React.Fragment>
                                )
                                
                            })
                        }
                        
                        
                        <div className="d-flex justify-content-center mt-3">
                            <Button className="btn-success" size="lg" onClick={this.handSubmitedForm.bind(this)}>Hoàn thành</Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class LotteyKeno extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            reRender: 1
        }
        this.lottey=Array.apply(null, Array(20));
    }

    inputOnchange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    numberOnChange(inputName,value){
        this.lottey[inputName] = value;
    }

    checkInput(){
        for (let i = 0; i < this.lottey.length; i++) {
            if(isEmpty(this.lottey[i])) 
                return Notification('error',"Lỗi",`Bộ số thứ ${i+1} không được bỏ trống`);
        }
        return true;
    }

    handApi(){
        let dataApi={
            "productId": "6",
            "data": {
                "term": this.state.term || this.props.currentTerm,
                "date": moment(this.state.date,).format("DD-MM-YYYY"),
                "result": this.lottey
            }
        }
        postInputResult(dataApi,(err)=>{
            if(err){
                if( err.data && err.data._error_message==="Term is exist"){
                    return Notification("error", `Hệ thống đã có kết quả kỳ ${this.state.term||this.props.currentTerm}`);
                }
                if( err.data && err.data._error_message==="Time input invalid"){
                    return Notification("error", "Không cho phép cập nhật ở thời điểm hiện tại");
                }
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else{
                Notification("success","Thêm kết quả thành công");
                this.setState({reRender: this.state.reRender+1})
            }
        })
    }

    handSubmitedForm(){
        if(this.checkInput() !== true) return false
        confirm("Chú ý, Kiểm tra lại dãy số trúng thưởng. Cập nhật kết quả có thể ảnh hưởng đến hệ thống nếu sai sót.", (check) => {
            if (check) {
                this.handApi();
            }
        });
    }

    shouldComponentUpdate(np){
        return np.tabLotteryResults === "KENO"
    }

    render() {
        return (
            <React.Fragment>
                <div className="row-tittle d-flex justify-content-center">
                    <h3>Nhập kết quả KENO thủ công</h3>
                </div>
                <div className="row-term d-flex">
                    <div className="d-inline">
                        <p className="m-0 ">Kỳ số: </p>
                        <Input
                            className="term-input"
                            name="term"
                            type="number"
                            value={this.state.term || this.props.currentTerm}
                            onChange={this.inputOnchange.bind(this)}
                        />
                    </div>
                    <div className="d-inline">
                        <p className="m-0 ">Ngày</p>
                        <DatePicker
                            showPopperArrow={false}
                            selected={this.state.date}
                            onChange={date =>this.setState({date: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </div>
                <div className="row-result pt-3 ">
                    <div className="row-number ">
                        <h4 className="text-center mt-2 ">Bộ số trúng thưởng</h4>
                        <Row className="justify-content-center d-flex m-0">
                            {
                                this.lottey.map((number,index)=>{
                                    return(
                                        <div key={index}>
                                            <InputNumberLottey
                                                className="number-2"
                                                maxLength="2"
                                                name={index}
                                                reRender={this.state.reRender}
                                                onChange={this.numberOnChange.bind(this)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Row>
                        
                        <div className="d-flex justify-content-center mt-3">
                            <Button className="btn-success" size="lg" onClick={this.handSubmitedForm.bind(this)}>Hoàn thành</Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class PushLotteryResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabLotteryResults:"3D"
        }
        this.productId = {
            "3D": 4,
            "4D": 3,
            "MEGA": 1,
            "POWER": 2,
            "KENO": 6
        }
    }

    shouldComponentUpdate(nextProps){
        return(nextProps.tabSelected === "pushLotteryResult")
    } 
    
    isActive(tab){
        return this.state.tabLotteryResults === tab ? "active":""
    }

    setTab(tab){
        getCurrentTerm(this.productId[tab],(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({currentTerm: result.termValue,tabLotteryResults: tab});
            }
        });
    }

    componentDidMount(){
        getCurrentTerm(this.productId[this.state.tabLotteryResults],(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({currentTerm: result.termValue});
            }
        });
    }
    render() {
        return (
            <div className="push-lottery-result-page p-2">
                <div className="group-button-header d-flex justify-content-center">
                    <ButtonGroup color="info">
                        <Button className={`type-ticket-button ${this.isActive("3D")} btn-info`} onClick={this.setTab.bind(this,"3D")}>3D</Button>
                        <Button className={`type-ticket-button ${this.isActive("4D")} btn-info`} onClick={this.setTab.bind(this,"4D")}>4D</Button>
                        <Button className={`type-ticket-button ${this.isActive("MEGA")} btn-info`} onClick={this.setTab.bind(this,"MEGA")}>MEGA</Button>
                        <Button className={`type-ticket-button ${this.isActive("POWER")} btn-info`} onClick={this.setTab.bind(this,"POWER")}>POWER</Button>
                        <Button className={`type-ticket-button ${this.isActive("KENO")} btn-info`} onClick={this.setTab.bind(this,"KENO")}>KENO</Button>
                    </ButtonGroup>
                </div>
                <div className="push-lottery-result-container pt-3">
                    {
                        this.state.tabLotteryResults==="3D"
                        ?
                          <Lottey3D currentTerm= {this.state.currentTerm} tabLotteryResults={this.state.tabLotteryResults}/>
                        : this.state.tabLotteryResults==="4D"
                        ? <Lottey4D currentTerm= {this.state.currentTerm} tabLotteryResults={this.state.tabLotteryResults}/>
                        : this.state.tabLotteryResults==="MEGA"
                        ? <LotteyMega currentTerm= {this.state.currentTerm} tabLotteryResults={this.state.tabLotteryResults}/>
                        : this.state.tabLotteryResults==="POWER"
                        ? <LotteyPower currentTerm= {this.state.currentTerm} tabLotteryResults={this.state.tabLotteryResults}/>
                        : this.state.tabLotteryResults==="KENO"
                        ? <LotteyKeno currentTerm= {this.state.currentTerm} tabLotteryResults={this.state.tabLotteryResults}/>
                        : null
                    }
                </div>
            </div>
        );
    }
}

export default PushLotteryResults;
