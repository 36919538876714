import React from "react";

import { Col, Row } from "reactstrap";

import "react-tabs/style/react-tabs.css";
import "./admin.css";
import { Power, Menu } from "react-feather";
import SidebarLeft from "./sidebarLeft";
import utils from "../../utils";

import Staffmanagement from "./content/staffmanagement";
import StoreManagement from "./content/storeManagement";
import WinPrize from "./content/winPrize";
import ConfigGeneral from "./content/configGeneral";
import PosManagerment from "./content/posManagerment";
import ConfigReasonsNotAllowRecharge from "./content/configReasonsNotAllowRecharge";
import ConfigReasonsNotAllowWithdrals from "./content/configReasonsNotAllowWithdrals";
import ConfigReasonsNotAllowWithdralsCleark from "./content/configReasonsNotAllowWithdralsCleark";
import ClerkHistory from "./content/clerkHistory";
import AccountantHistory from "./content/accountantHistory";
import AdminHistory from "./content/adminHistory";
import UserStatistical from "./content/userStatistical";
import UserStatisticalV2 from "./content/UserStaticalV2";
import CustomerTransactions from "./content/customerTransactions";
import StaffStatistical from "./content/staffStatistical";
import RevenueStatistics from "./content/revenueStatistics";
import MoneyHoldingStatistics from "./content/moneyHoldingStatistics";
import MoneyHoldingStatisticsOnDay from "./content/moneyHoldingStatisticsOnDay";
import RejectTicket from "./content/rejectTicket";
import Notifi from "./content/notifiManagement";
import logo from "../../assets/img/photos/logo.png";
import ConfigSeo from "./content/configSeo";
import StaffMonitor from './content/staffMonitor';
import GroupBuy from './content/groupBuyManager'
import Kienthiet from './content/kienthietManager'
import KTMN from './content/ktmnManager'
import ChangeTermKeno from './content/changeTermTicket'
import WinPrizeThirdParty from './content/winPrizeThirdParty'
const header = require("./header.json");

class Clerk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionTab: "staffmanagement",
      click: "staffmanagement",
      showSidebar: false,
    };
  }

  setOptionTab(tab) {
    this.setState({ optionTab: tab, click: tab, showSidebar: false });
  }

  toggle(Component) {
    this.setState({
      [Component]: !this.state[Component],
    });
  }

  render() {
    let uesrinfo = utils.getUserinfo();
    if (utils.isEmpty(uesrinfo) || uesrinfo.role !== "admin")
      window.location.replace("/");
    else if (utils.checkAuth())
      return (
        <React.Fragment>
          <div className="group-admin ">
            <Row className="p-0 m-0 admin">
              <Col
                xs="12"
                lg={2}
                className={`border-right p-0 m-0 ${
                  this.state.showSidebar ? "d-block" : "hidden-md-down"
                }`}
              >
                <a className="sidebar-brand ml-5 hidden-md-down" href="#">
                  <img
                    width="100px"
                    style={{ maxHeight: "120" }}
                    src={logo}
                    alt="logo"
                  />
                </a>
                <button
                  className="float-left bg-white border-0 hidden-lg-up"
                  onClick={this.toggle.bind(this, "showSidebar")}
                >
                  {" "}
                  <Menu />{" "}
                </button>
                <SidebarLeft
                  className="border mt-5"
                  setOptionTab={this.setOptionTab.bind(this)}
                  click={this.state.click}
                />
              </Col>
              <Col
                xs="12"
                lg={10}
                className={`${
                  this.state.showSidebar ? "d-none" : ""
                } group-content`}
              >
                <div className="main-content">
                  <div className="text-center mt-2 content-title-bold">
                    {header[this.state.optionTab].toUpperCase()}
                    <button
                      className="float-left bg-white border-0 hidden-lg-up"
                      onClick={this.toggle.bind(this, "showSidebar")}
                    >
                      {" "}
                      <Menu />{" "}
                    </button>
                    <button
                      className="text-danger bg-white border-0 float-right mr-1 mt-1"
                      onClick={() => {
                        utils.logOut();
                      }}
                    >
                      {" "}
                      <Power className="mr-2" />
                      {utils.getUserinfo().full_name}{" "}
                    </button>
                  </div>
                  <hr />
                  {this.state.optionTab === "staffmanagement" ? (
                    <Staffmanagement />
                  ) : this.state.optionTab === "storeManagement" ? (
                    <StoreManagement />
                  ) : this.state.optionTab === "winPrize" ? (
                    <WinPrize />
                  ) : this.state.optionTab === "configGeneral" ? (
                    <ConfigGeneral />
                  ) : this.state.optionTab === "posManagerment" ? (
                    <PosManagerment />
                  ) : this.state.optionTab ===
                    "configReasonsNotAllowRecharge" ? (
                    <ConfigReasonsNotAllowRecharge />
                  ) : this.state.optionTab ===
                    "configReasonsNotAllowWithdrals" ? (
                    <ConfigReasonsNotAllowWithdrals />
                  ) : this.state.optionTab ===
                    "configReasonsNotAllowWithdralsCleark" ? (
                    <ConfigReasonsNotAllowWithdralsCleark />
                  ) : this.state.optionTab === "clerkHistory" ? (
                    <ClerkHistory />
                  ) : this.state.optionTab === "accountantHistory" ? (
                    <AccountantHistory />
                  ) : this.state.optionTab === "adminHistory" ? (
                    <AdminHistory />
                  ) : this.state.optionTab === "userStatistical" ? (
                    <UserStatistical />
                  ) : this.state.optionTab === "customerTransactions" ? (
                    <CustomerTransactions />
                  ) : this.state.optionTab === "staffStatistical" ? (
                    <StaffStatistical />
                  ) : this.state.optionTab === "revenueStatistics" ? (
                    <RevenueStatistics />
                  ) : this.state.optionTab === "moneyHoldingStatistics" ? (
                    <MoneyHoldingStatistics />
                  ) : this.state.optionTab === "moneyHoldingStatisticsOnDay" ? (
                    <MoneyHoldingStatisticsOnDay />
                  ) : this.state.optionTab === "rejectTicket" ? (
                    <RejectTicket />
                  ) : this.state.optionTab === "notifiCommonManagement" ? (
                    <Notifi />
                  ) :  this.state.optionTab === "userStatisticalV2" ? (
                    <UserStatisticalV2 />
                  ) :  this.state.optionTab === "configSeo" ? (
                    <ConfigSeo />
                  ) :  this.state.optionTab === "staffMonitor" ? (
                    <StaffMonitor />
                  ) :  this.state.optionTab === "groupBuyManager" ? (
                    <GroupBuy />
                  ) : this.state.optionTab === "changeTermKeno" ? (
                    <ChangeTermKeno />
                  ) :  this.state.optionTab === "kenthietManager" ? (
                    <Kienthiet />
                  ) :  this.state.optionTab === "KTMNManager" ? (
                    <KTMN />
                  ) : this.state.optionTab === "WinThirdParty" ? (
                    <WinPrizeThirdParty />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
  }
}

export default Clerk;
