const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');
function getAllGroup(data,callback){

    axios({
        url: config_api.groupBuyGet+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getDetailGroup(data,callback){

    axios({
        url: config_api.groupBuyDetail+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function updateGroup(data,callback){
    axios({
        url: config_api.groupBuyUpdate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getProduct(id,callback){
    axios({
        url:config_api.product+'/'+id,
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }    

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function createGroup(data,callback){
    axios({
        url:config_api.groupBuyCreate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data    

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
async function createGroupAsync(data){
    try{
   let res= await axios({
        url:config_api.groupBuyCreate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data    

    })
    
        return [false, res.data]
    
   
    }catch(e){
        return [e.message];
    }
}
export {
    getAllGroup,
     updateGroup,
   getProduct,
   createGroup,
    createGroupAsync,
     getDetailGroup
}