import React from "react";
import { Col, Row,  Modal, ModalHeader, ModalBody, ModalFooter, Button,Table, InputGroup, InputGroupAddon, Input, FormGroup, Label,Card, CardTitle, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2} from '../api/apiStatistic'
import {getAllGroup,createGroup} from '../api/apiKienthiet'
import apiAdmin, {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";

import {convertDate,isEmpty,range,getMoneyFormat} from "../../../utils";
const moment = require('moment')

let STATUS=['Nháp','Đang mở','Hoàn thành','Đã hủy','Đã in'];
const productDataset = [
    {
        "value": 1,
        "label": "Mega 6/45",
        "slug": "mega",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 1,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 45,
                "price": 10
            },
            {
                "id": 2,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 45,
                "price": 400
            },
            {
                "id": 3,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 45,
                "price": 70
            },
            {
                "id": 4,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 45,
                "price": 280
            },
            {
                "id": 5,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 45,
                "price": 840
            },
            {
                "id": 6,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 45,
                "price": 2100
            },
            {
                "id": 7,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 45,
                "price": 4620
            },
            {
                "id": 8,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 45,
                "price": 9240
            },
            {
                "id": 9,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 45,
                "price": 17160
            },
            {
                "id": 10,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 45,
                "price": 30030
            },
            {
                "id": 11,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 45,
                "price": 50050
            },
            {
                "id": 12,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 45,
                "price": 185564
            }
        ]
    },
    {
        "value": 2,
        "label": "Power 6/55",
        "slug": "power",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 21,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 55,
                "price": 10
            },
            {
                "id": 22,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 55,
                "price": 500
            },
            {
                "id": 23,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 55,
                "price": 70
            },
            {
                "id": 24,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 55,
                "price": 280
            },
            {
                "id": 25,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 55,
                "price": 840
            },
            {
                "id": 26,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 55,
                "price": 2100
            },
            {
                "id": 27,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 55,
                "price": 4620
            },
            {
                "id": 28,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 55,
                "price": 9240
            },
            {
                "id": 29,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 55,
                "price": 17160
            },
            {
                "id": 30,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 55,
                "price": 30030
            },
            {
                "id": 31,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 55,
                "price": 50050
            },
            {
                "id": 32,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 55,
                "price": 185564
            }
        ]
    }
];
const Product=['','Mega 6/45','Power 6/55']
class ModalTicket extends React.Component {
    
    constructor(props){
        super(props);
        this.state={
            holders:[]
        }
    }

    componentDidMount(){
       
    }
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalTicket} className='w1000'>
                    <ModalHeader toggle={this.props.closeModalTicket} >
                        <div className="font-weight-bold">
                        Chi tiết
                        </div>
                    </ModalHeader>
                    <ModalBody  >
                        {                                                
                        <Card >
                            <CardTitle>Chi tiết</CardTitle>
                            <CardBody className="pt-0">                                                                                                                                                                                                
                              
                                <Row>
                                    <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                                        <tr>
                                            <th>#</th>
                                            <th>Vé</th>
                                            <th>Ngày xổ</th>
                                            <th>Người tạo</th>
                                            <th>Số tiền</th>
                                            <th>Trạng thái</th>
                                            <th>Số vé ban đầu</th>
                                            <th>Số vé còn lại</th>
                                            <th>Vé tối thiểu</th>
                                            <th>tG Cập nhật</th>
                                            <th></th>
                                        </tr>
                                        {
                                            this.props.data.map(d=>{
                                                return (
                                                    <tr>
                                                        <td>{d.kID}</td>
                                                        <td>{d.kTicket}</td>
                                                        <td>{d.kOpenTime}</td>   
                                                        <td>admin</td>                                                       
                                                        <td>{utils.getMoneyFormat( d.kPrize)}</td>
                                                        <td>{d.kStatus}</td>
                                                        <td>{d.kMaxTickets}</td>
                                                        <td>{d.kNumberTicketNotBuy}</td>
                                                        <td>{d.kMinBuyTicket}</td>
                                                        <td>{d.kTimeUpdate}</td>  
                                                        <td></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                                    
                             
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
class KienthietManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),   
            dateCreate: new Date(),                   
            data:[],         
            showModalTicket:false, 
            statusId:null,
            limit:10,
            offset:0,
            isLoading:false,
            modalCreate:false,
            showModalTicket:false,
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "Id "},
            {label: "Tên nhóm vé"}, 
            {label: "Ngày Mua vé"},   
            {label: "Trạng thái"},
            {label: "Vé mua tối thiểu"},
            {label: "Thời gian cập nhật"},
            {label: "Hành động"},                       
        ];
        this.filter={
            size: 50,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    
    getData(){    
        if(this.state.isLoading)   
            return;
        else this.setState({isLoading:true})
        let obj={
            limit:this.state.limit,
            offset:this.state.offset,
            date:moment( this.state.date).format("DD-MM-YYYY")
        }
        getAllGroup(obj,(err,data)=>{
            if(err){
                alert(err);
            }else{

                this.setState({data:data});                
            }
            this.setState({isLoading:false})
        })
    }   
    
    inputOnchange(event) {      
        console.log(event.target.value)  
                this.setState({
                    [event.target.name]: event.target.value,                    
            });                        
    }
    
    componentDidMount(){
        this.getData(null);
    }
    toggle(component1, component2, value) {
       
                
                this.setState({[component1]:!this.state[component1]});
           
    }
    handCreateGroup(){
        
        let data={
            TicketStart:this.state.TicketStart,
            TicketEnd:this.state.TicketEnd,
            OpenTime:moment(this.state.dateCreate).format('DD-MM-YYYY'),
            MinBuyTicket:parseInt(this.state.MinBuyTicket),
            Status:this.state.statusCreate,
            Prize:this.state.Prize/1000,
            MaxTickets:parseInt( this.state.MaxTickets),
            CharacterTicket:this.state.CharacterTicket,
        }
        if(data.TicketEnd<data.TicketStart)
        {
            alert('Lỗi vé TicketEnd<TicketStart')
        }
        if(data.MaxTickets<data.MinBuyTicket)
        {
            alert('Lỗi vé MaxTickets<MinBuyTicket')
        }
        createGroup(data,(err,res)=>{
            if(err) alert(err);
            else {this.setState({modalCreate:false}); this.getData()}
        })
    }
    
    handleChangeStatus(status,id){
      
    }
    handleNext(){
        if(this.state.data.length===0)
        {
            return;
        }
            
        this.setState({offset:this.state.offset+this.state.limit},()=>{
            this.getData()
        });
    }
    handleBack(){
        if(this.state.offset===0)
        {
            return;
        }
            
        this.setState({offset:this.state.offset-this.state.limit},()=>{
            this.getData()
        });
    }
    render() {
        return(
            <React.Fragment>
            {this.state.showModalTicket&&<ModalTicket
                data={this.state.data.find(e=>e.gID==this.state.selectGroup).detail}
                showModalTicket={this.state.showModalTicket}
                closeModalTicket={()=>this.toggle('showModalTicket','')}
            />}
                <Modal isOpen={this.state.modalCreate}>
                        <ModalHeader>Tạo mới nhóm</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label addonType='prepend'>Số vé bắt đầu</Label>
                                <Input name='TicketStart' onChange={this.inputOnchange.bind(this)} />
                            </FormGroup>
                            <FormGroup>
                                <Label addonType='prepend'>Số vé kết thúc</Label>
                                <Input name='TicketEnd' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup>                            
                            <FormGroup className='mb-3'>
                               <div> <Label addonType='prepend'>Ngày xổ</Label></div>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    selected={this.state.dateCreate===null ? new Date(): this.state.dateCreate}
                                    onChange={date => this.setState({dateCreate: date})}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label addonType='prepend'>Giá</Label>
                                <Input name='Prize' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup>  
                            <FormGroup>
                                <Label addonType='prepend'>Trạng thái</Label>
                                <Select
                                    name='productId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={[{value:0,label:'Không hoạt đông'},{value:1,label:'Hoạt động'}]}
                                    onChange={(value)=>{
                                       this.setState({statusCreate:value.value});                                       
                                    }}
                                />
                            </FormGroup> 
                            <FormGroup>
                                <Label addonType='prepend'>Số vé</Label>
                                <Input name='MaxTickets' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup>  
                            <FormGroup>
                                <Label addonType='prepend'>Mua vé tối thiểu</Label>
                                <Input name='MinBuyTicket' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup> 
                            <FormGroup>
                                <Label addonType='prepend'>Ký tự</Label>
                                <Input name='CharacterTicket' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup> 
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "modalCreate", null, false)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handCreateGroup.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>
                <div className='mx-4 p-3 w-120'>
                    
                    <Row className='justify-content-center m-0 mt-3'>
                        
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Trạng thái</Label>
                            <Select
                                    name='productId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={[{value:null,label:'Tất cả'},{value:0,label:'Nháp'},{value:1,label:'Đang mở'},{value:2,label:'Hoàn thành'},{value:3,label:'Đã hủy'},{value:4,label:'Đã in'}]}
                                    onChange={(value)=>{
                                       this.setState({statusIdSearch:value.value});                                       
                                    }}
                                />
                        </FormGroup>
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Ngày mở thưởng</Label>
                            <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.date===null ? new Date(): this.state.date}
                            onChange={date => this.setState({date: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                        </FormGroup>
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={()=>this.getData()} >
                                Tìm kiếm
                            </Button>                            
                        </div>
                    </Row>
                   
                </div>
               
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                <Row style={{flexDirection:'row',justifyContent:'flex-end'}}>
                        <Button onClick={this.toggle.bind(this, "modalCreate", null, false)}>Tạo mới</Button>
                </Row>
              
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.data) ? null :
                                    this.state.data.map((row)=>{
                                        return(
                                            <tr >
                                               <td>{row.gID}</td>
                                               <td>{row.gName}</td>
                                               <td>{row.gOpenTime}</td>
                                               <td>{row.gStatus}</td>
                                               <td>{row.gMinBuyTicket}</td>
                                               <td>{row.gTimeUpdate  }</td>                                    
                                               <td >
                                               <Button color='primary' onClick={()=>{this.setState({selectGroup:row.gID,showModalTicket:true})}} >
                                                    Chi tiết
                                                </Button>  
                    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <Button color='primary' onClick={()=>this.handleBack()}>{'<'}</Button>
                                    <span>...</span>
                                    <Button color='primary'  onClick={()=>this.handleNext()}>{'>'}</Button>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                   
                </div>
            </React.Fragment>
        );
    }
}

export default KienthietManager;