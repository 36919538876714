import React from "react";
import { Row, Label, FormGroup, Button,Table, InputGroup,CustomInput} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2,changePassword} from '../api/apiStatistic'
import {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
const moment = require('moment')
class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        this.props.smallOption ? (
                            <InputGroup className="mb-3 smallOption">
                                <CustomInput type="select" name={"option_"+this.props.name} className="smallOption-statistic d-inline" onChange={this.props.action}>
                                    <option key="0" value={""}>Tất cả</option>
                                    {
                                        this.props.smallOption.map((option,key)=>{
                                            return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                        })
                                    }
                                </CustomInput>
                                <input name={this.props.name||"nullName"} type="number" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                            </InputGroup>
                        ) : (
                            <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                        )
                    ): this.props.type==="option" ?(
                        <select class="custom-select header-select-statistic" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromCreated: new Date(),
            toCreated: new Date(),
            phone:null,
            pageNum:1,
            statisticData : [],
            statisticDataShow:[],
            size:50
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "Id"},
            {label: "Họ tên"},
            {label: "SĐT"},
            {label: "Số tiền dư trong tài khoản"},
            {label: "Tổng tiền nạp vào"},
          
          
            {label: "Ngày tạo"},
          
            {label: "Địa chỉ"},
            {label: "Email"},
            {label: "Giới tính"},
            {label: "Otp sử dụng"},
            {label: "Trạng thái"},
            {label: "Hành động"},
        ];
        this.filter={
            size: 50,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
        this.setState({[event.target.name]: event.target.value})
        this.updateShown(1);
    }

    getCustomerStatistic(page){
        // if(!!page){
        //     switch (page) {
        //         case "+":
        //             this.filter.page++;
        //             break;
        //         case "-":
        //             this.filter.page--;
        //             break;
        //         default:
        //             this.filter.page = page;
        //             break;
        //     }
        //     if(this.filter.page<1) this.filter.page = 1;
        // }
      let query={
          startDate:this.convertDate(this.state.fromCreated),
          toDate:this.convertDate(this.state.toCreated)
      }
      if(this.state.phone){
          query={
              phone:this.state.phone
          }
      }


        customerStatisticV2(
            query,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
           
                this.setState({statisticData: result,statisticDataShow:result.slice(0,this.filter.size), pageNum : Math.ceil(result.length/this.filter.size)})
            }
        });
    }
    convertDate=(date)=>{
        if(date)
        return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
    }
    toggleActiveCustomer(index,id,sdt,currentStatus){
        utils.confirm(`Xác nhận ${currentStatus? "KHÓA" : "KÍCH HOẠT"} tài khoản ${sdt}`, (check) => {
            if (check) {
                adjustCustomer(
                    id,
                    {isActive: currentStatus?false:true}
                    ,(err, result) => {
                        if (err) {
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                        } else {
                            let state = Object.assign({}, this.state);
                            state.statisticData.rows[index].isActive = result.isActive;
                            this.setState(state);
                        }
                });
            }
        });
    }
    updateShown=(page)=>{
        // let num=this.filter.page * this.filter.size;
        // this.filter.page=page;
        // let data=this.state.statisticData.slice(num,this.filter.size+num);
        // this.setState({...this.state,statisticDataShow:data})
        
        let start=(page-1)*this.filter.size;
        let end=this.filter.size;
        let skip=(page-1)*this.filter.size;
        end=end+skip;
        this.setState({statisticDataShow:this.state.statisticData.slice(start,end)})
        this.filter.page=page;

    }
    componentDidMount(){
        this.getCustomerStatistic(null);
    }
    renderPages=()=>{
        let component=[];
       // if(this.filter.page<6){
            utils.range(1,Math.ceil(this.state.statisticData.length/this.filter.size)).map((index)=>{
                component.push(
                    <Button 
                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                        hidden={Math.abs( index -  this.filter.page)>=5}
                        onClick={()=>this.updateShown(index)}
                    >
                        {index}
                    </Button>
                )
            })
       // }
            // if(this.filter.page>=6 ){

            //     utils.range(this.filter.page-5,this.filter.page+5).map((index)=>{
            //         component.push(
            //         <Button 
            //             className={`${this.filter.page === index ? "btn-danger" : null}`}
            //             hidden={index >  this.state.pageNum}
            //             onClick={()=>this.updateShown(index)}
            //         >
            //             {index}
            //         </Button>
            //     )
            // })
            // }
            // else if(this.filter.page<this.state.pageNum-5 && this.filter.page>=this.state.pageNum-10 && this.state.pageNum>10){
            //     utils.range(this.state.pageNum-10,this.state.pageNum).map((index)=>{
            //         component.push(
            //             <Button 
            //                 className={`${this.filter.page === index ? "btn-danger" : null}`}
            //                 hidden={index >  this.state.pageNum}
            //                 onClick={()=>this.updateShown(index)}
            //             >
            //                 {index}
            //             </Button>
            //         )
            //     })
            // }
            
            
        return component;
    }
    changePass(phone){
        changePassword(phone,(err,res)=>{
            if(err){
                console.log(err)
                alert("Không thành công")
            }
            else{
                alert("Cập nhật thành công")
            }
        })
    }
    render() {
        return(
            <React.Fragment>
                
                <div className='mx-4 p-3 w-120 text-center'>
                    <h2 className="d-inline">
                        {`Tổng số user tìm được: ${this.state.statisticData ? this.state.statisticData.length: null}`}
                    </h2>
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Ngày tạo tài khoản từ ngày</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromCreated}
                                onChange={date =>this.setState({fromCreated: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block">Ngày tạo tài khoản đến ngày</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.toCreated}
                                onChange={date => this.setState({toCreated: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={this.getCustomerStatistic.bind(this,null)}>
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button d-inline mx-2 "
                                table="thong-ke-khach-hang"
                                filename={`THỐNG KÊ THÔNG TIN KHÁCH HÀNG TỪ ${this.filter.fromCreated||"TỪ ĐẦU"} ĐẾN ${this.filter.toCreated||moment().format('DD-MM-YYYY')}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center">                      
                        <Filter label="SĐT" type="text" name="phone" action={this.inputOnchange.bind(this)}/>                      
                    </Row>
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticDataShow.map((row)=>{
                                        return(
                                            <tr >
                                                <td>{row.id}</td>
                                                <td>{row.fullName || "-"}</td>
                                                <td>{row.phoneNumber || "-"}</td>
                                                <td>{utils.getMoneyFormat(row.balance) || 0}</td>
                                                <td>{utils.getMoneyFormat(row.totalAdd) || 0}</td>                                                                                           
                                                <td>{row.createDate || "-"}</td>                                              
                                                <td style={{textAlign:'left'}}>{row.address || "-"}</td>
                                                <td>{row.email || "-"}</td>
                                                <td>{row.gender || "-"}</td>    
                                                <td>{row.smsCharge}</td>                                              
                                                <td className="cursor-pointer" 
                                                    // onClick={this.toggleActiveCustomer.bind(this,key,row.id,row.phoneNumber,row.isActive)}
                                                >{row.isActive ? "Kích hoạt" : "Khóa"}</td>
                                                {/* <td> {
                                                    row.isActive ? (<Unlock className='text-success float-right mr-2' onClick={()=>this.toggleActiveCustomer(this, row.id,row.phoneNumber)} />): (<Lock className='text-danger float-right mr-2' onClick={()=>this.toggleActiveCustomer(this, row.id,row.phoneNumber)} />)
                }</td> */}
                                                <td>
                                                <Button style={{margin:5,padding:5}}  onClick={()=>{
                                                    this.changePass(row.phoneNumber)
                                                    }} >
                                                        Reset MK 
                                                    </Button>
                                                </td> 
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mt-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={50} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                utils.isEmpty(this.state.statisticData) ? null
                                :(
                                    <div className="float-right px-2">                                       
                                        {this.renderPages()}
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        
                                      
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;