import React from "react";
import utils from "../../../utils";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import Notification from "../../../components/Notification";
import { Row, Label, FormGroup,Button } from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const apiHistory = require("./../api/apiHistory");
const {listStore} = require("./../api/apiStore");
const actionHistoryId = require("../../../config/config").actionHistoryId;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listStore: {},
            fromdate: null,
            todate: null,
        };
        this.rowPage = [50,100,200,250];
        this.filter={
            size: 100,
            page:1,
            staff: "admin"
        };
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    getApiHistory(page) {
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
        }
        listStore((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                result.map(value => {
                    state.listStore[value.id] = value.storeName;
                    return null;
                });

                apiHistory.getHistory(utils.convertDate(state.fromdate), utils.convertDate(state.todate), this.filter, (err, result) => {
                    if (err) {
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    } else {
                        state.data = [];
                        result.map((value,key) => {
                            state.data.push({
                                stt: (this.filter.page-1)*this.filter.size+key+1,
                                id: value.id,
                                createdDate: value.createdDate,
                                fullName: value.staff ? value.staffStatic.fullName : "không có dữ liệu",
                                username: value.staff ? value.staffStatic.username : "không có dữ liệu",
                                activity: actionHistoryId.find(action => action.id === value.actionId).label,
                                userAffected:
                                    value.affectedObjectStatic.username === undefined 
                                        ? "---" 
                                        : value.affectedObjectStatic.role==="clerk"
                                            ? "Tài khoản: " + value.affectedObjectStatic.username + ",  Vị trí: Nhân viên bán vé"
                                            : "Tài khoản: " + value.affectedObjectStatic.username + ",  Vị trí: Kế toán"
                                ,
                                store: value.affectedObjectStatic.storeName,
                            });
                            return null;
                        });
                        this.setState(state);
                    }
                });
            }
        });
    }
    componentDidMount() {
        this.getApiHistory(1);
    }

    render() {
        const tableColumns = [
            {
                dataField: "stt",
                text: "STT",
                headerStyle: { width: '50px' }
            },
            {
                dataField: "fullName",
                text: "Họ tên nhân viên",
                filter: textFilter({
                    placeholder: "Nhập tên", // custom the input placeholder
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "username",
                text: "Tài khoản nhân viên",
                filter: textFilter({
                    placeholder: "Nhập tên tài khoản",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "activity",
                text: "Hoạt động          ",
                filter: textFilter({
                    placeholder: "Nhập hoạt động",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "userAffected",
                text: "Tài khoản tác động",
                filter: textFilter({
                    placeholder: "Nhập tài khoản khách hàng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "store",
                text: "Tên cửa hàng",
                filter: textFilter({
                    placeholder: "Nhập tên cửa hàng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null || cell === undefined) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "createdDate",
                text: "Thời gian",
                sort: false,
                filter: textFilter({
                    placeholder: "Nhập ngày tháng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
        ];
        return (
            <React.Fragment>
                <div >
                    <Row className='justify-content-center'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Ngày bắt đầu</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromdate===null ? new Date(): this.state.fromdate}
                                onChange={date =>this.setState({fromdate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block">Ngày kết thúc</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.todate===null ? new Date(): this.state.todate}
                                onChange={date => this.setState({todate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2'>
                            <Button color='primary' onClick={this.getApiHistory.bind(this, 1)} >
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button float-right d-inline mx-2"
                                table="table-adminHistory"
                                filename={`LỊCH SỬ ADMIN TỪ ${utils.convertDate(this.state.fromDate)} ĐẾN ${utils.convertDate(this.state.toDate)}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <div className="overflow-x-auto table-overflow-x">
                        <BootstrapTable
                            id = "table-adminHistory"
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.data}
                            columns={tableColumns}
                            filter={filterFactory()}
                        />
                    </div>
                    <Row className="my-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            <div className="float-right px-2">
                                <Button disabled={this.filter.page===1} onClick={this.getApiHistory.bind(this,"-")}>{`<`}</Button>
                                <Button className="btn-danger">{this.filter.page}</Button>
                                <Button hidden={this.state.data.length < this.filter.size} onClick={this.getApiHistory.bind(this,"+")}>{this.filter.page+1}</Button>
                                <Button disabled={this.state.data.length < this.filter.size} onClick={this.getApiHistory.bind(this,"+")}>{`>`}</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
