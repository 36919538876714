import React from "react";

import {
    Container,Col,Row,
    Table,
    Button,
    Spinner
} from "reactstrap";

import { Layers, User, XCircle, Printer, RotateCcw} from "react-feather";

import ModalCamera from "../../components/ModalCamera";

import Notification from "../../components/Notification";
import ButtonTimeout from "../../components/ButtonTimeout";
import utils from "../../utils";
import imgCheckOk from "../../assets/img/photos/check_ok.png";
import imgCancel from "../../assets/img/photos/cancel.png";
import io from 'socket.io-client';

const apiTicket = require("./api/apiTicket");
const apiOder = require("./api/apiListOder");
const productOption = require("../../assets/json/productOption.json");
const product = require("../../assets/json/product.json");


class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            
            imgSrcFront: this.props.data.imageFront?utils.getURLImage(this.props.data.imageFront):null,
            imgSrcBack: this.props.data.imageBack?utils.getURLImage(this.props.data.imageBack):null,
            imgLinkFront: this.props.data.imageFront?utils.getURLImage(this.props.data.imageFront):null,
            imgLinkBack: this.props.data.imageBack?utils.getURLImage(this.props.data.imageBack):null,

            show_camera_front: false,
            show_camera_back: false,
            show_image_front: false,
            show_image_back: false,

            disabledSentTicket: true,
            doneOder: false,
            cancelTicket: this.props.data.statusId===3 || this.props.data.statusId===4 ? true :  false,

            statusTicket: this.props.data.statusId
        }
        this.socket = io.connect("http://localhost:3009");
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    handleShooting(id, imgSrc, imgLink) {
        if (id === "front") {
            this.setState({imgSrcFront: imgSrc, imgLinkFront:imgLink});
        } else {
            this.setState({imgSrcBack: imgSrc, imgLinkBack:imgLink});
        }
        this.props.pushCheckTicketArrayStatus(this.props.index,1);
    }

    handleShootingKenoTicket(id,imgSrc, imgLink) {
        this.setState({
            imgSrcFront: imgSrc, 
            imgLinkFront:imgLink,
            imgSrcBack: imgSrc, 
            imgLinkBack:imgLink
        });
        this.props.pushCheckTicketArrayStatus(this.props.index,1);
    }

    handleRemovePhoto(id) {
        if (id === "front") {
            this.setState({imgSrcFront: null,imgLinkFront: null});
        } else {
            this.setState({imgSrcBack: null,imgLinkBack: null});
        }
        this.props.pushCheckTicketArrayStatus(this.props.index,false)
    }

    handleRejectTicket() {
        utils.confirm("Xác nhận hủy vé này?", (check) => {
            if (check) {
                apiTicket.EditTicket(this.props.data.id,this.props.oderId,3,null,null,(err,result)=>  {
                    if(err){
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    } else {
                        let money = 0;
                        this.props.data.value.map((value)=>{
                            money+= value.price*this.props.data.termArray.length;
                            return value;
                        })
                        this.setState({cancelTicket: true})
                        this.props.handTicketChangeStatus(
                            {
                                phoneNumber: this.props.phoneNumber,
                                lotteryType: product[this.props.data.productId],
                                time: this.props.data.createdDate,
                                price: money 
                            },
                            "canceled"
                        )
                        this.props.pushCheckTicketArrayStatus(this.props.index,2)
                    }
                }); 
                
            }
        });
    }

    handleSendTicket() {
        if(this.state.imgLinkBack && this.state.imgLinkFront){
            apiTicket.EditTicket(this.props.data.id,this.props.oderId,4,this.state.imgLinkFront,this.state.imgLinkBack,(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    let money = 0;
                    if( this.props.data.productId === 6){
                        this.props.data.value.map((value)=>{
                            money+= value.price*this.props.data.termArray[0];
                            return value;
                        })
                    }else{
                        this.props.data.value.map((value)=>{
                            money+= value.price*this.props.data.termArray.length;
                        })
                    }
                    this.setState({doneOder: true,cancelTicket: true})
                    this.props.handTicketChangeStatus(
                        {
                            phoneNumber: this.props.phoneNumber,
                            lotteryType: product[this.props.data.productId],
                            time: this.props.data.createdDate,
                            price: money*this.props.data.termArray.length
                        },
                        "completed"
                    )
                    this.props.pushCheckTicketArrayStatus(this.props.index,true)
                }
            }); 
        }else{
            Notification("error","Lỗi","Chưa đủ tất cả ảnh")
        }
    }

    sendRequestPrintTicket(){
        if(this.props.data.productId>=7)
        {
            alert('Chưa hỗ trợ');
            return;
        }
        setTimeout(() => {
            let now = new Date();
            let hourNow = now.getHours();
            let minuteNow = now.getMinutes();
            let secondNow = now.getSeconds();
            if(minuteNow%10===0 && secondNow<20 && this.props.data.productId===6){
                Notification("error",`In lại vé sau ${20-secondNow} giây`);
            } else if(hourNow===18 && secondNow===0 && secondNow<20){
                Notification("error",`In lại vé sau ${20-secondNow} giây`);
            } else{
                apiTicket.sendPrintTicket(this.props.data.id,this.props.oderId,(err,result)=>  {
                    if(err){
                        Notification("error", "Có lỗi xảy ra ", "Không thể gửi yêu cầu in vé");
                    } else {
                        result.ticket.currentTerm = result.currentTerm;
                        if(result.ticket.productId !== 6 || result.ticket.termArray[0] === result.currentTerm.termValue){
                            this.socket.emit('print_ticket',result.ticket);
                        } else {
                            Notification("error",`Lệch kỳ. Kỳ hiện tại: ${result.currentTerm.termValue}`);
                        }
                    }
                }); 
            }
        }, 1000);
    }

    componentDidMount(){
        if(this.props.data.statusId===3){
            this.props.pushCheckTicketArrayStatus(this.props.index,true);
        }
        else if(this.props.data.imageBack && this.props.data.imageFront){
            this.props.pushCheckTicketArrayStatus(this.props.index,true);
        }
        else if(this.props.data.statusId===4){
            this.props.pushCheckTicketArrayStatus(this.props.index,true);
        }
    }

    render() {
        if(this.props.clearDataOder){
            this.setState({
                data: {},
                imgSrcFront: null,
                imgSrcBack: null,
                imgLinkFront: null,
                imgLinkBack: null,
                disabledSentTicket: true,
                doneOder: false,
                cancelTicket: false,
                statusTicket: null
            })
            this.props.toggle("clearDataOder");
        }
        else{
            let statusDisnable = utils.isEmpty(this.state.imgLinkBack) || utils.isEmpty(this.state.imgLinkFront) 
            if(statusDisnable!== this.state.disabledSentTicket) this.setState({disabledSentTicket:statusDisnable})
        }
        if(!utils.isEmpty(this.props.data))
        return (
            <React.Fragment>
                <Row style={this.state.doneOder || this.props.data.statusId === 4
                            ? {
                                backgroundImage: `url(${imgCheckOk})`,
                                backgroundPosition: 'top',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '150px 150px'
                            }
                            : this.state.cancelTicket || this.props.data.statusId === 3
                                ? {
                                    backgroundImage: `url(${imgCancel})`,
                                    backgroundPosition: 'top',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '150px 150px'
                                }    
                                : null
                }>
                    <Table className="w-100 text-center table-bordered mt-2">
                        <thead>
                        <tr>
                            <th className="w-50">{`#${this.props.data.id} ${product[this.props.data.productId]}`}</th>
                            <th>Loại vé</th>
                            <th>Kỳ</th>
                            <th>Tiền cược</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.value
                            ?this.props.data.value.map((value,key)=>{
                                return(
                                    <tr key={key}>
                                        <td>
                                            <div className="day-so">
                                                {
                                                    (this.props.data.productId === 3)
                                                    ?value.number.map(number=>{
                                                        if(this.props.data.productOptionId === 43 || this.props.data.productOptionId === 44){
                                                            return(
                                                                <div className="day-so">
                                                                    <span className="chu-so">{number[0]}</span>
                                                                    <span className="chu-so">{number[1]}</span>
                                                                    <span className="chu-so">{number[2]}</span>
                                                                </div>
                                                            )
                                                        } else{
                                                            return(
                                                                <div className="day-so">
                                                                    <span className="chu-so">{number[0]}</span>
                                                                    <span className="chu-so">{number[1]}</span>
                                                                    <span className="chu-so">{number[2]}</span>
                                                                    <span className="chu-so">{number[3]}</span>
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    })
                                                    :(this.props.data.productId === 4 || this.props.data.productId === 5)
                                                        ?value.number.map(number=>{
                                                            return(
                                                                <div className="day-so">
                                                                    <span className="chu-so">{number[0]}</span>
                                                                    <span className="chu-so">{number[1]}</span>
                                                                    <span className="chu-so">{number[2]}</span>
                                                                </div>
                                                                
                                                            )
                                                        })
                                                        :(this.props.data.productId === 6 && this.props.data.productOptionId===82)
                                                            ?<div className="day-so">
                                                                <span className="chu">
                                                                    { value.number === "1" || value.number[0] === "1"
                                                                        ? "Lẻ"
                                                                        : value.number === "2" || value.number[0] === "2"
                                                                            ? "Chẵn"
                                                                            : value.number === "3" || value.number[0] === "3"
                                                                                ? "Nhỏ"
                                                                                : value.number === "4" || value.number[0] === "4"
                                                                                    ? "Lớn"
                                                                                    : "Lỗi"
                                                                    }
                                                                </span>
                                                            </div>
                                                            :value.number.map((number,key)=>{
                                                            return(
                                                                <span key={key} className="chu-so">{number}</span>
                                                            )
                                                    })
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            {productOption[this.props.data.productOptionId]}
                                        </td>
                                        <td>
                                            {
                                                (this.props.data.termArray.length > 7)
                                                    ? `[${this.props.data.termArray.length} Kỳ]${this.props.data.termArray[0]} - ${this.props.data.termArray[this.props.data.termArray.length-1]}`
                                                    : this.props.data.termArray.toString()
                                            }
                                        </td>
                                        <td>
                                            {utils.convertMoneyFomat(value.price)}
                                        </td>
                                    </tr>
                                )
                            })
                            : null
                        }
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    {
                        this.props.data.productId===6
                        ?   <Col lg={10} className="border text-center p-2">
                                <ModalCamera
                                    label="Ảnh vé Keno"
                                    id={ this.props.index + "front"}
                                    keyImg="front"
                                    imgSrc = {this.state.imgSrcFront}
                                    folder = "ticket"
                                    oderId = {this.props.oderId}
                                    ticketId = {this.props.data.id}
                                    disabled = {this.state.cancelTicket}
                                    actionWhenReceiveData = {this.handleShootingKenoTicket.bind(this)}
                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                />
                            </Col>
                        :   <React.Fragment>
                                {
                                    utils.detectDevice(["ipad",'mobile'],"or")
                                    ?(
                                        <React.Fragment>
                                            <Col md={6} className="border text-center p-2">
                                                <ModalCamera
                                                    label="Ảnh vé mặt trước"
                                                    id={ this.props.index + "front"}
                                                    keyImg="front"
                                                    imgSrc = {this.state.imgSrcFront}
                                                    folder = "ticket"
                                                    oderId = {this.props.oderId}
                                                    ticketId = {this.props.data.id}
                                                    disabled = {this.state.cancelTicket}
                                                    actionWhenReceiveData = {this.handleShooting.bind(this)}
                                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                                />
                                            </Col>
                                            <Col md={6} className="border text-center p-2">
                                                <ModalCamera
                                                    label="Ảnh vé mặt sau"
                                                    id={this.props.index + "back"}
                                                    keyImg ="back"
                                                    imgSrc = {this.state.imgSrcBack}
                                                    folder = "ticket"
                                                    oderId = {this.props.oderId}
                                                    ticketId = {this.props.data.id}
                                                    disabled = {this.state.cancelTicket}
                                                    actionWhenReceiveData = {this.handleShooting.bind(this)}
                                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                                />
                                            </Col>
                                            <div className="mt-2 w-100 ">
                                                <center>
                                                <Button className="mr-2 p-3 btn btn-muted"
                                                            disabled = {this.state.cancelTicket}
                                                            onClick={this.sendRequestPrintTicket.bind(this)}
                                                    >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;In vé&nbsp;&nbsp;&nbsp;
                                                    <Printer/>
                                                </Button>
                                                <Button className="mr-2 p-3 btn btn-danger" 
                                                        disabled = {this.state.cancelTicket}
                                                        onClick={this.handleRejectTicket.bind(this)}
                                                >
                                                    &nbsp;&nbsp;Hủy vé&nbsp;&nbsp;
                                                    <XCircle/>
                                                </Button>
                                                <Button className="mr-2 p-3 btn btn-warning" 
                                                        onClick={()=>this.props.returnOrder()}
                                                >
                                                    &nbsp;&nbsp;Hoàn vé&nbsp;
                                                    <RotateCcw/>
                                                </Button>
                                                </center>
                                            </div>
                                            
                                        </React.Fragment>
                                    ):(
                                        <React.Fragment>
                                            <Col lg={5} className="border text-center p-2">
                                                <ModalCamera
                                                    label="Ảnh vé mặt trước"
                                                    id={ this.props.index + "front"}
                                                    keyImg="front"
                                                    imgSrc = {this.state.imgSrcFront}
                                                    folder = "ticket"
                                                    oderId = {this.props.oderId}
                                                    ticketId = {this.props.data.id}
                                                    disabled = {this.state.cancelTicket}
                                                    actionWhenReceiveData = {this.handleShooting.bind(this)}
                                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                                />
                                            </Col>
                                            <Col lg={5} className="border text-center p-2">
                                                <ModalCamera
                                                    label="Ảnh vé mặt sau"
                                                    id={this.props.index + "back"}
                                                    keyImg ="back"
                                                    imgSrc = {this.state.imgSrcBack}
                                                    folder = "ticket"
                                                    oderId = {this.props.oderId}
                                                    ticketId = {this.props.data.id}
                                                    disabled = {this.state.cancelTicket}
                                                    actionWhenReceiveData = {this.handleShooting.bind(this)}
                                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                                />
                                            </Col>
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment> 
                    }
                    <Col lg={2} className={utils.detectDevice(["ipad",'mobile'],"or") && this.props.data.productId!==6?"d-none":"null"}>
                        <Row className="d-flex justify-content-center mt-5">
                            <Button className="p-3 btn btn-muted"
                                        disabled = {this.state.cancelTicket}
                                        onClick={this.sendRequestPrintTicket.bind(this)}
                                >
                                &nbsp;&nbsp;&nbsp;&nbsp;In vé&nbsp;&nbsp;&nbsp;
                                <Printer/>
                            </Button>
                        </Row>
                        <Row className="d-flex justify-content-center mt-3">
                            <Button className="p-3 btn btn-danger" 
                                    disabled = {this.state.cancelTicket}
                                    onClick={this.handleRejectTicket.bind(this)}
                            >
                                &nbsp;&nbsp;Hủy vé&nbsp;&nbsp;
                                <XCircle/>
                            </Button>
                        </Row>
                        <Row className="d-flex justify-content-center mt-3">
                            <Button className="p-3 btn btn-warning" 
                                    onClick={()=>this.props.returnOrder()}
                            >
                                &nbsp;&nbsp;Hoàn vé&nbsp;
                                <RotateCcw/>
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clearDataOder:false,
            isLoaded: true,
        }
        this.ticketArrayStatus=[]; // luu trang thai tung ve trong 1 oder dang xu ly neu xong hoac huy het thi xoa
        if(this.props.data){
            this.props.data.ticketArray.forEach(element => {
                this.ticketArrayStatus.push(element.statusId === 3 || element.statusId === 4)
            });
        }
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    pushCheckTicketArrayStatus(index,data){
        if(index!== null && data!== null){
            this.ticketArrayStatus[index]=data;
            if(this.props.data.ticketArray.length===1 && data===2){
                this.doneOrder()
            }
        }
    }
    
    checkDoneOder(){
        var i=0;
        var leng = this.ticketArrayStatus.length;
        for (i = 0; i < leng; i++) {
            if (!this.ticketArrayStatus[i] ){
                break;
            }
        }
        if(i === leng){
            // tat ca ticket da duoc xu ly => reset
            if(utils.isEmpty(this.props.data)){
                return null
            } else{
                this.doneOrder();
            }
        } 
        else if(utils.isEmpty(leng)){
            Notification("error","Không thấy thông tin đơn hàng");
        }
        else{
            Notification("error","Chưa xử lý hết tất cả các vé");
        }
    }

    doneOrder(){
        apiOder.doneOder(this.props.data.id,(err,result)=>{
            if(err){
                if(err.data){
                    try {
                        switch (err.data._error_message) {
                            case "Available > balance":
                                Notification("error", "Lỗi", "Số dư khả dụng của khách hàng lớn hơn số dư trong ví. Hãy thông báo đến quản trị viên");
                                break;
                            case "(Many) Ticket is not processed yet!":
                                Notification("error", "Lỗi", "Chưa xử lý hết tất cả các vé");
                                break;
                            case "Order processed or not found!":
                                Notification("error", "Lỗi", "Order đã được xử lý hoặc không tồn tại");
                                break;
                            case "Customer not found!":
                                Notification("error", "Lỗi", "Khách hàng không tồn tại");
                                break;
                            case "(Many) Ticket not found!":
                                Notification("error", "Lỗi", "Có vé số bị lỗi hoặc không tồn tại");
                                break;
                            case "Amount is not number":
                                Notification("error", "Lỗi", "Giá trị vé phải là kiểu số");
                                break;
                            case "Not update availableBalance":
                                Notification("error", "Lỗi", "Không thể cập nhật tài khoản khách hàng");
                                break;
                            case "not update balance":
                                Notification("error", "Lỗi", "Không thể cập nhật tài khoản khách hàng");
                                break;
                            case "POST out of money":
                                Notification("error", "Lỗi", "POS hết tiền. Vui lòng nạp thêm tiền");
                                break;
                            default:
                                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");
                                console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                                break;
                        }  
                    } catch (error) {}
                }
            } else {
                this.ticketArrayStatus = [];
                let state = Object.assign({}, this.state);
                state.clearDataOder = !state.clearDataOder;
                state.isLoaded = false;
                this.setState(state);
                this.props.handTicketChangeStatus(
                    {
                        phoneNumber: this.props.data.customer.phoneNumber,
                        lotteryType: null,
                        time: this.props.data.createdDate,
                        price: this.props.data.totalPrice
                    },
                    "completed"
                )
                this.props.toggle("clearDataHandling");
            }
        })
    }

    returnOrder(){
        apiOder.returnOrder(this.props.data.id,(err,result)=>{
            if(err){
                try {
                switch (err.data._error_message) {
                    case "This order is not being processed by staff":
                        Notification("error", "Lỗi", "Order này không được nhân viên xử lý");
                        break;
                    case "Many Ticket has been processed. Could finish this order.":
                        Notification("error", "Lỗi", "Một số vé đã được xử lý, không thể hoàn tác");
                        break;
                    case "Return many Ticket has been error. Try again.":
                        Notification("error", "Lỗi", "Lỗi hoàn vé của server. Có thể thử lại sau");
                        break;
                    case "Can not return this order at the moment":
                        Notification("error", "Lỗi", "Lỗi hoàn vé của server. Có thể thử lại sau");
                        break;
                    default:
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                        break;
                    }  
                } catch (error) {}
            } else {
                this.props.toggle("clearDataHandling");
            }
        })
    }


    componentDidMount(){
        if(!utils.isEmpty(this.props.data))
        {
            this.ticketArrayStatus = [];
            this.props.data.ticketArray.forEach(element => {
                this.ticketArrayStatus.push(element.statusId === 3 || element.statusId === 4)
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.data===null && this.props.data!==null){
            this.setState({isLoaded: true})
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    !this.state.isLoaded
                    ?
                        <div className="d-flex justify-content-center">
                            <Spinner color="success" size="lg" className="mr-2" />
                        </div>
                    :
                        <Col className="ticket pb-3">
                            <Row>
                                <Col>
                                    <Row>
                                        <div className="_tittle-blue">
                                            <User/> THÔNG TIN NGƯỜI MUA
                                        </div>
                                    </Row>
                                    <Row className="mt-1">
                                        <Table className="mb-0">
                                            <tbody>
                                            <tr>
                                                <td>ID ORDER</td>
                                                <td className="text-primary">{utils.isEmpty(this.props.data)?null:this.props.data.id}</td>
                                                <td >Số điện thoại</td>
                                                <td className="text-primary">{utils.isEmpty(this.props.data)?null:this.props.data.customer.phoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td >Họ tên</td>
                                                <td className="text-primary">{utils.isEmpty(this.props.data)?null:this.props.data.customer.fullName}</td>
                                                <td >Số CMND/CCCD</td>
                                                <td className="text-primary">{utils.isEmpty(this.props.data)?null:this.props.data.customer.idCardNumber}</td>
                                            </tr>
                                            </tbody>
                                            {
                                                utils.isEmpty(this.props.data)
                                                ? null
                                                : utils.isEmpty(this.props.data.shipment)
                                                    ? null
                                                    : this.props.data.shipment.type !== 2
                                                        ?(
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td>Loại chuyển phát: {this.props.data.shipment.type===0? "Chuyển nhanh":"Chuyển chậm"}</td>
                                                                    <td>Số điện thoại: {utils.isEmpty(this.props.data)?null:this.props.data.shipment.phoneNumber}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Số CMND/CCCD: {utils.isEmpty(this.props.data)?null:this.props.data.shipment.idCardNumber}</td>
                                                                    <td>Địa chỉ: {utils.isEmpty(this.props.data)?null:this.props.data.shipment.address}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                        :null
                                            }
                                        </Table>
                                        <div style={{width:"100%"}}>
                                            
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <Row>
                                        <div className="_tittle-blue">
                                            <Layers/>&nbsp;THÔNG TIN VÉ MUA
                                        </div>
                                    </Row>

                                    {
                                        utils.isEmpty(this.props.data)
                                        ?null
                                        :this.props.data.ticketArray.map((value,key)=>{
                                            return(
                                                <Ticket
                                                    key = {key}
                                                    oderId={this.props.data.id}
                                                    phoneNumber={utils.isEmpty(this.props.data)
                                                                    ?null
                                                                    :this.props.data.shipment.phoneNumber
                                                                        ?this.props.data.shipment.phoneNumber
                                                                        :this.props.data.customer.phoneNumber
                                                                }
                                                    data={value}
                                                    index={key}
                                                    clearDataOder = {this.state.clearDataOder}
                                                    handTicketChangeStatus={this.props.handTicketChangeStatus}
                                                    pushCheckTicketArrayStatus = {this.pushCheckTicketArrayStatus.bind(this)}
                                                    toggle = {this.toggle.bind(this)}
                                                    returnOrder = {this.returnOrder.bind(this)}
                                                />
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                            <Container className="text-center d-flex align-items-stretch justify-content-center">
                                <ButtonTimeout className={utils.isEmpty(this.props.data)?'d-none':'mt-3 mx-1 w-50 btn-success'}
                                    onClick={this.checkDoneOder.bind(this)}
                                >
                                    Hoàn thành đơn hàng
                                </ButtonTimeout>
                            </Container>
                        </Col>
                }
            </React.Fragment>
        )
    }
}

export default Order;