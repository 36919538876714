const product = require('../json/product.json');
const productOption = require('../json/productOption.json');

function getProductById(id) {
    return product[JSON.stringify(id)]
}
function getProductOptionById(id) {
    return productOption[JSON.stringify(id)]
}

module.exports={
    getProductById: getProductById,
    getProductOptionById: getProductOptionById,
}