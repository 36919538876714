import React from "react";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import "./css/clerk.css";
import Block from "./block";

class SidebarRight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                completed: [],
                canceled: []
            }
        }
        
    }

    render(){
        return(
            <Tabs>
                <TabList className="d-flex align-items-stretch m-0 p-0">
                    <Tab style={{maxWidth: "50%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>  Hoàn thành ({this.props.data.completed.length})</Tab>
                    <Tab style={{maxWidth: "50%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>  Đã hủy ({this.props.data.canceled.length})</Tab>
                </TabList>
                <TabPanel>
                    {
                        
                        this.props.data.completed.length === 0
                            ? "Danh sách trống"
                            :
                            this.props.data.completed.map(({phoneNumber,lotteryType, time, price}, index) => {
                                return (
                                    <Block key={`completed-${index}`}
                                            id={`completed-${index}`}
                                            phoneNumber={phoneNumber}
                                            lotteryType={lotteryType}
                                            time={time}
                                            price={price}
                                    />
                                )
                            })
                    }
                </TabPanel>
                <TabPanel>
                    {
                        this.props.data.canceled.length === 0
                            ? "Danh sách trống"
                            :
                            this.props.data.canceled.map(({phoneNumber,lotteryType, time, price}, index) => {
                                return (
                                    <Block key={`canceled-${index}`}
                                            id={`canceled-${index}`}
                                            phoneNumber={phoneNumber}
                                            lotteryType={lotteryType}
                                            time={time}
                                            price={price}
                                    />
                                )
                            })
                    }
                </TabPanel>
            </Tabs>
        )
    }
}

export default SidebarRight;