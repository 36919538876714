const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

const getKTMNRevenue=async(filter)=>{
    try{
        let res= await axios({
            url: config_api.thirdPartyRevenue,
            method: 'get',
            headers: {
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + utils.getAuthToken()
            },
            params: filter,
        });
        return [null,res.data];
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }

}
const getThirdPartyWins= async(query)=>{
    try{
        let res=await axios({
            url: config_api.thirdPartyWin + "?" + utils.convertToQuery(query),
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + utils.getAuthToken(),
            },
          })
        return [null,res.data];
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }
}
export {
    getKTMNRevenue,
    getThirdPartyWins
}