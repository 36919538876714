import React from "react";
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import LoadingSprinner from "../../components/LoadingSprinner";
import logo from "../../assets/img/photos/logo.png";
import utils from "../../utils"
import {
    Button,
    Card,CardBody,CardTitle,
    Form,FormGroup, FormFeedback,
    Label,
    Input,
    Alert
} from "reactstrap";
const api = require("./api/api");
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        utils.cleanLocalStore();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });
        setTimeout(() => {
            api.login(username, password, (err, result) =>{
                if(err){
                    if(err.data){
                        switch (err.data._error_message) {
                            case "Your account must be activated":
                                this.setState({ error : err.data === undefined ? err : "Tài khoản bị khóa", loading: false })
                                break;
                            default:
                                this.setState({ error : err.data === undefined ? err : err.data._error_message, loading: false })
                                break;
                        }
                    } else{
                        this.setState({ error : "Lỗi đường truyền", loading: false })
                    }
                    
                } else {
                    if (result.id !== undefined) { 
                        localStorage.setItem('userInfo', JSON.stringify({
                            token: result.authToken,
                            full_name: result.username,
                            role : result.role,
                            storeId : result.storeId,
                            uuid : result.uuid,
                            username: result.username,
                            id: result.id
                        }));
                        window.location.replace("/"+ result.role);
                    }
                }
            })
        }, 500);
    }

    render() {
        const { username, password, submitted, loading, error } = this.state;
        return (
            <React.Fragment>
                {error &&
                    <Alert  color="danger"  className="p-2" >
                    {
                        (error==="Wrong username or password")
                        ? "Sai tài khoản hoặc mật khẩu"
                        : error
                    }
                    </Alert>
                }
                <Card>
                    <CardTitle className="text-center mt-4">
                        <img width="30%" src={logo} alt="logo"/>
                    </CardTitle>
                    <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label className="text-primary ">Tài khoản</Label>
                                <Input
                                    bsSize="lg"
                                    type="text"
                                    name="username"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    placeholder="Tài khoản"
                                    invalid={submitted && !username ? true : false}
                                />
                                <FormFeedback invalid>
                                    Tài khoản không được để trống
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label className="text-primary ">Mật khẩu</Label>
                                <Input
                                    bsSize="lg"
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    placeholder="Mật khẩu"
                                    invalid={submitted && !password  ? true : false}
                                />
                                <FormFeedback invalid>
                                    Mật khẩu không được để trống
                                </FormFeedback>

                            </FormGroup>
                            <FormGroup>
                            </FormGroup>
                            <div className="text-center mt-4">
                                {loading === false? 
                                    <Button color="primary" size="lg" className="">
                                        Đăng nhập
                                    </Button>
                                    :
                                    <LoadingSprinner/>
                                }
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(SignIn));
