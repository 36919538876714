const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

function apiAttachTicket(orderId,ticketId,face,file,callback) {
    if(file===null){
        return callback({data:{_error_message:"Chưa chụp được ảnh"}});
    }else if(file.size>5242880){
        return callback({data:{_error_message:"Ảnh quá lớn. Chọn lại ảnh"}});
    }
    var bodyFormData = new FormData();
    bodyFormData.append('attached_file', file);
    bodyFormData.append('contentType', "ticket");
    bodyFormData.append(face, true);
    axios({
        url: `${config_api.commerce}/ticket/${ticketId}?orderid=${orderId}`,
        method: 'PATCH',
        headers: {
            "Content-type": "multipart/form-data",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: bodyFormData
    })
    .then(result => {
        return callback(false, result.data);
    })
    .catch(error => {
        if(error.request){
            return callback("Đường truyền bị gián đoạn");
        }
        else if (error.response.status === 413){
            error.response.data._error_message="Ảnh quá lớn. Chọn lại ảnh";
            return callback(error.response);
        }
        else if (error.response.status === 422){
            error.response.data._error_message="Kiểm tra kết nối camera";
            return callback(error.response);
        }
        else if (error.response) {
            return callback(error.response);
        }
        else {
            return callback(error.message);
        }
    });
}

function EditTicket(idTicket,idOder,status,imageFront,imageBack,callback) {
    let data = null;
    
    if(status === 3){
        data={
            "statusId": 3
        }
    }
    else if(status === 4){
        data={
            "imageFront": imageFront,
            "imageBack": imageBack
        }
    }
    axios({
        url: config_api.commerce+"/ticket/"+idTicket+"?orderid="+idOder,
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function sendPrintTicket(idTicket,idOder,callback) {
    axios({
        url: config_api.commerce+"/ticket/"+idTicket+"?orderid="+idOder+"&print=true",
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function getCurrentTerm(productId,callback) {
    axios({
        url: config_api.term+"/"+productId,
        method: 'GET',
        headers: {
            "Content-type": "application/json"
        },
    })
    .then(result => {
        return callback(false, result.data[0])
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports = {
    EditTicket: EditTicket,
    sendPrintTicket: sendPrintTicket,
    apiAttachTicket:apiAttachTicket,
    getCurrentTerm: getCurrentTerm
};