import React from "react";
import utils from "../../../utils";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupAddon, Input, CustomInput, FormGroup, Row, Col, Label } from "reactstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
import Notification from "../../../components/Notification";
const apiAdmin = require("./../api/apiAdmin");
const {listStoreActive} = require("./../api/apiStore");
const apiLocation = require("./../api/apiLocation");
const {listPos} = require("./../api/apiPos");
const {arrayCity} = require( "../../../config/city");
const {getNameDistrictFromCode} = require( "../../../config/listDistrict");
const {isEmpty, confirm} = require('../../../utils/')

class RowStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            showModal: {
                editStaff: false,
            },
            // edit staff
            staffmanagement__Main__editStaff__fullName: null,
            staffmanagement__Main__editStaff__phoneNumber: null,
            staffmanagement__Main__editStaff__idCardNumber: null,
            staffmanagement__Main__editStaff__cityId: null,
            staffmanagement__Main__editStaff__districtId: null,
            staffmanagement__Main__editStaff__address: null,
            staffmanagement__Main__editStaff__storeId: null,
            staffmanagement__Main__editStaff__role: this.props.data.role,
            staffmanagement__Main__editStaff__posId: this.props.data.posId,
            checkdistricts: true,
            forceCreate: false,
        };
    }

    inputOnchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    toggle(component1, component2, value) {
        let state = Object.assign({}, this.state);
        if (component2) {
            if (value) {
                state[component1][component2] = value;
                this.setState(state);
            } else {
                state[component1][component2] = !state[component1][component2];
                this.setState(state);
            }
        } else {
            if (value) {
                state[component1] = value;
                this.setState(state);
            } else {
                state[component1] = !state[component1];
                this.setState(state);
            }
        }
    }

    apiUpdateStaff(id,data,forceCreate){
        Object.assign(data,{forceCreate: forceCreate});
        apiAdmin.editStaff(id, data, (err, result) => {
            if (err) {
                switch (err.data._error_message) {
                    case "Invalid phone number":
                        Notification("error", "Lỗi", "Định dạng số điện thoại bị sai");
                        break;
                    case "POS code not existed":
                        Notification("error", "Lỗi", "POS không tồn tại");
                        break;
                    case "Validation error: Name display must be between 5 and 30 characters in length":
                        Notification("error", "Lỗi", "Họ tên phải từ 5 đến 30 ký tự");
                        break;
                    case "User is already in use":
                        Notification("error", "Lỗi", "Tài khoản đã tồn tại. Nhập tài khoản khác");
                        break;
                    case "Invalid password":
                        Notification("error", "Lỗi", "Pasword quá ngắn hoặc sai định dạng");
                        break;
                    default:
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                        break;
                }
            } else {
                this.props.handleUpdateTable();
                Notification("success", "Chỉnh sửa tài khoản thành công");
                let state = Object.assign({}, this.state);
                state.data = result;
                state.showModal.editStaff = false;
                this.setState(state);
            }
        });
    }

    handClickSuccessModalEditStaff() {
        let forceCreate = false;
        let data = {};
        if (!this.state.checkdistricts) {
            Notification("error", "Chọn lại Quận/huyện");
            return null;
        }
        if (this.state.staffmanagement__Main__editStaff__fullName !== null)  Object.assign( data, { fullName: this.state.staffmanagement__Main__editStaff__fullName });
        if (this.state.staffmanagement__Main__editStaff__phoneNumber !== null)  Object.assign( data, { phoneNumber: this.state.staffmanagement__Main__editStaff__phoneNumber });
        if (this.state.staffmanagement__Main__editStaff__idCardNumber !== null)  Object.assign( data, { idCardNumber: this.state.staffmanagement__Main__editStaff__idCardNumber });
        if (this.state.staffmanagement__Main__editStaff__cityId !== null)  Object.assign( data, { cityId: this.state.staffmanagement__Main__editStaff__cityId });
        if (this.state.staffmanagement__Main__editStaff__districtId !== null)  Object.assign( data, { districtId: this.state.staffmanagement__Main__editStaff__districtId });
        if (this.state.staffmanagement__Main__editStaff__address !== null)  Object.assign( data, { address: this.state.staffmanagement__Main__editStaff__address });
        if (this.state.staffmanagement__Main__editStaff__storeId !== null)  Object.assign( data, { storeId: this.state.staffmanagement__Main__editStaff__storeId });
        if (this.state.staffmanagement__Main__editStaff__role === "clerk"){
            if(isEmpty(this.state.staffmanagement__Main__editStaff__posId)) return Notification("error","Mã POS không được để trống");
            else{
                Object.assign( 
                    data,
                    { 
                        role: this.state.staffmanagement__Main__editStaff__role,
                        posId: this.state.staffmanagement__Main__editStaff__posId,
                        forceCreate: forceCreate,
                    }
                );
            }
        } else{
            Object.assign( 
                data,
                { 
                    role: this.state.staffmanagement__Main__editStaff__role ,
                    posId: null,
                    forceCreate: forceCreate,
                }
            );
        }
        this.apiUpdateStaff(this.state.data.id, data,false);
    }

    listPosActive(){
        if(!this.state.listPos){
            listPos({isActive:true},(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    this.setState({
                        listPos : result.map(pos=>{
                            pos = {label: pos.code, value: pos.id}
                            return pos
                        })
                    });
                }
            });
        }
    }

    getDistrict(districtId) {
        this.setState({ 
            getDistrict: {
                name: getNameDistrictFromCode(districtId),
                code: districtId
            }
        });
    }

    listDistrict(cityId) {
        apiLocation.listDistrict(cityId, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                let state = Object.assign({}, this.state);
                state.listDistrict = Object.keys(result).map(function(val, key) {
                    return { value: result[val].code, label: result[val].name };
                });
                this.setState(state);
            }
        });
    }

    findData(list, field, dataFind, fieldOut) {
        let outData = list.find(r => r[field] == dataFind);
        if (utils.isEmpty(outData)) return null;
        return outData[fieldOut];
    }

    handResetPassword(){
        confirm(`Xác nhận RESET mật khẩu nhân viên ${this.props.data.username}`, ()=>{
            apiAdmin.resetPasswordStaff(this.props.data.id,(err,res)=>{
                if(err){
                    return Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                }
                Notification("success",'Đổi mật khẩu thành công');
                this.toggle("showModal","editStaff")
            })
            
        })
    }

    componentDidMount() {
        if (!utils.isEmpty(this.state.data.districtId)) this.getDistrict(this.state.data.districtId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
            listPos: nextProps.listPos
        });
    }
    render() {
        return (
            <React.Fragment>
                {/* Modal Edit staff*/}
                <Modal isOpen={this.state.showModal.editStaff} zIndex="5">
                    <ModalHeader className='text-center'>Chỉnh sửa Nhân viên</ModalHeader>
                    <ModalBody>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Họ tên</InputGroupAddon>
                            <Input name='staffmanagement__Main__editStaff__fullName' onChange={this.inputOnchange.bind(this)} defaultValue={this.state.data.fullName} />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Số điện thoại</InputGroupAddon>
                            <Input 
                                name='staffmanagement__Main__editStaff__phoneNumber' 
                                onChange={this.inputOnchange.bind(this)} 
                                defaultValue={this.state.data.phoneNumber} 
                                maxLength="10"
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                }}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Số CMND/CCCD</InputGroupAddon>
                            <Input 
                                name='staffmanagement__Main__editStaff__idCardNumber' 
                                onChange={this.inputOnchange.bind(this)} 
                                defaultValue={this.state.data.idCardNumber} 
                                maxLength="12"
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                }}
                            />
                        </InputGroup>

                        <Row>
                            <Col md='3'>
                                <FormGroup>
                                    <Label>Tỉnh/Thành phố</Label>
                                    <Select
                                        defaultValue={
                                            utils.isEmpty(this.state.data.cityId)
                                                ? null
                                                : { value: this.state.data.cityId, label: this.findData(this.props.listCity, "value", this.state.data.cityId, "label") }
                                        }
                                        name='staffmanagement__Main__editStaff__cityId'
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        options={this.props.listCity}
                                        onChange={value => {
                                            this.listDistrict(value.value);
                                            this.setState({ staffmanagement__Main__editStaff__cityId: value.value,checkdistricts: false });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='3'>
                                <FormGroup>
                                    <Label>Quận/huyện</Label>
                                    <Select
                                        defaultValue={utils.isEmpty(this.state.getDistrict) ? null : { value: this.state.getDistrict.code, label: this.state.getDistrict.name }}
                                        name='staffmanagement__Main__editStaff__districtId'
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        options={this.state.listDistrict}
                                        onChange={value => {
                                            this.setState({ staffmanagement__Main__editStaff__districtId: value.value, checkdistricts: true});
                                        }}
                                        onFocus={this.listDistrict.bind(this,this.state.data.cityId)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <Label>Địa chỉ chi tiết</Label>
                                    <Input name='staffmanagement__Main__editStaff__address' defaultValue={this.state.data.address} onChange={this.inputOnchange.bind(this)} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <InputGroup className='mb-3'>
                            <CustomInput
                                type='select'
                                id='exampleCustomSelect'
                                name='staffmanagement__Main__editStaff__storeId'
                                onChange={this.inputOnchange.bind(this)}
                                defaultValue={this.state.data.storeId}>
                                <option value=''>Chọn đại lý</option>
                                {this.props.listStore.map((value, key) => {
                                    return <option key={key} value={value.id}>{value.label}</option>;
                                })}
                            </CustomInput>
                        </InputGroup>
                        <CustomInput
                            type='select'
                            id='exampleCustomSelect'
                            name='staffmanagement__Main__editStaff__role'
                            className='mb-3'
                            defaultValue={this.state.data.role}
                            onChange={this.inputOnchange.bind(this)}>
                            <option value=''>Chọn quyền</option>
                            <option value='accountancy'>Kế toán</option>
                            <option value='clerk'>Nhân viên</option>
                        </CustomInput>
                        <InputGroup className={`${this.state.staffmanagement__Main__editStaff__role === "clerk" ? "mb-3" : "mb-3 d-none"}`}>
                            <Select
                                name='staffmanagement__Main__editStaff__posId'
                                className='w-100'
                                placeholder={this.props.data.po ? this.props.data.po.code: "Chọn mã POS"}
                                classNamePrefix='react-select'
                                options={this.state.listPos}
                                onChange={(value)=>this.setState({staffmanagement__Main__editStaff__posId: value.value})}
                                onFocus={this.listPosActive.bind(this)}
                            />
                        </InputGroup>
                        <div className="w-100 d-flex justify-content-center">
                            <Button className="bg-danger" onClick={this.handResetPassword.bind(this)}> Reset password</Button>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle.bind(this, "showModal", "editStaff", null)}>
                            Hủy bỏ
                        </Button>
                        <Button color='success' onClick={this.handClickSuccessModalEditStaff.bind(this)}>
                            Lưu
                        </Button>
                    </ModalFooter>
                </Modal>

                {
                    this.state.data.isActive ? (<Unlock className='text-success float-right mr-2' onClick={this.props.handActiveStaff.bind(this, false, this.state.data.id)} />): (<Lock className='text-danger float-right mr-2' onClick={this.props.handActiveStaff.bind(this, true, this.state.data.id)} />)
                }
                {
                    this.state.data.isActive ? <Edit className='text-info float-right mr-2' onClick= {
                        this.toggle.bind(this, "showModal", "editStaff")
                    }
                    />: null
                }

            </React.Fragment>
        );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listStaff: [],
            listStore: [],
            listCity: arrayCity,
            listDistrict: [],
            listPos : [],

            // create staff
            staffmanagement__Main__createStaff__username: null,
            staffmanagement__Main__createStaff__fullName: null,
            staffmanagement__Main__createStaff__phoneNumber: null,
            staffmanagement__Main__createStaff__idCardNumber: null,
            staffmanagement__Main__createStaff__city: null,
            staffmanagement__Main__createStaff__districts: null,
            staffmanagement__Main__createStaff__address: null,
            staffmanagementf__Main__createStaff__role: null,
            staffmanagement__Main__createStaff__password: null,
            staffmanagement__Main__createStaff__storeId: null,

            showModal: {
                createStaff: false,
                deleteStaff: false,
            },
            checkdistricts: true,
        };
        this.idStaffDelete = null;
        this.handleUpdateTable = this.handleUpdateTable.bind(this);
        this.handActiveStaff = this.handActiveStaff.bind(this);
    }

    toggle(component1, component2, value) {
        let state = utils.getRawData(this.state);
        if (component2) {
            if (value) {
                state[component1][component2] = value;
                this.setState(state);
            } else {
                state[component1][component2] = !state[component1][component2];
                this.setState(state);
            }
        } else {
            if (value) {
                state[component1] = value;
                this.setState(state);
            } else {
                state[component1] = !state[component1];
                this.setState(state);
            }
        }
    }

    inputOnchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    
    apiCreateStaff(data,forceCreate){
        Object.assign(data,{forceCreate: forceCreate})
        apiAdmin.createStaff(data, (err, result) => {
            if (err) {
                switch (err.data._error_message) {
                    case "Invalid phone number":
                        Notification("error", "Lỗi", "Định dạng số điện thoại bị sai");
                        break;
                    case "POS code not existed":
                        Notification("error", "Lỗi", "POS không tồn tại");
                        break;
                    case "Validation error: Name display must be between 5 and 30 characters in length":
                        Notification("error", "Lỗi", "Họ tên phải từ 5 đến 30 ký tự");
                        break;
                    case "User is already in use":
                        Notification("error", "Lỗi", "Tài khoản đã tồn tại. Nhập tài khoản khác");
                        break;
                    case "Invalid password":
                        Notification("error", "Lỗi", "Pasword quá ngắn hoặc sai định dạng");
                        break;
                    default:
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                        break;
                }
            } else {
                Notification("success", "Tạo nhân viên thành công");
                let state = Object.assign({}, this.state);
                result["store"] = { storeName: this.state.listStore.find(store => store.id == result.storeId).label };
                state.listStaff.push(result);
                state.showModal.createStaff = false;
                state.staffmanagementf__Main__createStaff__posId = null
                this.setState(state);
            }
        });
    }    
    handCreateStaff() {
        //check data
        if (!this.state.checkdistricts) {
            Notification("error", "Chọn lại Quận/huyện");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__username)) {
            Notification("error", "Điền đầy đủ thông tin", "Tài khoản không được để trống");
            return null;
        }
        if (this.state.staffmanagement__Main__createStaff__username.length < 5) {
            Notification("error", "Tên tài khoản quá ngắn", "Tài khoản phải dài hơn 5 ký tự");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__fullName)) {
            Notification("error", "Điền đầy đủ thông tin", "Họ tên không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__fullName.length<5)) {
            Notification("error", "Điền đầy đủ thông tin", "Họ tên phải dài hơn 5 ký tự");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__phoneNumber)) {
            Notification("error", "Điền đầy đủ thông tin", "Số điện thoại liên hệ không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__idCardNumber)) {
            Notification("error", "Điền đầy đủ thông tin", "Số chứng minh thư hoặc căn cước công dân không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__city)) {
            Notification("error", "Điền đầy đủ thông tin", "Tỉnh/Thành phố không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__districts)) {
            Notification("error", "Điền đầy đủ thông tin", "Quận/huyện không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__address)) {
            Notification("error", "Điền đầy đủ thông tin", "địa chỉ chi tiết không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagementf__Main__createStaff__role)) {
            Notification("error", "Điền đầy đủ thông tin", "Chọn quyền cho nhân viên");
            return null;
        }
        if (this.state.staffmanagementf__Main__createStaff__role === "clerk") {
            if( utils.isEmpty(this.state.staffmanagementf__Main__createStaff__role)){
                Notification("error", "Điền đầy đủ thông tin", "Mã POS không được để trống");
                return null;
            }
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__password)) {
            Notification("error", "Điền đầy đủ thông tin", "Mất khẩu không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.staffmanagement__Main__createStaff__storeId)) {
            Notification("error", "Điền đầy đủ thông tin", "Chọn cửa hàng cho nhân viên");
            return null;
        }
        // if (utils.isEmpty(this.state.staffmanagementf__Main__createStaff__posId) && this.state.staffmanagementf__Main__createStaff__role === "clerk") {
        //     Notification("error", "Điền đầy đủ thông tin", "Chọn POS cho nhân viên");
        //     return null;
        // }

        let data = {
            username: this.state.staffmanagement__Main__createStaff__username.toLowerCase(),
            fullName: this.state.staffmanagement__Main__createStaff__fullName,
            phoneNumber: this.state.staffmanagement__Main__createStaff__phoneNumber,
            idCardNumber: this.state.staffmanagement__Main__createStaff__idCardNumber,
            cityId: this.state.staffmanagement__Main__createStaff__city,
            districtId: this.state.staffmanagement__Main__createStaff__districts,
            address: this.state.staffmanagement__Main__createStaff__address,
            role: this.state.staffmanagementf__Main__createStaff__role,
            password: this.state.staffmanagement__Main__createStaff__password,
            storeId: this.state.staffmanagement__Main__createStaff__storeId,
            posId: this.state.staffmanagementf__Main__createStaff__role === "clerk" ?this.state.staffmanagementf__Main__createStaff__posId:null
        };

        this.apiCreateStaff(data,false);
    }

    handleUpdateTable() {
        apiAdmin.litStaff((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                this.setState({ listStaff: result });
            }
        });
    }

    handDeleteStaff() {
        if (this.idStaffDelete) {
            apiAdmin.deleteStaff(this.idStaffDelete, (err, result) => {
                if (err) {
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                } else {
                    Notification("success", "Xóa nhân viên thành công");
                    let state = utils.getRawData(this.state);
                    state.listStaff = state.listStaff.filter(staff => staff.id != this.idStaffDelete);
                    state.showModal.deleteStaff = false;
                    this.idStaffDelete = null;
                    this.setState(state);
                }
            });
        }
    }

    handActiveStaff(status, id) {
        apiAdmin.editStaff(id, { isActive: status }, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                apiAdmin.litStaff((err, result) => {
                    if (err) {
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                    } else {
                        this.setState({ listStaff: result });
                    }
                });
                Notification("success", "Chỉnh sửa tài khoản thành công");
            }
        });
    }

    listDistricts(cityId) {
        apiLocation.listDistrict(cityId, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                let state = Object.assign({}, this.state);
                state.listDistrict = result.map(function(val, key) {
                    return { value: result[key].code, label: result[key].name_with_type};
                });
                this.setState(state);
            }
        });
    }

    listPosActive(){
        if(utils.isEmpty(this.state.listPos)){
            listPos({isActive:true},(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    this.setState({
                        listPos : result.map(pos=>{
                            pos = {label: pos.code, value: pos.id}
                            return pos
                        })
                    });
                }
            });
        }
    }

    openModalDeleteStaff(id) {
        this.idStaffDelete = id;
        this.toggle("showModal", "deleteStaff", null);
    }

    convertListPos(){
        let output = {};
        this.state.listPos.map(p=>{
            output[p.label] = p.label;
        })
        return  output;
    }

    componentDidMount() {
        apiAdmin.litStaff((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                this.setState({ listStaff: result });
            }
        });
        listStoreActive((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                let storeName = [];
                result.map(value => {
                    return storeName.push({ id: value.id, label: value.storeName });
                });
                this.setState({ listStore: storeName });
            }
        });
        this.listPosActive();
    }

    render() {
        const tableColumns = [
            {
                dataField: "id",
                text: "#",
               
                headerStyle:{width:"50px"},
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "fullName",
                text: "Họ và tên",
                filter: textFilter({
                    placeholder: "Nhập tên",
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "username",
                text: "Tài khoản",
                filter: textFilter({
                    placeholder: "Nhập tên tài khoản",
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "role",
                text: "Chức vụ",
                filter: selectFilter({
                    placeholder: "Nhập tên chức vụ",
                    options: {
                        clerk: "Nhân viên bán vé",
                        accountancy: "Kế toán"
                    }
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    switch (cell) {
                        case null:
                            return "---"
                        case "clerk":
                            return "Nhân viên bán vé"
                        case "accountancy":
                            return "Kế toán"
                        default:
                            return cell;
                    }
                },
            },
            {
                dataField: "po.code",
                text: "POS",
                filter: selectFilter({
                    placeholder: "Nhập mã POS",
                    options: this.convertListPos()
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    switch (cell) {
                        case null:
                            return "---"
                        default:
                            return cell;
                    }
                },
            },
            {
                dataField: "phoneNumber",
                text: "Số điện thoại",
                filter: textFilter({
                    placeholder: "Nhập số điện thoại",
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "idCardNumber",
                text: "Số CMTND/CCCD",
                filter: textFilter({
                    placeholder: "Nhập số CMTND/CCCD",
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: `store.storeName`,
                text: "Cửa hàng",
                sort: true,
                filter: textFilter({
                    placeholder: "Nhập cửa hàng",
                }),
                headerStyle:{width:"123px"},
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                headerFormatter: () => {
                    return (
                        <Button className='float-center w-100 btn-suscess text-white' color='success' onClick={this.toggle.bind(this, "showModal", "createStaff", null)}>
                            <UserPlus className='ml-1'></UserPlus>
                        </Button>
                    );
                },
                headerStyle:{
                    verticalAlign: "middle",
                    width:"123px"
                },
                formatter: (cell, row) => {
                    return (
                        <RowStaff
                            listStore={this.state.listStore}
                            listCity={this.state.listCity}
                            listPos={this.state.listPos}
                            openModalDeleteStaff={this.openModalDeleteStaff.bind(this)}
                            data={row}
                            handActiveStaff={this.handActiveStaff}
                            handleUpdateTable={this.handleUpdateTable}
                        />
                    );
                },
            },
        ];
        const rowStyle2 = (row, rowIndex) => {
            const style = {};
            if (this.state.listStaff[rowIndex].isActive === false) {
                style.backgroundColor = "#cccccc";
                style.fontWeight = "bold";
                style.color = "white";
            }

            return style;
        };
        return (
            <React.Fragment>
                <div >
                    {/* Modal Create account*/}
                    <Modal isOpen={this.state.showModal.createStaff}>
                        <ModalHeader>Tạo nhân viên</ModalHeader>
                        <ModalBody>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Tài khoản</InputGroupAddon>
                                <Input name='staffmanagement__Main__createStaff__username' onChange={this.inputOnchange.bind(this)} />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Họ tên</InputGroupAddon>
                                <Input name='staffmanagement__Main__createStaff__fullName' onChange={this.inputOnchange.bind(this)} />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Số điện thoại</InputGroupAddon>
                                <Input 
                                    name='staffmanagement__Main__createStaff__phoneNumber' 
                                    onChange={this.inputOnchange.bind(this)} 
                                    maxLength="10"
                                    onKeyUp={(event) => {
                                        if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Số CMTNN/CCCD</InputGroupAddon>
                                <Input 
                                    name='staffmanagement__Main__createStaff__idCardNumber' 
                                    onChange={this.inputOnchange.bind(this)} 
                                    maxLength="12"
                                    onKeyUp={(event) => {
                                        if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    }}
                                />
                            </InputGroup>

                            <Row>
                                <Col md='3'>
                                    <FormGroup>
                                        <Label>Tỉnh/Thành phố</Label>
                                        <Select
                                            name='staffmanagement__Main__createStaff__city'
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={this.state.listCity}
                                            onChange={value => {
                                                this.listDistricts(value.value);
                                                this.setState({ staffmanagement__Main__createStaff__city: value.value,checkdistricts: false  });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='3'>
                                    <FormGroup>
                                        <Label>Quận/huyện</Label>
                                        <Select
                                            name='staffmanagement__Main__createStaff__districts'
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={this.state.listDistrict}
                                            onChange={value => {
                                                this.setState({ staffmanagement__Main__createStaff__districts: value.value,checkdistricts: true  });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>Địa chỉ chi tiết</Label>
                                        <Input name='staffmanagement__Main__createStaff__address' onChange={this.inputOnchange.bind(this)} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Mật khẩu</InputGroupAddon>
                                <Input type='password' name='staffmanagement__Main__createStaff__password' onChange={this.inputOnchange.bind(this)} />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <CustomInput type='select' id='exampleCustomSelect' name='staffmanagement__Main__createStaff__storeId' onChange={this.inputOnchange.bind(this)}>
                                    <option value=''>Chọn đại lý</option>
                                    {this.state.listStore.map((value, key) => {
                                        return <option key={key} value={value.id}>{value.label}</option>;
                                    })}
                                </CustomInput>
                            </InputGroup>
                            <InputGroup>
                                <CustomInput type='select' id='exampleCustomSelect' name='staffmanagementf__Main__createStaff__role' className='mb-3' onChange={this.inputOnchange.bind(this)}>
                                    <option value=''>Chọn quyền</option>
                                    <option value='accountancy'>Kế toán</option>
                                    <option value='clerk'>Nhân viên</option>
                                </CustomInput>
                            </InputGroup>
                            <InputGroup className={`${this.state.staffmanagementf__Main__createStaff__role === "clerk" ? "mb-3" : "mb-3 d-none"}`}>
                                <Select
                                    name='staffmanagementf__Main__createStaff__posId'
                                    className='w-100'
                                    placeholder="Chọn mã pos"
                                    classNamePrefix='react-select'
                                    options={this.state.listPos}
                                    onChange={(value)=>this.setState({staffmanagementf__Main__createStaff__posId: value.value})}
                                />
                            </InputGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "showModal", "createStaff", null)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handCreateStaff.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>

                    {/* Modal Delete account*/}
                    <Modal isOpen={this.state.showModal.deleteStaff}>
                        <ModalHeader>Xóa nhân viên</ModalHeader>
                        <ModalBody>Xác nhận xóa nhân viên</ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "showModal", "deleteStaff", null)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handDeleteStaff.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <div className="overflow-x-auto">
                        <BootstrapTable
                            id="table-staffmanagement"
                            {...this.props}
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.listStaff}
                            columns={tableColumns}
                            filter={filterFactory()}
                            rowStyle={rowStyle2}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
