const {config_api} = require("../../../config/config");
const utils = require("../../../utils");
const axios = require("axios");

function increaseMoneyToPos(data,callback) {
    axios({
        url: config_api.pos+"/budget",
        method: "POST",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: data,
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}
module.exports={
    increaseMoneyToPos: increaseMoneyToPos,
}