const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

function postInputResult(data,callback) {
    axios({
        url: config_api.commerce+"/input-results",
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(() => {
        return callback(false, true)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports={
    postInputResult: postInputResult
}