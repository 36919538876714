const config_api = require("../../config/config").config_api;
const axios = require('axios');
const utils = require("../../utils");
const moment = require('moment')

function exportedExcel(type,params,callback) {
    axios({
        url: config_api.statistic+"/"+type,
        method: 'GET',
        headers: {
            "Content-type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        responseType: 'blob',
        params: params,
        timeout: 1000*60*5
    })
    .then(result => {
        const type = result.headers['content-type'];
        const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const now = moment().format('DD-MM-YYYY HH-mm')
        link.download = `export${now}.xlsx`;
        link.click();
        return(callback(false,true) );
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response)
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message) 
        }
    });
}

module.exports = {
    exportedExcel: exportedExcel
};