import React from "react";
import { Row, Label, FormGroup, Button,Table,InputGroup, CustomInput} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {staffStatistic} from '../api/apiStatistic'
import {listStore} from '../api/apiStore'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {arrayCity} from '../../../config/city'
import {listPos} from './../api/apiPos'

class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        this.props.smallOption ? (
                            <InputGroup className="mb-3 smallOption">
                                <CustomInput type="select" name={"option_"+this.props.name} className="smallOption-statistic d-inline" onChange={this.props.action}>
                                    <option key="0" value={""}>Tất cả</option>
                                    {
                                        this.props.smallOption.map((option,key)=>{
                                            return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                        })
                                    }
                                </CustomInput>
                                <input name={this.props.name||"nullName"} type="number" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                            </InputGroup>
                        ) : (
                            <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                        )
                    ): this.props.type==="option" ?(
                        <select class="custom-select" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statisticData : [],
            fromdate: new Date,
            todate: new Date,
        };
        this.tableheader1=[
            {label:"STT"},
            {label:"User nhân viên"},
            {label:"Họ tên nhân viên"},
            {label:"SĐT"},
            {label:"Quyền"},
            {label:"Mã POS"},
            {label:"Cửa hàng"},
            {label: "3D"},
            {label: "4D"},
            {label: "Mega 6/45"},
            {label: "Power 6/55"},
            {label: "Keno"},
            {label: "Tổng"},
            {label:"Tổng tiền nạp cho khách"},
            {label:"Tổng tiền trả cho khách"},
            {label:"CMT/CCCD"},
            {label: "Địa chỉ"},
            {label: "Quận/huyện"},
            {label: "Tỉnh/TP"},
            {label:"Trạng thái"},
            {label:"Ngày bắt đầu làm việc"},
        ];
        this.role = [
            {label:"Bán vé",value:"clerk"},
            {label:"Kế toán",value:"accountancy"}
        ];
        this.isActive = [
            {label:"Hoạt động",value:true},
            {label:"Khóa",value:false},
        ];
        this.fieldSmallOption=["3d","4d","mega","power","keno","total","moneyRecharge","moneyWithdraw"];
        this.smallOption=[
            {label:">=" ,value:">="},
            {label:"<=" ,value:"<="},
            {label:"=" ,value:"="}
        ]
        this.listStore=[];
        this.listPos=[];
        this.filter={};
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    getStaffStatistic(){
        if( this.state.fromdate!==null ) 
            Object.assign(this.filter,{fromdate: utils.convertDate(this.state.fromdate)})
        else Object.assign(this.filter,{fromdate: '01-01-2019'});
        if( this.state.todate!==null ) 
            Object.assign(this.filter,{todate:utils.convertDate(this.state.todate)})
        else Object.assign(this.filter,{todate:utils.convertDate(new Date)})

        let query = {...this.filter};
        for (let i = 0; i < this.fieldSmallOption.length; i++) {
            let key = this.fieldSmallOption[i];
            if( !utils.isEmpty(query[key]) ) {
                switch (query[`option_${key}`]) {
                    case ">=":
                        query[`gte_${key}`] = query[key]/1000;
                        break;
                    case "<=":
                        query[`lte_${key}`] = query[key]/1000;
                        break;
                    case "=":
                        query[`gte_${key}`] = query[key]/1000;
                        query[`lte_${key}`] = query[key]/1000;
                        break;
                    default:
                        break;
                }
            }
            delete query[`option_${key}`];
            delete query[key];
        }
        staffStatistic(query,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({statisticData: result, pageNum : Math.ceil(result.count/this.filter.size)})
            }
        });
    }
    getStore(){
        listStore((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                result.map((value) => {
                    this.listStore.push({label:value.storeName, value: value.id})
                });
            }
        });
    }

    getPoscodeById(id) {
        if(utils.isEmpty(id)) return null;
        let pos = this.listPos.find( p => p.value == id);
        if(utils.isEmpty(pos)) return null
        return pos.label
    }

    
    componentDidMount(){
        this.getStore();
        listPos({},(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.listPos = [];
                result.map(pos=>{
                    this.listPos.push({value: pos.id, label: pos.code});
                })
                this.getStaffStatistic();
            }
        });
        
    }
    

    render() {
        return(
            <React.Fragment>
                <div className='mx-4 p-3 w-120  text-center'>
                    <h2 className="d-inline">{`Tổng số nhân viên: ${this.state.statisticData.length}`}</h2>
                    <Row className='justify-content-center m-0 mt-2'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Ngày bắt đầu</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromdate}
                                onChange={date =>this.setState({fromdate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block">Ngày kết thúc</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.todate}
                                onChange={date => this.setState({todate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2'>
                            <Button color='primary' onClick={this.getStaffStatistic.bind(this)}>
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button float-right d-inline mx-2"
                                table="thong-tin-nhan-vien"
                                filename={`THÔNG TIN NHÂN VIÊN TỪ ${this.filter.fromdate} ĐẾN ${this.filter.todate}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Filter label="User nhân viên" type="text" name="username" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Họ tên nhân viên" type="text" name="fullName" action={this.inputOnchange.bind(this)}/>
                        <Filter label="SĐT" type="text" name="phoneNumber" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Quyền" type="option" name="role[]" optionSelect={this.role} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Mã POS" type="option" name="posId" optionSelect={this.listPos} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Cửa hàng" type="option" name="storeId" optionSelect={this.listStore} action={this.inputOnchange.bind(this)}/>
                        <Filter label="3D" type="text" name="3d" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="4D" type="text" name="4d" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Mega 6/45" type="text" name="mega" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Power 6/55" type="text" name="power" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Keno" type="text" name="keno" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Tổng" type="text" name="total" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Tổng tiền nạp cho khách" type="text" name="moneyRecharge" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Tổng tiền trả cho khách" type="text" name="moneyWithdraw" smallOption={this.smallOption} action={this.inputOnchange.bind(this)}/>
                        <Filter label="CMT/CCCD" type="text" name="idCardNumber" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Tỉnh/TP" type="option" name="cityId" optionSelect={arrayCity} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Trạng thái" type="option" name="isActive" optionSelect={this.isActive} action={this.inputOnchange.bind(this)}/>
                    </Row>
                </div>
                <div className=' mx-4 p-3 mt-2 w-120  text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-tin-nhan-vien">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticData.map((row,key,)=>{
                                        return(
                                            <tr key={key}>
                                                <td>{key+1}</td>
                                                <td>{row.username || "-"}</td>
                                                <td>{row.fullName || "-"}</td>
                                                <td>{row.phoneNumber || "-"}</td>
                                                <td>{row.role || "-"}</td>
                                                <td>{this.getPoscodeById(row.posId) || "-"}</td>
                                                <td>{row.store? row.store.storeName  || "-" : "-"}</td>
                                                <td>{utils.getMoneyFormat(row["3d"]) || 0}</td>
                                                <td>{utils.getMoneyFormat(row["4d"]) || 0}</td>
                                                <td>{utils.getMoneyFormat(row["mega"]) || 0}</td>
                                                <td>{utils.getMoneyFormat(row["power"]) || 0}</td>
                                                <td>{utils.getMoneyFormat(row["keno"]) || 0}</td>
                                                <td>{utils.getMoneyFormat(row["total"]) || 0}</td>
                                                <td>{utils.getMoneyFormat(row.moneyRecharge) || 0}</td>
                                                <td>{utils.getMoneyFormat(row.moneyWithdraw) || 0}</td>
                                                <td>{row.idCardNumber || "-"}</td>
                                                <td>{row.address || "-"}</td>
                                                <td>{row.districtId || "-"}</td>
                                                <td>{row.cityId || "-"}</td>
                                                <td>{row.isActive || "-"}</td>
                                                <td>{row.createdDate || "-"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
