import React from "react";

import {
    Row,
    Table,
    Modal,ModalHeader,ModalBody,
    Button,
    FormGroup,
    Label,
} from "reactstrap";

import { MinusCircle, PlusCircle} from "react-feather";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import Notification from "../../components/Notification";
import utils from "../../utils";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const productOption = require("../../assets/json/productOption.json");
const product = require("../../assets/json/product.json");
const apiHistory = require("./api/apiHistory");
const apiAdmin = require("./api/apiAdmin");
const actionHistoryId = require("../../config/config").actionHistoryId;
const get_urlImage = require("../../config/config").get_urlImage;

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listStore: [],
            fromdate: null,
            todate: null,
            openModalExpan: false
        };
        this.rowPage = [50,100,200,250];
        this.filter={
            size: 100,
            page:1,
            staffid: utils.getUserinfo().id
        };
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }
    
    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    getApiHistory(page) {
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
        }
        apiAdmin.listStore((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                result.map(value => {
                    state.listStore.push({ id: value.id, label: value.storeName });
                });
                apiHistory.getHistory(utils.convertDate(state.fromdate), utils.convertDate(state.todate), this.filter, (err, result) => {
                    if (err) {
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    } else {
                        state.data = [];
                        result.map((value,key) => {
                            var userAffected = null;
                            var amount = null;
                            var ticketArr = null;
                            var proof = [];
                            switch (value.actionId) {
                                case 21:
                                    userAffected = value.affectedObjectStatic.phoneNumber;
                                    amount = utils.convertMoneyFomat(value.transactionStatic.value);
                                    proof.push(get_urlImage(value.transactionStatic.image));
                                    break;
                                case 22:
                                    userAffected = value.affectedObjectStatic.phoneNumber;
                                    amount = utils.convertMoneyFomat(value.transactionStatic.value);
                                    proof.push(get_urlImage(value.transactionStatic.image));
                                    break;
                                case 25:
                                    userAffected = value.affectedObjectStatic.customer?value.affectedObjectStatic.customer.phoneNumber:"---";
                                    amount = utils.convertMoneyFomat(value.affectedObjectStatic.totalPrice);
                                    proof = null;
                                    break;
                                case 51:
                                    userAffected = value.affectedObjectStatic?value.affectedObjectStatic.phoneNumber:"---";
                                    amount = utils.convertMoneyFomat(value.transactionStatic.value);
                                    proof.push(get_urlImage(value.transactionStatic.image));
                                    break;
                                case 52:
                                    userAffected = value.affectedObjectStatic?value.affectedObjectStatic.phoneNumber:"---";
                                    amount = utils.convertMoneyFomat(value.transactionStatic.value);
                                    proof = null;
                                    break;
                                case 26:
                                    userAffected = value.affectedObjectStatic.customer?value.affectedObjectStatic.customer.phoneNumber:"---";
                                    amount = utils.convertMoneyFomat(value.affectedObjectStatic.totalPrice);
                                    proof = null;
                                    try {
                                        ticketArr = value.ticketArray;
                                    } catch (error) {}
                                    break;
                                case 60:
                                    userAffected = "---";
                                    amount = utils.convertMoneyFomat(value.affectedObjectStatic.value);
                                    proof.push(get_urlImage(value.affectedObjectStatic.imageFront));
                                    break;
                                case 61:
                                    userAffected = "---";
                                    amount = utils.convertMoneyFomat(value.affectedObjectStatic.value);
                                    proof.push(get_urlImage(value.affectedObjectStatic.imageFront));
                                    break;
                                case 63:
                                    userAffected = "---";
                                    amount = utils.convertMoneyFomat(value.transactionStatic.value);
                                    proof=null;
                                    break;
                                default:
                                    userAffected = "---";
                                    amount = "---";
                                    proof = null;
                                    break;
                            }
                            state.data.push({
                                stt: (this.filter.page-1)*this.filter.size+key+1,
                                id: value.id,
                                createdDate: value.createdDate,
                                fullName: value.staffStatic.fullName,
                                username: value.staffStatic.username,
                                store: state.listStore.find(store => store.id == value.staff.storeId).label,
                                activityId: value.actionId,
                                activity: actionHistoryId.find(action => action.id === value.actionId).label,
                                userAffected: userAffected,
                                amount: amount,
                                proof: proof,
                                ticketArray: ticketArr,
                                affectedObjectStatic: value.affectedObjectStatic,
                                transaction_history: value.transaction_history
                            });
                        });
                        state.data = state.data.reverse();
                        this.setState(state);
                    }
                });
            }
        });
    }

    componentDidMount() {
        this.getApiHistory(1);
    }

    render() {
        const expandRow = {
            renderer: (row) => (
                <div className='pl-5'>
                    <Modal className="modal-big" isOpen={this.state.open}>
                        <ModalHeader toggle={()=>this.setState({open:false})}/>
                        <ModalBody>
                            <Row>
                                {
                                    utils.isEmpty(row.affectedObjectStatic)
                                    ? null
                                    : utils.isEmpty(row.affectedObjectStatic.shipment)
                                        ?null
                                        : row.affectedObjectStatic.shipment.type!==2
                                            ? <Table className="mb-3">
                                                <thead>
                                                    <tr>
                                                        <th>Loại chuyển phát</th>
                                                        <th>Số điện thoại</th>
                                                        <th>Số CMND/CCCD</th>
                                                        <th>Địa chỉ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{row.affectedObjectStatic.shipment.type===0 ? "Chuyển nhanh" : "Chuyển chậm"}</td>
                                                        <td>{row.affectedObjectStatic.shipment.phoneNumber}</td>
                                                        <td>{row.affectedObjectStatic.shipment.idCardNumber}</td>
                                                        <td>{row.affectedObjectStatic.shipment.address}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            : <div><h2 className="mb-3 pl-1">Giữ vé tại cửa hàng</h2></div>
                                }
                                <div>
                                <img style={{maxWidth: "50%",maxHeight: "550px"}} className="px-1" src={utils.getURLImage(this.state.back)} alt="img_history"/>
                                <img style={{maxWidth: "50%",maxHeight: "550px"}} className="px-1" src={utils.getURLImage(this.state.front)} alt="img_history"/>
                                </div>
                            </Row>
                        </ModalBody>
                    </Modal>

                    <Table>
                        {
                        [21,22,51,60,61].includes(row.activityId) ? (
                            <img className='ticket-photo' src={row.proof[0]} alt="ticket"/>
                        ) : row.activityId === 26 ? (
                            <React.Fragment>
                                <thead>
                                    <tr>
                                        <th width="16%">Loại vé</th>
                                        <th width="16%">Kiểu chơi</th>
                                        <th>Kỳ</th>
                                        <th width="20%">Trạng thái vé</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {row.ticketArray.map((ticket, key) => {
                                        return(
                                            <tr key={key} onClick={()=>{if(ticket.statusId!==3)this.setState({open:true, back: ticket.imageBack, front: ticket.imageFront})}}>
                                                <td>
                                                    {product[ticket.productId]}
                                                </td>
                                                <td>
                                                    {productOption[ticket.productOptionId]}    
                                                </td>
                                                <td>
                                                    {
                                                        ticket.termArray.length > 7
                                                        ? (`${ticket.termArray.length} kỳ. từ kỳ: ${ticket.termArray[0]} - đến kỳ: ${ticket.termArray[ticket.termArray.length-1]}`)
                                                        :ticket.termArray.toString()
                                                    }
                                                </td>
                                                <td>
                                                    {ticket.statusId===3?"Hủy vé":"Đã xử lý"}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </React.Fragment>
                        ) : row.activityId === 52 ? (
                            <React.Fragment>
                                <h2>{`Lý do: ${row.transaction_history.reason}`}</h2>
                                </React.Fragment>
                        ) : (
                            <h2>Trống</h2>
                        )}
                    </Table>
                </div>
            ),
            expandHeaderColumnRenderer: ({ isAnyExpands }) => (isAnyExpands ? <MinusCircle width={16} height={16} /> : <PlusCircle width={16} height={16} />),
            expandColumnRenderer: ({ expanded }) => (expanded ? <MinusCircle width={16} height={16} /> : <PlusCircle width={16} height={16} />),
        };
        const tableColumns = [
            {
                dataField: "stt",
                text: "STT",
                headerStyle: { width: '50px' },
                classes: '_word-break-all'
            },
            {
                dataField: "store",
                text: "Cửa hàng",
                classes: '_word-break-all'
            },
            {
                dataField: "activity",
                text: "Hoạt động",
                classes: '_word-break-all'
            },
            {
                dataField: "userAffected",
                text: "SĐT khách hàng",
                classes: '_word-break-all'
            },
            {
                dataField: "amount",
                text: "Số tiền",
                sort: true,
                classes: '_word-break-all'
            },
            {
                dataField: "createdDate",
                text: "Thời gian",
                sort: false,
                classes: '_word-break-all'
            },
        ];
        return (
            <React.Fragment>
                <div className='px-4'>
                    <Row className='justify-content-center'>
                        <FormGroup className='mb-3 mx-1'>
                            <Label className="d-block">Ngày bắt đầu</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromdate===null ? new Date(): this.state.fromdate}
                                onChange={date =>this.setState({fromdate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 mx-1'>
                            <Label className="d-block">Ngày kết thúc</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.todate===null ? new Date(): this.state.todate}
                                onChange={date => this.setState({todate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2'>
                            <Button color='primary' onClick={this.getApiHistory.bind(this,1)}>
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button float-right d-inline mx-2"
                                table="table-personal-clerk"
                                filename={`LỊCH SỬ NHÂN VIÊN TỪ ${utils.convertDate(this.state.fromDate)} ĐẾN ${utils.convertDate(this.state.toDate)} USER ${utils.getUserinfo().full_name}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <div className="overflow-x-auto table-overflow-x">
                        <BootstrapTable
                            id="table-personal-clerk"
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.data}
                            columns={tableColumns}
                            expandRow={expandRow}
                            filter={filterFactory()}
                        />    
                    </div>
                    
                    <Row className="my-2">
                        <select className="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            <div className="float-right px-2">
                                <Button disabled={this.filter.page===1} onClick={this.getApiHistory.bind(this,"-")}>{`<`}</Button>
                                <Button className="btn-danger">{this.filter.page}</Button>
                                <Button hidden={this.state.data.length < this.filter.size} onClick={this.getApiHistory.bind(this,"+")}>{this.filter.page+1}</Button>
                                <Button disabled={this.state.data.length < this.filter.size} onClick={this.getApiHistory.bind(this,"+")}>{`>`}</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default History;