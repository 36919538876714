import React from "react";
import { Row, Button,Table } from "reactstrap";
import DatePicker from "react-datepicker";
import Notification from '../../../components/Notification';
import LoadingSprinner from '../../../components/LoadingSprinner';
import {moneyHoldingOnday} from '../api/apiStatistic';
import {convertDate,isEmpty,getMoneyFormat} from "../../../utils"
import {exportedExcel} from '../../../components/api/apiExportServer'

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statisticData: [],
            total:{},
            isLoading:false,
            dateTrans: this.props.dateTrans ? new Date(this.props.dateTrans[2], this.props.dateTrans[1]-1, this.props.dateTrans[0]) : new Date()
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label:"STT"},
            {label:"SĐT"},
            {label:"Họ tên"},
            {label:"Tiền giữ hộ đầu"},
            {label:"Mua vé"},
            {label:"Rút tiền tại quầy"},
            {label:"Rút tiền qua ngân hàng"},
            {label:"Tổng tiền rút"},
            {label:"Nạp tiền tại quầy"},
            {label:"Nạp tiền qua ngân hàng"},
            {label:"Nạp qua Vnpay"},
             {label:"Nạp qua MOMO"},
             {label:"Nạp qua MegaPay"},
             {label:"Mua vé qua MegaPay"},
             {label:"Nạp qua Gpay(atm)"},
             {label:"Nạp qua Gpay(Visa/Master)"},
             {label:"Thanh toán qua Gpay(Visa/Master)"},
             {label:"Nạp qua VA"},
            {label:"Tổng tiền nạp"},
            {label:"Khuyến mại"},
            {label:"Trúng thưởng"},
            {label:"Tiền giữ hộ cuối"}
        ];
        this.filter={
            size: 100,
            page:1
        };
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    getMoneyHoldingOnDayStatistic(page){
        if(this.state.isLoading)
            return;
        else this.setState({isLoading:true});
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        Object.assign(this.filter,{dateTrans: convertDate(this.state.dateTrans)})
        moneyHoldingOnday(
            this.filter,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({statisticData: result.rows,total:result.total, pageNum : Math.ceil(result.count/this.filter.size)})
            }
            this.setState({isLoading:false});
        });
    }

    handExport(){
        this.setState({loading:true});
        let filter = {...this.filter};
        delete filter.page;
        delete filter.size;
        Object.assign(filter,{dateTrans: convertDate(this.state.dateTrans)})
        exportedExcel('exportStatisticTransByDay',filter,(err,res)=>{
            if(err){
                Notification("error","Vui lòng giới hạn lại thời gian");
            }
            this.setState({loading:false});
        })

    }

    componentDidMount(){
        this.getMoneyHoldingOnDayStatistic(1)
    }

    render() {
        return(
            <React.Fragment>
                <center>
                    <span className='content-title-bold mb-2'>{`THỐNG KÊ NGÀY ${this.state.dateTrans ? convertDate(this.state.dateTrans):'--/--/----'}`}</span>
                </center>
                <Row className='justify-content-center mb-2 mx-0'>
                    <DatePicker
                        isClearable
                        showPopperArrow={false}
                        selected={this.state.dateTrans===null ? new Date(): this.state.dateTrans}
                        onChange={date => this.setState({dateTrans: date})}
                        dateFormat="dd-MM-yyyy"
                    />
                    <div className='d-inline ml-2 d-flex'>
                        <Button className=" mb-3" color='primary' onClick={this.getMoneyHoldingOnDayStatistic.bind(this,1)}>
                            Tìm kiếm
                        </Button>
                        {
                            !this.state.loading
                            ? <Button  className="mx-2 mb-3" color='primary' onClick={this.handExport.bind(this)}>
                                    Xuất file
                                </Button>
                            : <LoadingSprinner/>
                        }
                        
                    </div>
                </Row>
                <div className=' mx-4 p-3 mt-2 w-120  text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                                {
                                    this.state.total ?(
                                        <tr key={0}>
                                                <th></th>
                                                <th>Tổng</th>
                                                <th></th>
                                                <th>{0}</th>
                                                <th>{0 }</th>
                                                <th>{0 }</th>
                                                <th>{0 }</th>
                                                <th>{0}</th>
                                                <th>{getMoneyFormat(this.state.total["ADD_DEPOSIT_AT_STORE"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["ADD_BANKING_TRANSFER"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["ADD_VNPAY"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["ADD_MOMO"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["ADD_MegaPay"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["Buy_MegaPay"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["Gpay_atm"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["Gpay_international"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["Gpay_International_Order"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["Gpay_VA"]) }</th>
                                                <th>
                                                    {getMoneyFormat(   
                                                        parseInt(this.state.total["ADD_DEPOSIT_AT_STORE"]) + 
                                                        parseInt(this.state.total["ADD_BANKING_TRANSFER"]) + 
                                                        parseInt(this.state.total["ADD_VNPAY"]) +
                                                        parseInt(this.state.total["ADD_MegaPay"]) +
                                                        parseInt(this.state.total["Gpay_VA"]) +
                                                        parseInt(this.state.total["Gpay_international"]) +
                                                        parseInt(this.state.total["Gpay_International_Order"]) +
                                                        parseInt(this.state.total["Buy_MegaPay"]) 
                                                    )}
                                                </th>
                                                <th>{getMoneyFormat(0)}</th>
                                                <th>{getMoneyFormat(this.state.total["ADD_WON_LOTTERY"]) }</th>
                                                <th>{getMoneyFormat(this.state.total["holdingMoneyAfter"]) }</th>
                                        </tr>
                                    ): null
                                }
                            </thead>
                            <tbody>
                            {
                                isEmpty(this.state.statisticData) ? null :
                                (
                                    this.state.statisticData.map((row,key,)=>{
                                        return(
                                            <tr key={key+1}>
                                                <td>{(this.filter.page-1)*this.filter.size+key+1}</td>
                                                <td>{row["wallet.customer.phoneNumber"] }</td>
                                                <td>{row["wallet.customer.fullName"] }</td>
                                                <td>{getMoneyFormat(row["holdingMoneyBefore"]) }</td>
                                                <td>{getMoneyFormat(row["SUB_PAYMENT"]) }</td>
                                                <td>{getMoneyFormat(row["SUB_WITHDRAWAL_AT_STORE"]) }</td>
                                                <td>{getMoneyFormat(row["SUB_WITHDRAWAL_BANKING"]) }</td>
                                                <td>{getMoneyFormat(parseInt(row["SUB_WITHDRAWAL_AT_STORE"]) + parseInt(row["SUB_WITHDRAWAL_BANKING"]))}</td>
                                                <td>{getMoneyFormat(row["ADD_DEPOSIT_AT_STORE"]) }</td>
                                                <td>{getMoneyFormat(row["ADD_BANKING_TRANSFER"]) }</td>
                                                <td>{getMoneyFormat(row["ADD_VNPAY"]) }</td>
                                                <td>{getMoneyFormat(row["ADD_MOMO"]) }</td>
                                                <td>{getMoneyFormat(row["ADD_MegaPay"]) }</td>
                                                <td>{getMoneyFormat(row["Buy_MegaPay"]) }</td>
                                                <td>{getMoneyFormat(row["Gpay_atm"]) }</td>
                                                <td>{getMoneyFormat(row["Gpay_international"]) }</td>
                                                <th>{getMoneyFormat(row["Gpay_International_Order"]) }</th>
                                                <th>{getMoneyFormat(row["Gpay_VA"]) }</th>
                                                <td>
                                                    {getMoneyFormat(
                                                        parseInt(row["ADD_DEPOSIT_AT_STORE"]) + 
                                                        parseInt(row["ADD_BANKING_TRANSFER"]) + 
                                                        parseInt(row["ADD_MOMO"]) + 
                                                        parseInt(row["ADD_MegaPay"]) + 
                                                        parseInt(row["Gpay_atm"]) + 
                                                        parseInt(row["Gpay_international"]) + 
                                                        parseInt(row["ADD_VNPAY"]) +
                                                        parseInt(row["Gpay_VA"]) 
                                                    )}
                                                </td>
                                                <td>{getMoneyFormat(0)}</td>
                                                <td>{getMoneyFormat(row["ADD_WON_LOTTERY"]) }</td>
                                                <td>{getMoneyFormat(row["holdingMoneyAfter"]) }</td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mt-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            <div className="float-right px-2">
                                <Button disabled={this.filter.page===1} onClick={this.getMoneyHoldingOnDayStatistic.bind(this,"-")}>{`<`}</Button>
                                <Button hidden={this.filter.page===1} onClick={this.getMoneyHoldingOnDayStatistic.bind(this,"-")}>{this.filter.page-1}</Button>
                                <Button className="btn-danger">{this.filter.page}</Button>
                                <Button hidden={this.state.statisticData.length < this.filter.size} onClick={this.getMoneyHoldingOnDayStatistic.bind(this,"+")}>{this.filter.page+1}</Button>
                                <Button disabled={this.state.statisticData.length < this.filter.size} onClick={this.getMoneyHoldingOnDayStatistic.bind(this,"+")}>{`>`}</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
