import React from "react";

import {
    Col,Row,
    Modal,ModalHeader,ModalBody,ModalFooter,
    Button,
    InputGroup,InputGroupAddon,Input,CustomInput,
    FormGroup,
    Label,
} from "reactstrap";

import { Award} from "react-feather";
import ModalCamera from "../../components/ModalCamera";
import Notification from "../../components/Notification";
import utils from "../../utils";

const apiAward = require("./api/apiAward");
const apiConfigReason = require("./api/apiReason");
const getCustommerFromPhone = require("../apiGeneral/getCustommerFromPhone");

class Redeem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            money: 0,
            phone_number: "",

            dataCustommer:null,

            imgSrc: null,
            imgLink: null,
            type: "2",
            intendedFor: "2",
            show_reason:false,
        }
    }

    handleChange(event) {
        if (event.target.name === "money") {
            let amount = event.target.value.replace(/[^\d]/g, "");
            if (utils.isEmpty(amount))
                amount = 0;
            try {
                amount = parseInt(amount);
                if (Number.isNaN(amount))
                    return;
                this.setState({money: amount});
                return;
            } catch (e) {
                return;
            }
        }
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSearch() {
        if(!utils.isEmpty(this.state.phone_number)){
            getCustommerFromPhone.getData(this.state.phone_number,(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại định dạng các trường đầu vào");
                } else {
                    if(utils.isEmpty(result)){
                        Notification("warning","Không tồn tại số điện thoại trong hệ thống");
                    }
                    let state = Object.assign({}, this.state);
                    state.dataCustommer = result;
                    this.setState(state);
                }
            });
        }
    }

    handleReCharge() {
        utils.confirm(`Xác nhận rút <span class="money-title">${utils.convertMoneyFomat(this.props.data.amount)} đồng</span> từ tài khoản <span class="content-title-bold">${this.props.data.customer.phoneNumber}</span>`, (check) => {
            if(check){
                let data = {
                    status:"1",
                    proof: this.state.imgLink
                }
                apiAward.editRequestRedeem(this.props.data.id,data,(err)=>  {
                    if(err){
                        Notification("error", "Lỗi", "Không thể xác nhận rút tiền. Ghi lại thông tin yêu cầu và báo cáo với quản trị viên");
                    }
                    else{
                        Notification("success" ,"Xác nhận yêu cầu thành công");
                        this.setState({imgSrc: null,imgLink: null});
                        this.props.clearDataRedeem("completed");
                    }
                });
            }
        })
    }

    inputOnChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    handleShooting(id,imgSrc,imgLink) {
        this.setState({imgSrc:imgSrc,imgLink: imgLink});
    }

    handleRemovePhoto(id) {
        this.setState({imgSrc: null,imgLink: null});
    }

    checkEmptyField(){
        if(utils.isEmpty(this.props.data)){
            Notification("error","Chưa đủ thông tin","Không tìm được thông tin khách hàng");
        }
        else if(utils.isEmpty(this.props.data.customer.phoneNumber)){
            Notification("error","Chưa đủ thông tin","Số điện thoại trống");
        }
        else if(utils.isEmpty(this.state.imgSrc)){
            Notification("error","Chưa đủ thông tin","Chưa chụp hóa đơn");
        }
        else{
            this.handleReCharge();
        }
    }

    reject(){
        if(utils.isEmpty(this.state.reason)){
            Notification("error" ,"Chọn lý do hủy yêu cầu");
        }else{
            let data = {
                status:"2", 
                reason: this.state.reason
            }
            apiAward.editRequestRedeem(this.props.data.id,data,(err)=>  {
                if(err){
                    Notification("error", "Lỗi", "Không thể xác nhận hủy rút tiền. Ghi lại thông tin yêu cầu và báo cáo với quản trị viên");
                }
                else{
                    Notification("success" ,"Hủy yêu cầu thành công");
                    this.setState({imgSrc: null,imgLink: null,show_reason:false});
                    this.props.clearDataRedeem("canceled"); 
                }
            });
        }
    }

    componentDidMount(){
        apiConfigReason.listReason("2",(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({
                    dataReason : result
                });
            }
        });
    }


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.show_reason}>
                    <ModalHeader>Lý do hủy yêu cầu</ModalHeader>
                    <ModalBody>
                        <CustomInput
                            style={{fontSize:"20px"}}
                            size = "10"
                            type="select"
                            id="reason"
                            name="reason"
                            onChange = { this.inputOnChange.bind(this) }
                            multiple
                        >
                            {
                                this.state.dataReason ? this.state.dataReason.map((value,index)=>{
                                    return(
                                        <option key={index} className="border-bottom"> {value.reason}</option>
                                    )
                                })
                                : null
                            }
                        </CustomInput>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.toggle.bind(this,"show_reason",null,null)}>
                        Hủy bỏ
                        </Button>
                        <Button color="success" onClick={this.reject.bind(this)}>
                        Xác nhận
                        </Button>
                    </ModalFooter>
                </Modal>



                <Col>
                    <Row style={{ color: "blue", fontWeight: 600 }}>
                        <Award/>KHÁCH HÀNG RÚT TIỀN
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <Row>
                                <Col sm="12" md="6" className='text-center p-2 border'>
                                    <div className='content-title-bold mt-1'>Thông tin chi tiết khách hàng</div>
                                    <FormGroup row className='mt-4 mx-0'>
                                        <Label for='name' sm={3}>
                                            Họ và tên:
                                        </Label>
                                        <Col sm={8}>
                                            <Input placeholder={this.props.data ? this.props.data.customer.fullName : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-4 mx-0'>
                                        <Label  sm={3}>
                                            Số điện thoại:
                                        </Label>
                                        <Col sm={8}>
                                            <Input placeholder={this.props.data ? this.props.data.customer.phoneNumber : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-4 mx-0'>
                                        <Label sm={3}>
                                            Số CMND:
                                        </Label>
                                        <Col sm={8}>
                                            <Input placeholder={this.props.data ? this.props.data.customer.idCardNumber : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-4 mx-0'>
                                        <Label sm={3}>
                                            Số dư tài khoản:
                                        </Label>
                                        <Col sm={8} className='content-title-bold'>
                                            {this.props.data ? utils.convertMoneyFomat(this.props.data.customer.wallet.balance) : "0"}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-4 mx-0'>
                                        <Label for='exampleEmail2' sm={3}>
                                            Số dư khả dụng:
                                        </Label>
                                        <Col sm={8} className='content-title-bold'>
                                            {this.props.data ? utils.convertMoneyFomat(this.props.data.customer.wallet.availableBalance) : "0"}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6" className='text-center pt-2 pb-4  border'>
                                    <ModalCamera
                                        label='Ảnh hóa đơn'
                                        id='imageBillRedeem'
                                        imgSrc={this.state.imgSrc}
                                        folder='wallet'
                                        actionWhenReceiveData={this.handleShooting.bind(this)}
                                        actionWhenDeleteImage={this.handleRemovePhoto.bind(this)}
                                    />
                                    <span className='mb-1 content-title-bold'>Nhập số tiền cần rút</span>
                                    <InputGroup size='lg' className='mb-3'>
                                        <InputGroupAddon addonType={"append"}>Số tiền</InputGroupAddon>
                                        <Input
                                            readOnly
                                            type='text'
                                            name='money'
                                            onChange={this.handleChange.bind(this)}
                                            value={this.props.data?utils.convertMoneyFomat(this.props.data.amount):"0"}
                                            maxLength='15'
                                        />
                                    </InputGroup>
                                    <center >
                                        <Button 
                                            className="mr-3 w-25 "
                                            style={{height:"50px"}}
                                            disabled={utils.isEmpty(this.props.data) ||utils.isEmpty(this.state.imgSrc)}
                                            color={utils.isEmpty(this.props.data) ||utils.isEmpty(this.state.imgSrc) ? 'dark' : 'success' }
                                            onClick={this.checkEmptyField.bind(this)}
                                        >
                                            Hoàn thành
                                        </Button>
                                        <Button 
                                            className=" w-25 "
                                            style={{height:"50px"}}
                                            disabled={utils.isEmpty(this.props.data)}
                                            color={utils.isEmpty(this.props.data) ? 'dark' : 'danger' }
                                            onClick={()=>{this.setState({show_reason:true,reason:null})}}
                                        >
                                            Hủy bỏ
                                        </Button>
                                    </center>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </React.Fragment>
        );
    }
}

export default Redeem;