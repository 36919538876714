import React from "react";
import { Row, Label, FormGroup, Button,Table,Col, InputGroup,CustomInput,ModalHeader,Modal,ModalBody, Card, CardTitle, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2} from '../api/apiStatistic'
import {getStaffMonitor,getTicketArray} from '../api/apiAdmin'
import {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
import io from 'socket.io-client';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  
//   ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
//   );
const config_api_socket = require("../../../config/config");
const {getProductById,getProductOptionById} = require('../../../assets/js/getProduct');
const moment = require('moment')

class ModalTicket extends React.Component {
    
    getTicketCount(){
        let sum=0
        this.props.ticketArray.map(e=>{
            sum+=1*e.termArray.length
        })
        return sum
    }
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalTicket}>
                    <ModalHeader toggle={this.props.closeModal}>
                        <div className="font-weight-bold">
                        {
                            
                            `Vé: ${getProductById(this.props.productId)}, Số tiền: ${utils.getMoneyFormat(this.props.revenue)}, Số vé:${this.getTicketCount()}; `
                        }
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {
                            
                            this.props.ticketArray.map((ticket,key)=>{
                                let price=0;
                                for(let val of ticket.value){
                                    price+=val.price;
                                }
                                price*=ticket.termArray.length;
                                return(
                                    <Card key = {key}>
                                        <CardTitle>{`Kiểu chơi: ${getProductOptionById(ticket.productOptionId)}, Tiền vé:${utils.getMoneyFormat(price)}, Số kỳ: ${ticket.termArray.length}, Id:${ticket.id}; Thời gian in:${ticket.datePrint}`}</CardTitle>
                                        <CardBody className="pt-0">
                                            { // hien so
                                                ticket.value.map((rowNumber,index)=>{
                                                    return(
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-10">
                                                                <span class="text-danger">{String.fromCharCode(65+index)}</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap w-90">
                                                            
                                                                {this.props.productId!=13?
                                                                    rowNumber.number.map((number,k)=>{
                                                                        if(ticket.productOptionId===82){
                                                                            let kenoEvenOddBigSmall={
                                                                                "1": "Lẻ",
                                                                                "2": "Chẵn",
                                                                                "3": "Nhỏ",
                                                                                "4": "Lớn"
                                                                            };
                                                                            return (<span class="mx-1" key={k}>{kenoEvenOddBigSmall[number]}</span>)
                                                                        }
                                                                        return(<span class="mx-1" key={k}>{number}</span>)
                                                                    }):(<span class="mx-1">{rowNumber.number}</span>)
                                                                }
                                                               { this.props.productId!=13?<span>-{rowNumber.price}K</span>: (<><span>-{rowNumber.price}K</span> <div>{rowNumber.ticketCodes.join('; ')}</div></>)
                                                               }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* Ảnh */}
                                            <Row>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageFront)}/>
                                                </Col>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageBack)}/>
                                                </Col>
                                            </Row>
                                           
                                        </CardBody>
                                    </Card>
                                    
                                )
                            })
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
class StaffMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),           
            phone:null,
            pageNum:1,
            statisticData : [],
            statisticDataShow:[],
            size:50,
            ticketArray:[],
            showModalTicket:false,
            productSelect:null,
            revenueSelect:null,
            dataChartByUser:[]
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "Id Nhân viên"},
            {label: "Họ tên"},            
            {label: "Mega645"},
            {label: " Power655"},
            {label: " Max4D"},
            {label: " Max3D Pro"},
            {label: " Max3D"},
            {label: " Max3DPlus"},
            {label: " Keno"},
            {label: " Loto 235"},            
            {label: "Cặp số"},            
            {label: " 6x36"},
            {label: "Thần tài"},
            {label: "Điên toán 123"}, 
            {label: "Kiến thiết"},            
        ];
        this.filter={
            size: 50,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    
    getStatistic(page){
       
      let query={
          date:this.convertDate(this.state.date),
          
      }      
      getStaffMonitor(
        query.date,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
           
                this.setState({statisticData: result,statisticDataShow:result.slice(0,this.filter.size), pageNum : Math.ceil(result.length/this.filter.size)})
            }
        });
    }
    convertDate=(date)=>{
        if(date)
        return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
    }
   
    
    componentDidMount(){
        const socket = io.connect(config_api_socket.socket_server,{query: `storeId=${utils.getUserinfo().storeId}&username=${utils.getUserinfo().username}`});
        socket.on('connect',()=>{           
            console.log('connected_socket');
        });  
        socket.on('staff_action',(args)=>{           
            console.log(args);
            args=JSON.parse(args);
            //user, action
            let user = args.user.username;
            let action = args.action;
            let time = args.time;
            let currentState=[...this.state.dataChartByUser];
            if(!currentState.find(e=>e.username==user)){
                currentState.push( 
                    {
                        username:user,
                        chartData:{
                            labels:[time],
                            datasets: [
                                {
                                label: 'Lấy vé',
                                data: [action==25?1:0],
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                {
                                label: 'Trả vé',
                                data: [action==27?1:0],
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                },
                                {
                                label: 'Hoàn thành đơn',
                                data: [action==26?1:0],
                                backgroundColor: 'rgba(53, 162, 1, 0.5)',
                                },
                            ],
                        }
                    }
                )
            }
            else{
                let stateStaff=currentState.find(e=>e.username==user);
                //label
                if(stateStaff.chartData.labels.length>10){
                    stateStaff.chartData.labels.shift();
                }
                stateStaff.chartData.labels.push(time);
                stateStaff.chartData.datasets.map(e=>{
                    if(e.data.length>10){
                        e.data.shift();
                    }
                    switch(action){
                        case 25:
                            {
                                if(e.label=='Lấy vé'){
                                    e.data.push(1);
                                }
                                else
                                e.data.push(0);
                                break;
                            }
                        case 26:
                            {
                                if(e.label=='Hoàn thành đơn'){
                                    e.data.push(1);
                                }
                                else
                                e.data.push(0);
                                break;
                              
                            }
                        case 27:
                            {
                                if(e.label=='Trả vé'){
                                    e.data.push(1);
                                }
                                else
                                e.data.push(0);
                                break;
                               
                            }
                    }
                })
                

               
            }
            this.setState({dataChartByUser:currentState})
        });
       
        this.getStatistic(null);
    }
    selectRevenue(productId,revenue){

        if(revenue){            
        getTicketArray(revenue.ticketIds,(err,data)=>{
            if(!err){
                this.setState({selectProduct:productId,selectRevenue:revenue.revenue,ticketArray:data,showModalTicket:true})
            }
            else Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
        })
    }
    }
    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }
    render() {
        let chartData = JSON.parse(JSON.stringify(this.state.dataChartByUser));
        let sumMoney=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        return(
            <React.Fragment>
                {
                        this.state.ticketArray?(
                            <ModalTicket
                                ticketArray = {this.state.ticketArray}
                                showModalTicket = {this.state.showModalTicket}
                                productId={this.state.selectProduct}
                                revenue={this.state.selectRevenue}
                                closeModal = {this.toggle.bind(this,"showModalTicket")}
                            />
                        ):null
                }
                <div className='mx-4 p-3 w-120 text-center'>
                    
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Chọn ngày cần xem</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.date}
                                onChange={date =>this.setState({date: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>                        
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={this.getStatistic.bind(this,null)}>
                                Tìm kiếm
                            </Button>                            
                        </div>
                    </Row>
                   
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th colSpan='12'>Doanh thu từng vé</th>
                                </tr>
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            
                                {

                                    utils.isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticDataShow.map((row)=>{
                                        sumMoney[1]+=row.revenue[1]&&row.revenue[1].revenue || 0;
                                        sumMoney[2]+=row.revenue[2]&&row.revenue[2].revenue|| 0;
                                        sumMoney[3]+=row.revenue[3]&&row.revenue[3].revenue|| 0;
                                        sumMoney[4]+=row.revenue[4]&&row.revenue[4].revenue|| 0;
                                        sumMoney[5]+=row.revenue[5]&&row.revenue[5].revenue|| 0;
                                        sumMoney[6]+=row.revenue[6]&&row.revenue[6].revenue|| 0;
                                        sumMoney[7]+=row.revenue[7]&&row.revenue[7].revenue|| 0;
                                        sumMoney[8]+=row.revenue[8]&&row.revenue[8].revenue|| 0;
                                        sumMoney[9]+=row.revenue[9]&&row.revenue[9].revenue|| 0;
                                        sumMoney[10]+=row.revenue[10]&&row.revenue[10].revenue|| 0;
                                        sumMoney[11]+=row.revenue[11]&&row.revenue[11].revenue|| 0;
                                        sumMoney[12]+=row.revenue[12]&&row.revenue[12].revenue|| 0;
                                        sumMoney[13]+=row.revenue[13]&&row.revenue[13].revenue|| 0;
                                        return(
                                            <tr >
                                                <td>{row.staffId}</td>
                                                <td>{ row.fullName}</td>
                                                <td onClick={this.selectRevenue.bind(this,1,row.revenue[1])}>{row.revenue[1]&& utils.getMoneyFormat(row.revenue[1].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,2,row.revenue[2])}>{row.revenue[2]&&utils.getMoneyFormat(row.revenue[2].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,3,row.revenue[3])}>{row.revenue[3]&&utils.getMoneyFormat(row.revenue[3].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,12,row.revenue[12])}>{row.revenue[12]&&utils.getMoneyFormat(row.revenue[12].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,4,row.revenue[4])}>{row.revenue[4]&&utils.getMoneyFormat(row.revenue[4].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,5,row.revenue[5])}>{row.revenue[5]&&utils.getMoneyFormat(row.revenue[5].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,6,row.revenue[6])}>{row.revenue[6]&&utils.getMoneyFormat(row.revenue[6].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,7,row.revenue[7])}>{row.revenue[7]&&utils.getMoneyFormat(row.revenue[7].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,8,row.revenue[8])}>{row.revenue[8]&&utils.getMoneyFormat(row.revenue[8].revenue)+'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,9,row.revenue[9])}>{row.revenue[9]&&utils.getMoneyFormat(row.revenue[9].revenue )+'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,10,row.revenue[10])}>{row.revenue[10]&&utils.getMoneyFormat(row.revenue[10].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,11,row.revenue[11])}>{row.revenue[11]&&utils.getMoneyFormat(row.revenue[11].revenue) +'đ/'}</td>
                                                <td onClick={this.selectRevenue.bind(this,13,row.revenue[13])}>{row.revenue[13]&&utils.getMoneyFormat(row.revenue[13].revenue) +'đ/'}</td>                                            
                                            </tr>
                                        )
                                    })
                                }
                                <tr  style={{fontWeight:'bold'}}>
                                <td>#</td>
                                <td>Tổng</td>
                                <td >{utils.getMoneyFormat(sumMoney[1]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[2]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[3]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[12]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[4]) +'đ/'}</td>
                                <td>{utils.getMoneyFormat(sumMoney[5]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[6]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[7]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[8]) +'đ/'}</td>
                                <td>{utils.getMoneyFormat(sumMoney[9]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[10]) +'đ/'}</td>
                                <td>{utils.getMoneyFormat(sumMoney[11]) +'đ/'}</td>
                                <td >{utils.getMoneyFormat(sumMoney[13]) +'đ/'}</td>                                            
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                    {
                        
                        chartData.map(e=>(
                        <Col md="4">
                            <p>{e.username}</p>
                            <Bar
                                data={e.chartData}
                            />
                        </Col>
                        ))
                       
                    }
                     </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default StaffMonitor;