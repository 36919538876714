import React from "react";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";

const Dashboard = ({children}) => (
    <React.Fragment>
        <Wrapper>
            {/*<Sidebar/>*/}
            <Main>
                {/*<Navbar/>*/}
                <Content>{children}</Content>
                {/*<Footer/>*/}
            </Main>
        </Wrapper>
        {/*<Settings />*/}
    </React.Fragment>
);

export default Dashboard;
