import React from "react";

import {
    Col, Row
} from "reactstrap";


import "react-tabs/style/react-tabs.css";
import "./accountancy.css";

import SidebarLeft from "./sidebarLeft";
import Content from "./content";
import utils from "../../utils";
import Notification from "../../components/Notification";
const apiAccountant = require("./api/apiAccountant");

class Accountancy extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            dataBanking: null,
            listReason : {
                rechange: null,
                withdraw: null
            },
            clearDataHandling: false,
            takeAway : false,
            side:{
                completed: [],
                canceled: []
            },
            showSidebar: false
        }
    }

    toggle(component) {
        if(component === "clearDataHandling" && this.state.clearDataHandling === false){
            this.setState({
                [component]: !this.state[component],
                dataBanking: null,
            })
        }
        else if(component === "takeAway" && this.state.takeAway === false){
            this.setState({
                [component]: !this.state[component],
                dataBanking: null,
            })
        }
        else{
            this.setState({
                [component]: !this.state[component]
            })
        }
    }

    moveDataToContent(data){
        this.setState({dataBanking: data, showSidebar: false});
    }

    

    handTicketChangeStatus(data,tab){
        let state = utils.getRawData(this.state);
        // neu sidebar dai qua thi gioi han 10 block
        if(state.side[tab].length >= 10){
            state.side[tab] = state.side[tab].pop()
        }
        state.side[tab].unshift(data);
        this.setState(state);
    }

    componentDidMount(){
        let state = Object.assign({},this.state);
        apiAccountant.listReason("1",(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                state.listReason.rechange = result;
                this.setState(state);
                apiAccountant.listReason("2",(err,result)=>  {
                    if(err){
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    } else {
                        state.listReason.withdraw = result;
                        this.setState(state)
                    }
                });
            }
        });
    }

    render() {
        let uesrinfo = utils.getUserinfo();
        if(utils.isEmpty(uesrinfo) || uesrinfo.role!=="accountancy") window.location.replace("/");
        else if(utils.checkAuth())
        return (
            <React.Fragment>
                <div className="group-accountant"> 
                    <Row className="p-0 m-0 accountant">
                        <Col xs="12" lg={2} className={`p-0 m-0 group-tabs-handle custom-sidebar-fixed ${this.state.showSidebar?"d-block":"hidden-md-down"}`}>
                            <SidebarLeft
                                className="d-none"
                                moveDataToContent = {this.moveDataToContent.bind(this)}
                                clearDataHandling = {this.state.clearDataHandling}
                                dataProcess = {this.state.dataBanking}
                                takeAway = {this.state.takeAway}
                                toggle = {this.toggle.bind(this)}
                            />
                        </Col>
                        <Col xs="12" lg={10} className={`group-content ${this.state.showSidebar?"d-none":"d-block"}`}>
                            <div className="main-content">
                                <Content
                                    dataBanking = {this.state.dataBanking}
                                    handTicketChangeStatus = {this.handTicketChangeStatus.bind(this)}
                                    toggle = {this.toggle.bind(this)}
                                    listReason = {this.state.listReason}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default Accountancy;